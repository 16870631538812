import { useState, useEffect, useMemo } from "react";
import "./index.css";
import { Button } from "../../components/Button/Index";
import { Modal } from "antd";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { InputBox } from "../../components/Input/InputApplication";
import { useHistory } from "react-router-dom";
import { DashboardContainer } from "../../components/DashboardContainer";
import { success, error } from "../../components/Alerts/Index";
import { Input } from "../../components/Input";
import closeBtn from "../../assets/img/close-btn-dark.svg";
import axios from "axios";
import config from "../../config";
import "./index.css";

export const ContactUs = () => {
  const [loadContact, setLoadContact] = useState(false);
  const applicant = useSelector((state) => state.applicant.profile);
  const [openContactSuccess, setOpenContactSuccess] = useState(false);
  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm({});

  console.log(applicant);

  const name = watch("name");
  const email = watch("email");
  const subject = watch("subject");
  const message = watch("message");

  const SubmitContact = async (data) => {
    console.log(data);
    setLoadContact(true);
    axios
      .post(`${config.baseUrl}/api/v1/auth/contactus`, {
        email: applicant?.email,
        fullName: applicant?.firstName + " " + applicant?.lastName,
        message: message,
        purpose: subject,
      })
      .then((res) => {
        setLoadContact(false);
        if (res.status === 200 && res?.data?.result) {
          setOpenContactSuccess(true);
          reset({
            name: null,
            email: null,
            subject: null,
            message: null,
          });
        } else {
          console.log(res);
          error("Error", res.data.message);
        }
      })
      .catch((e) => {
        setLoadContact(false);
      });
  };

  const closeModal = () => {
    setOpenContactSuccess(false);
  };
  const style = {
    height: "50px",
    background: "#FFFFFF !important",
    width: "100%",
    border: "1px solid #B7B7B7 !important",
    borderRadius: "0.960832px",
    outlineColor: "#B7B7B7",
    outline: "1px solid #B7B7B7",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
  };

  return (
    <DashboardContainer>
      <div className="rate-padon ">
        <br />
        <div className="d-flex justify-content-center align-items-center">
          <div className="">
            <div className="rating-content-box">
              <div className="text-center">Contact us</div>
              <br />
              <div>Please tell us how we can be of assistance.</div>
              <div>
                <div>
                  <div className="mb-3">
                    <br />
                    <div>
                      <select
                        style={style}
                        class="form-select input-profile"
                        aria-label="Default select example"
                        {...register("subject", {
                          required: "Subject is required",
                        })}
                      >
                        <option value={""}>Subject</option>
                        <option value="Payment">Payment</option>
                        <option value="Review">Question about review</option>
                        <option value="Other">Other</option>
                      </select>
                      <span className="validate-er" style={{ color: "red" }}>
                        {errors?.subject?.message}
                      </span>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div>
                      <div>
                        <textarea
                          className="additional-comment"
                          rows="4"
                          {...register("message", {
                            required: "Message is required",
                          })}
                          cols="60"
                          placeholder="Message"
                        ></textarea>
                        <span className="validate-er" style={{ color: "red" }}>
                          {errors?.message?.message}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-100 mb-5">
                  <Button
                    text="Send"
                    onClick={handleSubmit(SubmitContact)}
                    loading={loadContact}
                    disabled={loadContact}
                    className="purple w-100"
                  />
                </div>

                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={false}
        visible={openContactSuccess}
        footer={false}
        width={600}
        onCancel={closeModal}
        maskClosable={true}
        wrapClassName="example"
        style={{ marginTop: "-1rem" }}
      >
        <div
          onClick={closeModal}
          className="d-flex justify-content-end align-items-center cursor"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div className="rate-padon">
          <br />
          <div className="text-center ">
            <h2 style={{ fontSize: "16px" }}>
              Thank you for your message. We will be in touch shortly.
            </h2>
          </div>

          <div className="d-flex justify-content-center align-items-center">
            <div className="error-btn">
              <Link to="/orders" className="edu-btn">
                Back to Reviews
              </Link>
            </div>{" "}
          </div>
        </div>
      </Modal>
    </DashboardContainer>
  );
};
