import React, { useState } from "react";
import { faqData as data } from "../assets/data/faqData";
import Chevron from "../assets/img/chevron-down.svg";
import {useSelector} from "react-redux"
import { Redirect } from "react-router-dom";
import Faq from "react-faq-component";
export default function FaqContent() {
  const auth = useSelector((state) => state.auth);
  const [currentIndex, setCurrentIndex] = useState();
  const showAnswer = (index) => {
    currentIndex === index ? setCurrentIndex("") : setCurrentIndex(index);
  };
  const styles = {
    // bgColor: 'white',
    titleTextColor: "#333333",
    rowTitleColor: "",
    rowContentColor: "#3c3c43",
    // arrowColor: "red",
  };

  if (auth?.authenticated) {
    return <Redirect to="/orders" />;
  }

  const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
  };
  return (
    <>
    <br />
    <br />
    <br /><br />
      <h2 className="text-left faq-head">Frequently Asked Questions (FAQs)</h2>
      <div className="">
        <div>
          <Faq data={data} styles={styles} config={config} />
        </div>
      </div>
    </>
  );
}
