import { forwardRef } from "react";
import "./index.css";
export const Input = forwardRef((props, ref) => {
  const style = {
    ...props.style,
    paddingLeft: props.prependIcon ? "50px" : "0.9rem",
    height: "50px",
    background: "#FFFFFF !important",
    width: "100%",
    border: "1px solid #B7B7B7 !important",
    borderRadius: "0.960832px",
    outlineColor: "#B7B7B7",
    outline: "1px solid #B7B7B7",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
  };
  return (
    <div>
      <label
        className={`d-block label-name ${
          props.labelColor ? props.labelColor : `text-dark`
        }`}
        htmlFor={props.name}
      >
        {props.label}
      </label>
      <div>
        {props.prependIcon && (
          <div className="prependIcon">{props.prependIcon}</div>
        )}
        <input
          {...props}
          ref={ref}
          title={props.title}
          placeholder={props.placeholder}
          class="form-control input-profile"
          value={props.value}
          style={
            props.error
              ? {
                  ...style,
                  border: "1px solid #ff5b5b",
                  boxShadow: "none",
                  background: "#F7F9FC",
                }
              : {
                  ...style,
                }
          }
        />
      </div>
      {props.error && <p className="validate-error ">{props.error}</p>}
    </div>
  );
});
