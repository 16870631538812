import axios from "axios";
import config from "../config";
import { addApplication } from "../redux/features/applicationSlice";

const getApplicationThunkCreator = (applicationId, setLoading) => {
    return async (dispatch) => {
        try {
            const res = await axios.get(`${config.baseUrl}/api/v1/application/${applicationId}`)
            setLoading(false)
            if (res.status === 200 && res.data.result) {
                dispatch(addApplication(res.data.result))
            }
            else {
                // TODO: improve error handling here
                console.log(res)
            }
        } catch (e) {
            setLoading(false)
            // TODO: improve error handling here
            console.log(e)
        }
    }
};

export default getApplicationThunkCreator;