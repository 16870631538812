const config = {
  baseUrl: process.env.REACT_APP_API_END_POINT
    ? process.env.REACT_APP_API_END_POINT
    : //  : "http://localhost:3001/sop-service",
    // "http://34.141.143.98:5000/sop-service",
    "https://edyter-api.com/sop-service",
   // "http://62.171.172.183:5000/sop-service", New url
  TEST_PUBLISHABLE_API_KEY: "pk_test_51LHmqOFP1btY9lPykDrAhDO6JmBCaMv0jBgnAsfBxlnvmLBY0zocIoZODyUi3OlhTlGGjoyEjF8V4p26Eg6S8KE500pkOjiWts",
  // RETURN_URL: "http://localhost:3000/orders/order-confirmation"
  RETURN_URL: "https://www.edyter.com/orders/order-confirmation",
  // RETURN_URL: "https://profound-centaur-7a48dd.netlify.app/orders/order-confirmation"
  //checking
};

export default config;
