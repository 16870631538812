import { useState, useEffect, useMemo } from "react";
import "./index.css";
import { EmptyState } from "../../components/EmptyState";
import { Button } from "../../components/Button/Index";
import { DashboardContainer } from "../../components/DashboardContainer/index";
import icon1 from "../../assets/img/icon-orange-box.svg";
import icon2 from "../../assets/img/icon-box-info.svg";
import icon3 from "../../assets/img/icon-box-danger.svg";
import getApplicationsThunkCreator from "../../redux-thunk/getApplicationsThunkCreator";
import axios from "axios";
import filterIcon from "../../assets/img/filter-icon.svg";
import MyComponent from "react-fullpage-custom-loader";
import { Redirect, useHistory } from "react-router-dom";
import Profile from "../update-profile/profile";
import Table from "../../components/Table/Tabe";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import config from "../../config";
import getApplicantThunkCreator from "../../redux-thunk/getApplicantThunkCreator";
import {
  createApplicantThunkCreator,
  updateApplicantWithApplicationIdThunkCreator,
} from "../../redux-thunk/createApplicantThunkCreator";
import countryList from "react-select-country-list";
import { cloneDeep } from "lodash";
import RequestReveiw from "../Review/request-review";
export const Dashboard = () => {
  const auth = useSelector((state) => state.auth);
  const applicant = useSelector((state) => state.applicant?.profile);
  const [isHighSchool, setIsHighSchool] = useState(false);
  const session = useSelector((state) => state.auth?.session);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const applications = useSelector((state) => state.applicant?.applications);
  const history = useHistory();

  const profile = useSelector((state) => state.applicant?.profile);

  console.log(applications);

  useEffect(() => {
    setLoading(true);
    dispatch(getApplicationsThunkCreator(setLoading, session?.user?.id));
  }, [dispatch, session?.user?.id]);

  useEffect(() => {
    dispatch(getApplicantThunkCreator(session?.user?.id, setIsHighSchool));
  }, [dispatch, session?.user?.id]);


  if (!auth.authenticated || !session?.user?.id) {
    return <Redirect to="/" />;
  }

  const inprogressApplications = applications?.filter(
    (a) => a?.applicationStatus !== "REVIEWED"
  );
  const completedApplications = applications?.filter(
    (a) => a?.applicationStatus === "REVIEWED"
  );

  if (applicant?.userId === null) {
    history.push("/profile");
  }




  return (
    <div style={{ backgroundColor: "#F7F7F7", height: "100%" }}>
      {loading && (
        <MyComponent
          loaderType="cube-transition"
          height="100%"
          sentences={["Please wait..."]}
          wrapperBackgroundColor="rgba(0,0,0,0.5)"
        />
      )}
      <DashboardContainer>
        <div className="" style={{ height: "100vh", marginTop: "-1rem" }}>
          <div className="col-md-12" style={{ padding: 0, margin: 0 }}>
            <br />
            <div className="flex-dashboard mb-3">
              <div>
                Welcome, <b>{profile.firstName}</b>
              </div>
              <div
                className="place-order-btn cursor div-button-request"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  // history.push("/orders/review-request-application");
                  window.location.href = "/orders/review-request-application"
                }}
              >
                Request a new review
              </div>
            </div>
          </div>
          <br />

          <div className="row">
            <div className="col-md-4">
              <div className=" border-card">
                <div className="bord-content">
                  <div className="d-flex justify-content-between align-items-center">
                    <div style={{ marginRight: ".5rem" }}>
                      <div className="d-flex align-items-bottom">
                        <img src={icon1} width="30" />
                        <div className="mt-2">
                          <small className="mute-text-p bold-num">Total reviews :</small>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="lead-bold-num">
                        {applications?.length}
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className=" border-card">
                <div className="bord-content">
                  <div className="d-flex justify-content-between align-items-center">
                    <div style={{ marginRight: ".5rem" }}>
                      <div className="d-flex align-items-bottom">
                        <img src={icon2} width="30" />
                        <div className="mt-2">
                          <small className="mute-text-p bold-num">Processing :</small>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content align-items-center">
                      <div className="lead-bold-num">
                        {inprogressApplications?.length}
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className=" border-card">
                <div className="bord-content">
                  <div className="d-flex justify-content-between align-items-center">
                    <div style={{ marginRight: ".5rem" }}>
                      <div className="d-flex align-items-bottom">
                        <img src={icon3} width="30" />
                        <div className="mt-2">
                          <small className="mute-text-p bold-num">Reviewed :</small>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="lead-bold-num">
                        {completedApplications?.length}
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="">
            <div className="card-eds card-ed">
              <div className="card-ed-top">
                <div className="d-flex justify-content-center align-items-center top-pad">
                  <div className="bold-lead text-center">Reviews</div>
                </div>
                <hr />
              </div>
              {applications?.length > 0 && (
                <div className="container-table">
                  <Table data={applications} />
                </div>
              )}

              {applications?.length === 0 && (
                <div className="empty-space">
                  <EmptyState />
                </div>
              )}
            </div>
          </div>
        </div>
      </DashboardContainer>
    </div>
  );
};
export default Dashboard;
