import { useState, useEffect, useMemo } from "react";
import "./index.css";
import { Button } from "../../components/Button/Index";
import { Modal } from "antd";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { InputBox } from "../../components/Input/InputApplication";
import { useHistory } from "react-router-dom";
import { success, error } from "../../components/Alerts/Index";
import getApplicationsThunkCreator from "../../redux-thunk/getApplicationsThunkCreator";
import MyComponent from "react-fullpage-custom-loader";
import { Input } from "../../components/Input";
import closeBtn from "../../assets/img/close-btn-dark.svg";
import axios from "axios";
import config from "../../config";
import closeX from "../../assets/img/closeX.svg";
import { Rate } from "antd";
import {
  createApplicantThunkCreator,
  updateApplicantWithApplicationIdThunkCreator,
} from "../../redux-thunk/createApplicantThunkCreator";
import countryList from "react-select-country-list";
import { cloneDeep } from "lodash";
const desc = ["Poor", "Very poor", "Neutral", " high", "Very High"];
const platformUse = [
  "Difficult",
  "Very difficult",
  "Normal",
  " Easy",
  "Very Easy",
];
const platformRecommend = [
  "Very unlikely",
  "Unlikely",
  "Neutral",
  " Likely",
  "Very Likely",
];

export const RateOrder = ({ open, setOpen, applicationId }) => {
  const history = useHistory();
  const [loadRating, setLoadRating] = useState(false);
  const [loading, setLoading] = useState(false);
  const session = useSelector((state) => state.auth.session);
  const dispatch = useDispatch();
  const [qualityOfReview, setQualityOfReview] = useState("");
  const [platformEaseOfUse, setPlatformEaseOfUse] = useState("");
  const [recommendTheService, setRecommendTheService] = useState("");
  const style = {
    height: "50px",
    background: "#FFFFFF !important",
    width: "100%",
    border: "1px solid #B7B7B7 !important",
    borderRadius: "0.960832px",
    outlineColor: "#B7B7B7",
    outline: "1px solid #B7B7B7",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm({});

  const closeModal = () => {
    setOpen(false);
  };

  const otherAreaOfImprovement = watch("otherAreaOfImprovement");
  const areaOfImprovement = watch("areaOfImprovement");
  const additionalComments = watch("additionalComments");

  const closeRatingModal = () => {
    dispatch(getApplicationsThunkCreator(setLoading, session.user.id));
    setOpen(false);
    reset({
      otherAreaOfImprovement: "",
      additionalComments: "",
      areaOfImprovement: "",
    });
    setQualityOfReview("");
    setPlatformEaseOfUse("");
    setRecommendTheService("");
  };

  const submitRating = async () => {
    if (
      areaOfImprovement &&
      platformEaseOfUse &&
      qualityOfReview &&
      recommendTheService
    ) {
      setLoadRating(true);
      try {
        const res = await axios.post(
          `${config.baseUrl}/api/v1/application/applicationFeedback/add`,
          {
            additionalComments: additionalComments,
            applicationId: applicationId,
            areaOfImprovement: areaOfImprovement,
            otherAreaOfImprovement: otherAreaOfImprovement,
            platformEaseOfUse: platformUse[platformEaseOfUse - 1],
            qualityOfReview: desc[qualityOfReview - 1],
            recommendTheService: platformRecommend[recommendTheService - 1],
            userId: session.user.id,
          }
        );
        setLoadRating(false);
        if (res?.status === 200 && res.data.result) {
          closeRatingModal();
          success("", "Thank you for your feedback!");
        } else {
          console.log(res);
          error("Error", res.data.message);
        }
      } catch (e) {
        setLoadRating(false);
        console.log(e);
      }
    } else {
      error("Error", "Fields are required");
    }
  };

  return (
    <div>
      <Modal
        title={false}
        visible={open}
        footer={false}
        width={"100%"}
        maskClosable={false}
        wrapClassName="example"
        style={{ marginTop: "-5rem" }}
        bodyStyle={{ height: "100%" }}
      >
        <div
          onClick={closeRatingModal}
          className="d-flex justify-content-end align-items-center cursor"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div className="rate-padon">
          <br />
          <div className="d-flex justify-content-center align-items-center">
            <div className="col-review-box">
              <div className="rating-content-box">
                <div className="text-center lead-p"></div>
                <br />
                <div>
                  <div>
                    <div className=" rate-quest">
                      How would you rate the quality of the review on your
                      personal statement?
                    </div>
                    <div>
                      <span>
                        <Rate
                          tooltips={desc}
                          onChange={setQualityOfReview}
                          value={qualityOfReview}
                        />
                        {qualityOfReview ? (
                          <span className="ant-rate-text">
                            <b>{desc[qualityOfReview - 1]}</b>
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                  <br />
                  <div>
                    <div className=" rate-quest">
                      How would you rate the ease of use of the platform?
                    </div>
                    <div>
                      <span>
                        <Rate
                          tooltips={platformUse}
                          onChange={setPlatformEaseOfUse}
                          value={platformEaseOfUse}
                        />
                        {platformEaseOfUse ? (
                          <span className="ant-rate-text">
                            <b>{platformUse[platformEaseOfUse - 1]}</b>
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                  <br />
                  <div>
                    <div className=" rate-quest">
                      How likely are you to recommend the service to your
                      friends?
                    </div>
                    <div>
                      <span>
                        <Rate
                          tooltips={platformRecommend}
                          onChange={setRecommendTheService}
                          value={recommendTheService}
                        />
                        {recommendTheService ? (
                          <span className="ant-rate-text">
                            <b>{platformRecommend[recommendTheService - 1]}</b>
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                  <br />
                  <div>
                    <div className=" rate-quest">
                      Which one area should be improved?. If others, please
                      specify
                    </div>
                    <div className="mt-3">
                      <select
                        style={style}
                        class="form-select input-profile"
                        {...register("areaOfImprovement", {})}
                      >
                        <option selected>Select</option>
                        <option value="timeline">Timeline</option>
                        <option value="quality">Quality</option>
                        <option value="platform use">Platform use</option>
                        <option value="communication">Communication</option>
                        <option value="other">Other</option>
                        <option value="none">None</option>
                      </select>
                      {areaOfImprovement === "other" && (
                        <div>
                          <InputBox
                            require
                            placeholder="Enter other improvement"
                            name="otherAreaOfImprovement"
                            type="text"
                            {...register("otherAreaOfImprovement", {
                              required: "Other area of improvement is required",
                            })}
                            error={errors?.otherAreaOfImprovement?.message}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <br />
                  <div>
                    <div className=" rate-quest">
                      Do you have any additional comments? If yes, provide
                      below:
                    </div>
                    <div className="mt-3">
                      <textarea
                        className="additional-comment"
                        rows="4"
                        {...register("additionalComments", {})}
                        cols="60"
                        placeholder="Your feedback"
                      ></textarea>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="w-100 mb-5">
                    <Button
                      text="Submit"
                      onClick={submitRating}
                      loading={loadRating}
                      className="purple w-100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
