import ErrorIcon from "../../assets/img/error-icon.svg";
import ModalCheck from "../../assets/img/modal-check.svg";
import { toast } from "react-toastify";
import "./Index.css";

export function success(title, text) {
  return toast.success(
    <div className="row">
      <div className="col-2">
        <img src={ModalCheck} width="20" style={{ marginRight: "2rem" }} />
      </div>
      <div className="col-10">
        <span className="pop-title"> {title} </span>
        <p className="pop-text p-0 m-0">{text}</p>
      </div>
    </div>
  );
}

export function error(title, text) {
  return toast.error(
    <div className="row">
      <div className="col-2">
        <img src={ErrorIcon} style={{ marginRight: "2rem" }} />
      </div>
      <div className="col-10">
        <span className="pop-title"> {title} </span>
        <p className="pop-text p-0 m-0">{text}</p>
      </div>
    </div>
  );
}
