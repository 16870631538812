import { createSlice } from "@reduxjs/toolkit";

export const applicationSlice = createSlice({
  name: "application",
  initialState: {
    object: {},
    applicationSubmittedResult: {},
  },
  reducers: {
    addApplication: (state, action) => {
      state.object = action.payload;
    },
    applicationSuccess: (state, action) => {
      state.applicationSubmittedResult = action.payload.applicationResult;
    },
    clearApplication: (state) => {
      state.object = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { addApplication, clearApplication, applicationSuccess } =
  applicationSlice.actions;

export default applicationSlice.reducer;
