import React from 'react';
import { Link } from "react-router-dom";
import ErrorImg from "../../assets/img/bg/error-thumb.png"


const ErrorPageMain = () => {
    return (
        <main>
            <div className="content-error-area pt-120 pb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="content-error-item text-center">
                                <div className="error-thumb">
                                    <img src={ErrorImg} alt="error not found" />
                                </div>
                                <div className="section-title">
                                    <h4 className="mb-20">Oops! That page can not be found.</h4>
                                </div>
                                <div className="error-btn">
                                    <Link to="/" className="edu-btn">Back to homepage</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ErrorPageMain;