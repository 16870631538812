import React from "react";
import Footer from "../../components/Layout/Footer/Footer";
import Header from "../../components/Layout/Header";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import Applicant from "../../components/ReviewRequest/Applicant";

export default function ReviewRequestApplicant() {
  const auth = useSelector(state => state.auth)
  const history = useHistory()

  if (!auth.authenticated) {
    return <Redirect to="/" />
  }

  const next = () => {
    history.push("/orders/review-request-application")
  };



  return (
    <>

      <Header auth={auth} />
      <main>
        <div className="container-padding ">
          <Applicant next={next} profileTitle={'Please use this section to provide details pertaining to your profile.'} nextButtonText={'Next'} />
        </div>
      </main>
      <Footer />
    </>
  );
}
