import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "antd";
import axios from "axios";
import config from "../../../config";
import OTPInput from "otp-input-react";
import { setAuthToken } from "../../../serviceApis/authentication";
import "./auth.css";
import { Input } from "../../../components/Input/index";
import closeBtn from "../../../assets/img/close-btn-dark.svg";
import closeX from "../../../assets/img/closeX.svg";
import { error, success } from "../../../components/Alerts/Index";
import eyeShow from "../../../assets/img/eye-show.svg";
import { EmailValidator } from "commons-validator-js";
import eyeOff from "../../../assets/img/eye-off.svg";
const ResetPassword = ({
  openEmailModal,
  setOpenEmailModal,
  setSignInOpen,
}) => {
  const [showEmailError, setShowEmailError] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [showVerificationError, setShowVerificationError] = useState(false);
  const [verificationError, setVerificationError] = useState("");
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [eye, seteye] = useState(false);
  const [eye2, seteye2] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [openVerificationModal, setOpenVerificationModal] = useState(false);
  const [loadEmail, setLoadEmail] = useState(false);
  const [loadVerify, setLoadVerify] = useState(false);
  const [loadResend, setLoadResend] = useState(false);
  const [loadPassword, setLoadPassword] = useState(false);
  const [openNewPasswordModal, setOpenNewPasswordModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userId, setUserId] = useState("");
  const [otp, setOtp] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const validator = new EmailValidator();
  const passwordRegex = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
  );

  const style = {
    height: "50px",
    background: "#FFFFFF !important",
    width: "100%",
    border: "0.899471px solid #B7B7B7",
    borderRadius: "0.960832px",
    outlineColor: "#B7B7B7",
    outline: "1px solid #B7B7B7",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm();

  const {
    register: register1,
    watch: watch1,
    formState: { errors: errors1 },
    handleSubmit: handleSubmit1,
    reset: reset1,
  } = useForm();

  const newPass = watch1("newPass");

  const closeEmailModal = () => {
    reset({ email: "" });
    if (showEmailError) {
      setShowEmailError(false);
    }
    setOpenEmailModal(false);
  };

  const closeVerificationModal = () => {
    if (showVerificationError) {
      setShowVerificationError(false);
    }
    setOtp("");
    setOpenVerificationModal(false);
  };

  const handleChange = (otp) => {
    if (showVerificationError) {
      setShowVerificationError(false);
    }
    setOtp(otp);
  };

  const closeNewPasswordModal = () => {
    setPassword("");
    reset1({ newPass: "", cNewPass: "" });
    if (showPasswordError) {
      setShowPasswordError(false);
    }
    setOpenNewPasswordModal(false);
  };

  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const onEmailSubmit = async (data) => {
    setLoadEmail(true);
    setEmail(data.email);
    try {
      const res = await axios.get(
        `${config.baseUrl}/api/v1/auth/validateEmail/${data.email}`
      );
      setLoadEmail(false);
      if (!res?.data?.status) {
        console.log(res);
        error("Error", res.data.message);
      } else {
        closeEmailModal();
        setOpenVerificationModal(true);
      }
    } catch (e) {
      setLoadEmail(false);
      console.log(e);
      if (e.response.status !== 401) {
        // network issue
        setEmailError("Something went wrong. Try again");
        setShowEmailError(true);
      } else {
        closeEmailModal();
        setOpenVerificationModal(true);
      }
    }
  };

  const activateUser = async () => {
    setLoadVerify(true);
    try {
      const res = await axios.post(
        `${config.baseUrl}/api/v1/auth/activateUser/email/${email}/${otp}`
      );
      setLoadVerify(false);
      if (res?.status === 200 && res.data.result) {
        setUserId(res.data.result.user.id);
        setAuthToken(res.data.result.accessToken);
        closeVerificationModal();
        setOpenNewPasswordModal(true);
      } else {
        console.log(res);
        setVerificationError(res.data.message);
        setShowVerificationError(true);
      }
    } catch (e) {
      setLoadVerify(false);
      console.log(e);
      if (e?.response?.status === 400) {
        if (isJson(e?.response?.data?.message)) {
          const msgObj = JSON.parse(e?.response?.data?.message);
          setVerificationError(
            `Invalid code. Number of attempts left: ${msgObj.noOfTrials}`
          );
        } else if (
          e?.response?.data?.message ===
          "You have exceeded activation token trial time. A new token has been sent your email address."
        ) {
          setVerificationError(
            "Maximum number of attempts exceeded. A new code has been sent to your email"
          );
        } else {
          setVerificationError("Something went wrong. Try again");
        }
      } else {
        setVerificationError("Something went wrong. Try again");
      }
      setShowVerificationError(true);
    }
  };

  const resendCode = async () => {
    if (showVerificationError) {
      setShowVerificationError(false);
    }
    setLoadResend(true);
    try {
      const res = await axios.get(
        `${config.baseUrl}/api/v1/auth/resendToken/email/${email}`
      );
      setLoadResend(false);
      if (res?.status !== 200) {
        console.log(res);
      }
    } catch (e) {
      setLoadResend(false);
      console.log(e);
    }
  };

  console.log(errors);

  const submitNewPassword = async (data) => {
    setLoadPassword(true);
    try {
      const res = await axios.post(
        `${config.baseUrl}/api/v1/users/resetPassword/${userId}/${data.newPass}`
      );
      setLoadPassword(false);
      if (res?.status === 200) {
        closeNewPasswordModal();
        setOpenConfirmationModal(true);
      } else {
        console.log(res);
        setPasswordError(res.data.message);
        setShowPasswordError(true);
      }
    } catch (e) {
      console.log(e);
      setLoadPassword(false);
      setPasswordError("Something went wrong. Try again");
      setShowPasswordError(true);
    }
  };

  return (
    <>
      <Modal
        title={false}
        visible={openEmailModal}
        footer={false}
        width={"100%"}
        onCancel={closeEmailModal}
        wrapClassName="example"
        style={{ marginTop: "1rem" }}
        bodyStyle={{ height: "100%" }}
        maskClosable={false}
      >
        <div
          onClick={closeEmailModal}
          className="d-flex cursor justify-content-end align-items-center"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div className=" pt-30 ">
          <form>
            <div className="signup-form-wrapper-new">
              <div className="form-inputs ">
                <div className="forgot-password-bord">
                  <div
                    className="text-center col-lead mt-4"
                    style={{ color: "#382D8B" }}
                  >
                    Forgot password
                  </div>
                  <div>
                    Please enter your email address. We'll send you a one time passcode.
                  </div>
                  <div className="mb-4 mt-4">
                    <Input
                      label=""
                      require
                      placeholder="Email Address"
                      name="email"
                      type="email"
                      {...register("email", {
                        required: "Email is required",
                        onChange: (e) => {
                          if (showEmailError) {
                            setShowEmailError(false);
                          }
                        },
                      })}
                      autoComplete="off"
                      error={errors?.email?.message}
                    />
                    {emailError && showEmailError && (
                      <div className="signup-action">
                        <span className="text-danger">{emailError}</span>
                      </div>
                    )}
                  </div>
                  <div
                    className="sing-buttom mb-20"
                    onClick={handleSubmit(onEmailSubmit)}
                  >
                    <button type="submit" className="sing-btn">
                      {loadEmail ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                  <div
                    className="primary-col link-hover-ed cursor text-center d-flex justify-content-center mb-2"
                    onClick={() => {
                      setSignInOpen(true);
                      setOpenEmailModal(false);
                    }}
                  >
                    Back to sign-in
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        title={false}
        visible={openVerificationModal}
        footer={false}
        onCancel={closeVerificationModal}
        width={"100%"}
        wrapClassName="example"
        style={{ marginTop: "1rem" }}
        bodyStyle={{ height: "100%" }}
        maskClosable={false}
      >
        <div
          onClick={closeVerificationModal}
          className="d-flex cursor justify-content-end align-items-center"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div className="signup-form-wrapper-new">
          <div className="form-inputs ">
            <div className="forgot-password-bord">
              <div
                className="text-center col-lead mt-4"
                style={{ color: "#382D8B" }}
              >
                Enter code
              </div>
              <div className="text-center">
                Please enter the code that was sent to you.
              </div>
              <div
                className="d-flex justify-content-center align-items-center h-100 pt-4 pb-4"
                style={{ marginLeft: ".8rem" }}
              >
                <OTPInput
                  value={otp}
                  onChange={handleChange}
                  OTPLength={6}
                  separator={<span></span>}
                  inputStyles={{
                    width: "70%",
                    height: "3.5rem",
                    borderRadius: "5px",
                    fontSize: "16px",
                    color: "#382D8B",
                    fontWeight: "400",
                    fontSize: "29px",
                    outlineColor: "1px solid #B7B7B7",
                    outline: "1px solid #B7B7B7",
                    lineHeight: "107.5%",
                    color: "#382D8B",
                    border: "1px solid #B7B7B7",
                  }}
                />
              </div>
              {verificationError && showVerificationError && (
                <div className="signup-action">
                  <span className="text-danger">{verificationError}</span>
                </div>
              )}
              <div className="d-flex flex-column">
                <div className="sing-buttom mb-20" onClick={activateUser}>
                  <button type="submit" className="sing-btn">
                    {loadVerify ? "Submitting..." : "Verify"}
                  </button>
                </div>
                <div
                  onClick={resendCode}
                  className="text-center cursor link-hover-ed bold-num primary-col mb-3  d-flex justify-content-center align-items-center"
                >
                  {" "}
                  {loadResend ? "Sending..." : "Resend code"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title={false}
        visible={openNewPasswordModal}
        footer={false}
        width={"100%"}
        onCancel={closeNewPasswordModal}
        wrapClassName="example"
        style={{ marginTop: "1rem" }}
        bodyStyle={{ height: "100%" }}
        maskClosable={false}
      >
        <div
          onClick={closeNewPasswordModal}
          className="d-flex cursor justify-content-end align-items-center"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div className="pt-30">
          <form>
            <div className="signup-form-wrapper-new">
              <div className="form-inputs">
                <div className="forgot-password-bord">
                  <div
                    className="text-center col-lead mt-4"
                    style={{ color: "#382D8B", whiteSpace: "nowrap" }}
                  >
                    Create New Password
                  </div>{" "}
                  <div className="mb-4">
                    <div
                      class="d-flex w-100 justify-content-between align-items-center  mt-4"
                      style={style}
                    >
                      <div
                        className="d-flex w- h-100 justify-content"
                        style={{
                          width: "85%",
                        }}
                      >
                        <input
                          type={eye ? `text` : "password"}
                          placeholder="Password"
                          className="d-flex w-100 h-100 px-3"
                          {...register1("newPass", {
                            required: "New Password is required",
                            onChange: (e) => {
                              if (!passwordRegex?.test(e.target.value)) {
                                setPasswordInvalid(true);
                              } else {
                                setPasswordInvalid(false);
                              }
                            },
                          })}
                          style={{
                            border: "0 !important",
                            outline: "0 !important",
                            outlineColor: "none !important",
                            outline: "none !important",
                          }}
                        />
                      </div>

                      <div
                        className="cursor d-flex h-100 justify-content-center align-items-center"
                        style={{
                          width: "15%",
                        }}
                        onClick={() => seteye(!eye)}
                      >
                        {eye ? (
                          <div>
                            <img src={eyeShow} width="20" />
                          </div>
                        ) : (
                          <div>
                            <img src={eyeOff} width="20" />
                          </div>
                        )}
                      </div>
                    </div>
                    <p style={{ fontSize: "12px" }} className="pt-4">
                      Use <b>8</b> or more characters with at least{" "}
                      <b>one upper case</b> letter,
                      <b> one lower case</b> letter, <b> one digit</b> and{" "}
                      <b>one special character.</b>
                    </p>
                    <span className="text-danger">
                      {errors1?.newPass?.message}
                    </span>
                    {passwordInvalid && (
                      <span className="text-danger">Use a valid password</span>
                    )}
                  </div>
                  <div className="mb-4">
                    <div
                      class="d-flex w-100 justify-content-between align-items-center  mt-4"
                      style={style}
                    >
                      <div
                        className="d-flex w- h-100 justify-content"
                        style={{
                          width: "85%",
                        }}
                      >
                        <input
                          type={eye2 ? `text` : "password"}
                          placeholder="Confirm password"
                          className="d-flex w-100 h-100 px-3"
                          {...register1("cNewPass", {
                            required: "Confirm Password is required",

                            validate: (value) =>
                            {
                              console.log('test')
                              return value === newPass ||
                              "Confirm Password and Password do not match"
                            }
                          })}
                          style={{
                            border: "0 !important",
                            outline: "0 !important",
                            outlineColor: "none !important",
                            outline: "none !important",
                          }}
                        />
                      </div>

                      <div
                        className="cursor d-flex h-100 justify-content-center align-items-center"
                        style={{
                          width: "15%",
                        }}
                        onClick={() => seteye2(!eye2)}
                      >
                        {eye2 ? (
                          <div>
                            <img src={eyeShow} width="20" />
                          </div>
                        ) : (
                          <div>
                            <img src={eyeOff} width="20" />
                          </div>
                        )}
                      </div>
                    </div>
                    <span className="text-danger">
                      {" "}
                      {errors1?.cNewPass?.message}
                    </span>
                  </div>
                  <div
                    className="sing-buttom mb-20"
                    onClick={handleSubmit1(submitNewPassword)}
                  >
                    <button type="submit" className="sing-btn">
                      {loadPassword ? "Submitting..." : "Create New Password"}
                    </button>
                  </div>
                  <div
                    onClick={() => {
                      setSignInOpen(true);
                      setOpenEmailModal(false);
                      setOpenVerificationModal(false);
                      setOpenNewPasswordModal(false);
                    }}
                    className="text-center cursor link-hover-ed  primary-col mb-5 mt-1 d-flex justify-content-center align-items-center"
                  >
                    {" "}
                    {loadResend ? "Sending..." : "or sign In"}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        title={false}
        visible={openConfirmationModal}
        footer={false}
        width={"100%"}
        onCancel={closeConfirmationModal}
        wrapClassName="example"
        style={{ marginTop: "1rem" }}
        bodyStyle={{ height: "100%" }}
        maskClosable={false}
      >
        <div
          onClick={closeConfirmationModal}
          className="d-flex cursor justify-content-end align-items-center"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div className="pt-50">
          <div className="signup-form-wrapper-new">
            <div className="form-inputs">
              <div className="forgot-password-bord" style={{ padding: "20px" }}>
                {" "}
                <div className="d-flex justify-content-center align-items-center h-100">
                  <div>
                    <div className="text-center d-flex justify-content-center align-items-center">
                      <img src={closeX} width="50" />
                    </div>
                    <br />
                    <div
                      className="text-center col-lead"
                      style={{ color: "#382D8B" }}
                    >
                      Success!
                    </div>
                    <div>Password changed successfully</div>
                    <br />
                    <div
                      onClick={() => {
                        setSignInOpen(true);
                        setOpenConfirmationModal(false);
                        setOpenEmailModal(false);
                      }}
                      className="text-center cursor link-hover-ed primary-col mb-5 mt-1 d-flex justify-content-center align-items-center"
                    >
                      {" "}
                      Back to login
                    </div>
                    <br />
                  </div>
                </div>
              </div>
              {/* <div
                className="sing-buttom mb-20"
                onClick={() => {
                  closeConfirmationModal();
                  setSignInOpen(true);
                }}
              >
                <button className="sing-btn">Back to Sign In</button>
              </div> */}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export default ResetPassword;
