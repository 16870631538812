import axios from "axios";
import config from "../config";
import { addApplicant } from "../redux/features/applicantSlice";

const getApplicantThunkCreator = (userId, setLoad) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${config.baseUrl}/api/v1/applicant/getProfileStatus/${userId}`
      );
      if (res.status === 200 && res.data.result) {
        setLoad(false);
        dispatch(addApplicant(res.data.result));
      } else {
        // TODO: imporve error handling here
        console.log(res);
      }
    } catch (e) {
      // TODO: imporve error handling here
      console.log(e);
    }
  };
};

export default getApplicantThunkCreator;
