import { useState, useEffect, useMemo } from "react";
import "./index.css";
import { Button } from "../../Button/Index";
import { Modal } from "antd";
import closeBtn from "../../../assets/img/close-btn-dark.svg";
import PdfIcon from "../../../assets/img/pdf-icon.svg";
import axios from "axios";
import config from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SampleDownloadDatas = ({ open, setOpen, downloadDatas }) => {
  console.log(downloadDatas);
  const closeModal = () => {
    setOpen(false);
  };

  const downloadOutput = async (filename, downloadUrl) => {
    try {
      const path =
        downloadUrl || `${config.baseUrl}/api/v1/application/downloadOwnFile/`;
      const res = await axios({
        url: `${path}${filename}`,
        method: "GET",
        responseType: "blob",
      });
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      } else {
        //TODO: handle this case
        console.log(res);
      }
    } catch (e) {
      //TODO: handle error here
      console.log(e);
    }
  };

  return (
    <div>
      <Modal
        title={false}
        visible={open}
        footer={false}
        width={"90%"}
        onCancel={closeModal}
        maskClosable={true}
        wrapClassName="example"
        style={{ marginTop: "3rem" }}
      >
        <div
          onClick={closeModal}
          className="d-flex justify-content-end align-items-center cursor"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div className="rate-padon">
          <br />
          <div className="order-sum-ans">
            <div className="show-web">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="" style={{ color: "#000", textAlign: "left" }}>
                      <b>Sample one</b>
                    </th>
                    <th scope="" style={{ color: "#000", textAlign: "left" }}>
                      <b>Sample two</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      <div className="d-flex justify-content align-items-center">
                        <div className="rev-right-space">
                          <img src={PdfIcon} width="40" />
                        </div>
                        <div className="rev-right-space">
                          {downloadDatas[0]?.sampleDraftDocName}{" "}
                        </div>
                        <div className="rev-right-space">
                          <a
                            href="#!"
                            className="link-hover"
                            onClick={() =>
                              downloadOutput(
                                downloadDatas[0]?.sampleDraftDocName,
                                `${config.baseUrl}/api/v1/auth/downloadSampleFile/`
                              )
                            }
                          >
                            <span>
                              <FontAwesomeIcon icon="download" size="xl" />
                            </span>
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content align-items-center">
                        <div className="rev-right-space">
                          <img src={PdfIcon} width="40" />
                        </div>
                        <div className="rev-right-space">
                          {downloadDatas[1]?.sampleDraftDocName}{" "}
                        </div>
                        <div className="rev-right-space">
                          <a
                            href="#!"
                            className="link-hover"
                            onClick={() =>
                              downloadOutput(
                                downloadDatas[1]?.sampleDraftDocName,
                                `${config.baseUrl}/api/v1/auth/downloadSampleFile/`
                              )
                            }
                          >
                            <span>
                              <FontAwesomeIcon icon="download" size="xl" />
                            </span>
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <div className="d-flex justify-content align-items-center">
                        <div className="rev-right-space">
                          <img src={PdfIcon} width="40" />
                        </div>
                        <div className="rev-right-space">
                          {downloadDatas[0]?.sampleReviewedDocName}{" "}
                        </div>
                        <div className="rev-right-space">
                          <a
                            href="#!"
                            className="link-hover"
                            onClick={() =>
                              downloadOutput(
                                downloadDatas[0]?.sampleReviewedDocName,
                                `${config.baseUrl}/api/v1/auth/downloadSampleFile/`
                              )
                            }
                          >
                            <span>
                              <FontAwesomeIcon icon="download" size="xl" />
                            </span>
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content align-items-center">
                        <div className="rev-right-space">
                          <img src={PdfIcon} width="40" />
                        </div>
                        <div className="rev-right-space">
                          {downloadDatas[1]?.sampleReviewedDocName}{" "}
                        </div>
                        <div className="rev-right-space">
                          <a
                            href="#!"
                            className="link-hover"
                            onClick={() =>
                              downloadOutput(
                                downloadDatas[1]?.sampleReviewedDocName,
                                `${config.baseUrl}/api/v1/auth/downloadSampleFile/`
                              )
                            }
                          >
                            <span>
                              <FontAwesomeIcon icon="download" size="xl" />
                            </span>
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {" "}
                      <div className="d-flex jusitfy-content align-items-center">
                        <div className="rev-right-space">
                          <img src={PdfIcon} width="40" />
                        </div>
                        <div className="rev-right-space">
                          {downloadDatas[0]?.sampleRevisionDocName}{" "}
                        </div>
                        <div className="rev-right-space">
                          <a
                            href="#!"
                            className="link-hover"
                            onClick={() =>
                              downloadOutput(
                                downloadDatas[0]?.sampleRevisionDocName,
                                `${config.baseUrl}/api/v1/auth/downloadSampleFile/`
                              )
                            }
                          >
                            <span>
                              <FontAwesomeIcon icon="download" size="xl" />
                            </span>
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex jusitfy-content align-items-center">
                        <div className="rev-right-space">
                          <img src={PdfIcon} width="40" />
                        </div>
                        <div className="rev-right-space">
                          {downloadDatas[1]?.sampleRevisionDocName}{" "}
                        </div>
                        <div className="rev-right-space">
                          <a
                            href="#!"
                            className="link-hover"
                            onClick={() =>
                              downloadOutput(
                                downloadDatas[1]?.sampleRevisionDocName,
                                `${config.baseUrl}/api/v1/auth/downloadSampleFile/`
                              )
                            }
                          >
                            <span>
                              <FontAwesomeIcon icon="download" size="xl" />
                            </span>
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex justify-content align-items-center">
                        <div className="rev-right-space">
                          <img src={PdfIcon} width="40" />
                        </div>
                        <div className="rev-right-space">
                          {downloadDatas[0]?.sampleRevisionDoc2Name}{" "}
                        </div>
                        <div className="rev-right-space">
                          <a
                            href="#!"
                            className="link-hover"
                            onClick={() =>
                              downloadOutput(
                                downloadDatas[0]?.sampleRevisionDoc2Name,
                                `${config.baseUrl}/api/v1/auth/downloadSampleFile/`
                              )
                            }
                          >
                            <span>
                              <FontAwesomeIcon icon="download" size="xl" />
                            </span>
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content align-items-center">
                        <div className="rev-right-space">
                          <img src={PdfIcon} width="40" />
                        </div>
                        <div className="rev-right-space">
                          {downloadDatas[1]?.sampleRevisionDoc2Name}{" "}
                        </div>
                        <div className="rev-right-space">
                          <a
                            href="#!"
                            className="link-hover"
                            onClick={() =>
                              downloadOutput(
                                downloadDatas[1]?.sampleRevisionDoc2Name,
                                `${config.baseUrl}/api/v1/auth/downloadSampleFile/`
                              )
                            }
                          >
                            <span>
                              <FontAwesomeIcon icon="download" size="xl" />
                            </span>
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="show-mobile">
              <h4 style={{ color: "#222" }}>Sample 1</h4>
              <div>
                <div>
                  {downloadDatas[0]?.sampleDraftDocName ? (
                    <div className="flex-asnwers">
                      <div className="rev-right-space">
                        <img src={PdfIcon} width="40" />
                      </div>
                      <div className="rev-right-space">
                        {downloadDatas[0]?.sampleDraftDocName}{" "}
                      </div>
                      <div className="rev-right-space">
                        <a
                          href="#!"
                          className="link-hover"
                          onClick={() =>
                            downloadOutput(
                              downloadDatas[0]?.sampleDraftDocName,
                              `${config.baseUrl}/api/v1/auth/downloadSampleFile/`
                            )
                          }
                        >
                          <span>
                            <FontAwesomeIcon icon="download" size="xl" />
                          </span>
                        </a>
                      </div>
                    </div>
                  ) : (
                    "No upload"
                  )}
                </div>
                <br />
                <div>
                  {downloadDatas[0]?.sampleReviewedDocName ? (
                    <div className="flex-asnwers">
                      <div className="rev-right-space">
                        <img src={PdfIcon} width="40" />
                      </div>
                      <div className="rev-right-space">
                        {downloadDatas[0]?.sampleReviewedDocName}{" "}
                      </div>
                      <div className="rev-right-space">
                        <a
                          href="#!"
                          className="link-hover"
                          onClick={() =>
                            downloadOutput(
                              downloadDatas[0]?.sampleRevisionDocName,
                              `${config.baseUrl}/api/v1/auth/downloadSampleFile/`
                            )
                          }
                        >
                          <span>
                            <FontAwesomeIcon icon="download" size="xl" />
                          </span>
                        </a>
                      </div>
                    </div>
                  ) : (
                    "No Upload"
                  )}
                </div>
                <br />
                <div>
                  {downloadDatas[0]?.sampleRevisionDocName ? (
                    <div className="flex-asnwers">
                      <div className="rev-right-space">
                        <img src={PdfIcon} width="40" />
                      </div>
                      <div className="rev-right-space">
                        {downloadDatas[0]?.sampleRevisionDocName}{" "}
                      </div>
                      <div className="rev-right-space">
                        <a
                          href="#!"
                          className="link-hover"
                          onClick={() =>
                            downloadOutput(
                              downloadDatas[0]?.sampleRevisionDocName,
                              `${config.baseUrl}/api/v1/auth/downloadSampleFile/`
                            )
                          }
                        >
                          <span>
                            <FontAwesomeIcon icon="download" size="xl" />
                          </span>
                        </a>
                      </div>
                    </div>
                  ) : (
                    "No Upload"
                  )}
                </div>
                <br />
                <div>
                  {downloadDatas[0]?.sampleRevisionDoc2Name ? (
                    <div className="flex-asnwers">
                      <div className="rev-right-space">
                        <img src={PdfIcon} width="40" />
                      </div>
                      <div className="rev-right-space">
                        {downloadDatas[0]?.sampleRevisionDoc2Name}{" "}
                      </div>
                      <div className="rev-right-space">
                        <a
                          href="#!"
                          className="link-hover"
                          onClick={() =>
                            downloadOutput(
                              downloadDatas[0]?.sampleRevisionDoc2Name,
                              `${config.baseUrl}/api/v1/auth/downloadSampleFile/`
                            )
                          }
                        >
                          <span>
                            <FontAwesomeIcon icon="download" size="xl" />
                          </span>
                        </a>
                      </div>
                    </div>
                  ) : (
                    "No Uploaded"
                  )}
                </div>
                <br />
              </div>

              <br />
              <h4 style={{ color: "#222" }}>Sample 2</h4>
              <div>
                <div>
                  {downloadDatas[1]?.sampleDraftDocName ? (
                    <div className="flex-asnwers">
                      <div className="rev-right-space">
                        <img src={PdfIcon} width="40" />
                      </div>
                      <div className="rev-right-space">
                        {downloadDatas[1]?.sampleDraftDocName}{" "}
                      </div>
                      <div className="rev-right-space">
                        <a
                          href="#!"
                          className="link-hover"
                          onClick={() =>
                            downloadOutput(
                              downloadDatas[1]?.sampleDraftDocName,
                              `${config.baseUrl}/api/v1/auth/downloadSampleFile/`
                            )
                          }
                        >
                          <span>
                            <FontAwesomeIcon icon="download" size="xl" />
                          </span>
                        </a>
                      </div>
                    </div>
                  ) : (
                    "No upload"
                  )}
                </div>
                <br />
                <div>
                  {downloadDatas[1]?.sampleReviewedDocName ? (
                    <div className="flex-asnwers">
                      <div className="rev-right-space">
                        <img src={PdfIcon} width="40" />
                      </div>
                      <div className="rev-right-space">
                        {downloadDatas[1]?.sampleReviewedDocName}{" "}
                      </div>
                      <div className="rev-right-space">
                        <a
                          href="#!"
                          className="link-hover"
                          onClick={() =>
                            downloadOutput(
                              downloadDatas[1]?.sampleRevisionDocName,
                              `${config.baseUrl}/api/v1/auth/downloadSampleFile/`
                            )
                          }
                        >
                          <span>
                            <FontAwesomeIcon icon="download" size="xl" />
                          </span>
                        </a>
                      </div>
                    </div>
                  ) : (
                    "No Upload"
                  )}
                </div>
                <br />
                <div>
                  {downloadDatas[1]?.sampleRevisionDocName ? (
                    <div className="flex-asnwers">
                      <div className="rev-right-space">
                        <img src={PdfIcon} width="40" />
                      </div>
                      <div className="rev-right-space">
                        {downloadDatas[1]?.sampleRevisionDocName}{" "}
                      </div>
                      <div className="rev-right-space">
                        <a
                          href="#!"
                          className="link-hover"
                          onClick={() =>
                            downloadOutput(
                              downloadDatas[1]?.sampleRevisionDocName,
                              `${config.baseUrl}/api/v1/auth/downloadSampleFile/`
                            )
                          }
                        >
                          <span>
                            <FontAwesomeIcon icon="download" size="xl" />
                          </span>
                        </a>
                      </div>
                    </div>
                  ) : (
                    "No Upload"
                  )}
                </div>
                <br />
                <div>
                  {downloadDatas[1]?.sampleRevisionDoc2Name ? (
                    <div className="flex-asnwers">
                      <div className="rev-right-space">
                        <img src={PdfIcon} width="40" />
                      </div>
                      <div className="rev-right-space">
                        {downloadDatas[1]?.sampleRevisionDoc2Name}{" "}
                      </div>
                      <div className="rev-right-space">
                        <a
                          href="#!"
                          className="link-hover"
                          onClick={() =>
                            downloadOutput(
                              downloadDatas[1]?.sampleRevisionDoc2Name,
                              `${config.baseUrl}/api/v1/auth/downloadSampleFile/`
                            )
                          }
                        >
                          <span>
                            <FontAwesomeIcon icon="download" size="xl" />
                          </span>
                        </a>
                      </div>
                    </div>
                  ) : (
                    "No Uploaded"
                  )}
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
