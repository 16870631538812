import axios from "axios";
import config from "../config";
import { applicationSuccess } from "../redux/features/applicationSlice";
import { error, success } from "../components/Alerts/Index";

export const createApplicationThunkCreator = (
  setLoad,
  setShowCreateError,
  setCreateError,
  formData,
  next
) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: "post",
        url: `${config.baseUrl}/api/v1/application/uploadDraftDocument`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoad(false);
      if (res?.status === 200 && res?.data?.result) {
        success("Success", res.data.message);
        dispatch(applicationSuccess({ applicationResult: res.data.result }));
      } else {
        error("Error", res?.data?.message);
      }
    } catch (e) {
      console.log(e);
      setLoad(false);
      error("Error", e?.response?.data?.message);
    }
  };
};

export const updateApplicationThunkCreator = (
  setLoad,
  setShowCreateError,
  setCreateError,
  data,
  next,
  applicationId
) => {
  return async (dispatch) => {
    setLoad(true);
    try {
      const res = await axios.put(
        `${config.baseUrl}/api/v1/application/${applicationId}`,
        data
      );
      setLoad(false);
      if (res?.status === 200) {
        next();
      } else {
        console.log(res);
        setCreateError("Something went wrong. Try again");
        setShowCreateError(true);
      }
    } catch (e) {
      console.log(e);
      setLoad(false);
      setCreateError("Something went wrong. Try again");
      setShowCreateError(true);
    }
  };
};
