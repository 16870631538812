import { useState, useEffect, useMemo } from "react";
import "./index.css";
import { Button } from "../../components/Button/Index";
import { Modal } from "antd";
import { useForm } from "react-hook-form";
import moment from "moment";
import getApplicantThunkCreator from "../../redux-thunk/getApplicantThunkCreator";
import { updateApplicantThunkCreator } from "../../redux-thunk/createApplicantThunkCreator";
import { useSelector, useDispatch } from "react-redux";
import { InputBox } from "../../components/Input/InputApplication";
import { useHistory } from "react-router-dom";
import { DashboardContainer } from "../../components/DashboardContainer";
import MyComponent from "react-fullpage-custom-loader";
import closeBtn from "../../assets/img/close-btn-dark.svg";
import closeX from "../../assets/img/closeX.svg";
import {
  createApplicantThunkCreator,
  updateApplicantWithApplicationIdThunkCreator,
} from "../../redux-thunk/createApplicantThunkCreator";
import countryList from "react-select-country-list";
import { cloneDeep } from "lodash";
export const Profile = ({ open, setOpen, applicationId }) => {
  const session = useSelector((state) => state.auth.session);
  const applicant = useSelector((state) => state.applicant.profile);
  const dispatch = useDispatch();
  const [successMsg, setSuccessMsg] = useState(false);
  const [loadProfile, setLoadProfile] = useState(false);
  const [isEditButtonCaption, setIsEditButtonCaption] = useState(false);
  const history = useHistory();
  const [load, setLoad] = useState(false);
  const [callHandleSubmit, setCallHandleSubmit] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);
  const [createError, setCreateError] = useState("");
  const [showCreateError, setShowCreateError] = useState(false);
  const [showChoiceUniversitiesError, setShowChoiceUniversitiesError] =
    useState(false);
  const countryOptions = useMemo(() => countryList()?.getData(), []);
  const [isHighSchool, setIsHighSchool] = useState(false);
  const [hasDraft, setHasDraft] = useState(null);

  const next = () => {
    history.push("/orders/review-request-application");
  };

  const style = {
    height: "50px",
    background: "#FFFFFF !important",
    width: "100%",
    border: "1px solid #B7B7B7 !important",
    borderRadius: "0.960832px",
    outlineColor: "#B7B7B7",
    outline: "1px solid #B7B7B7",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
  };

  // useEffect(() => {
  //   if (applicant.id) {
  //     setSuccessMsg(true);
  //   } else {
  //     setSuccessMsg(false);
  //   }
  // }, [applicant]);

  useEffect(() => {
    dispatch(getApplicantThunkCreator(session?.user?.id, setLoad));
  }, [dispatch, session?.user?.id]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      firstName: applicant?.firstName,
      lastName: applicant?.lastName,
      hobby: applicant?.hobby,
      gender: applicant?.gender,
      dateOfBirth: dateInputFormat(applicant?.dateOfBirth),
      countryOfOrigin: applicant?.countryOfLocation,
      highestLevelOfEducation: applicant?.highestLevelOfEducation,
    },
  });

  useEffect(() => {
    reset({
      firstName: applicant?.firstName,
      lastName: applicant?.lastName,
      gender: applicant?.gender,
      hobby: applicant?.hobby,
      dateOfBirth: dateInputFormat(applicant?.dateOfBirth),
      countryOfOrigin: applicant?.countryOfLocation,
      highestLevelOfEducation: applicant?.highestLevelOfEducation,
    });

    const next = () => {
      history.push("/orders");
    };

    setHasDraft(
      applicant?.haveDraft === "Yes"
        ? true
        : applicant?.haveDraft === "No"
          ? false
          : null
    );
  }, [applicant, reset]);

  function dateOutFormat(date) {
    if (!date) return "";

    const dateArray = date.split("-");
    return `${dateArray[1]}-${dateArray[2]}-${dateArray[0]}`;
  }

  const createApplicant = (data) => {
    console.log("test");
    setLoadProfile(true);
    data.userId = session?.user?.id;
    data.dateOfBirth = data.dateOfBirth;
    const dataReq = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      userId: session?.user?.id,
      dateOfBirth: dateOutFormat(data?.dateOfBirth),
      gender: data?.gender,
      countryOfLocation: data?.countryOfOrigin,
      highestLevelOfEducation: data?.highestLevelOfEducation,
    };
    if (!applicant?.id) {
      dispatch(
        createApplicantThunkCreator(
          setLoadProfile,
          setShowCreateError,
          setCreateError,
          dataReq,
          history
        )
      );
    } else {
      dispatch(
        updateApplicantThunkCreator(
          setLoadProfile,
          setShowCreateError,
          setCreateError,
          dataReq,
          history
        )
      );
    }
  };

  useEffect(() => {
    if (applicant?.userId) {
      setAllowEdit(true);
    } else {
      setAllowEdit(false);
    }
  }, [applicant?.userId]);

  const editProfileFucntion = () => {
    setIsEditButtonCaption(true);
    setAllowEdit(false);
  };

  const fuctionProcessButtonState = (caption) => {
    if (caption) {
      editProfileFucntion();
      setCallHandleSubmit(true);
    } else {
      setCallHandleSubmit(false);
      setIsEditButtonCaption(false);
      setAllowEdit(true);
    }
  };

  return (
    <DashboardContainer>
      {!successMsg && (
        <div>
          <div className="text-center lead-p">Profile</div>
          {applicant?.firstName === null && applicant?.lastName === null && (
            <div className="text-center" style={{ color: "#382d8b" }}>
              Please update your profile.
            </div>
          )}
          <br />
          <div className="d-flex justify-content-center align-items-center">
            <div className="col-profile-box">
              <div className="container">
                <div className="mt-4"></div>
                <br />
                <form>
                  <div>
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div class="">
                          <InputBox
                            label="First Name"
                            require
                            name="firstName"
                            disabled={allowEdit}
                            type="text"
                            {...register("firstName", {
                              required: "First Name is required",
                            })}
                            error={errors?.firstName?.message}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12 -top-space">
                        <div class="">
                          <InputBox
                            label="Last Name"
                            require
                            disabled={allowEdit}
                            name="lastName"
                            type="text"
                            {...register("lastName", {
                              required: "Last Name is required",
                            })}
                            error={errors?.lastName?.message}
                          />
                        </div>
                      </div>
                    </div>

                    <br />
                    <div className="row">
                      <div className="col-md-6 col-12 pt-5">

                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          <b>Gender</b>
                        </label>
                        <br />
                        <select
                          disabled={allowEdit}
                          style={style}
                          class="form-select input-profile"
                          {...register("gender", {
                            required: "Gender is required",
                          })}
                        >
                          <option selected>Select</option>
                          <option>Prefer not to say</option>
                          <option>Male</option>
                          <option>Female</option>
                        </select>
                        <span className="validate-er">
                          {errors?.gender?.message}
                        </span>
                      </div>

                      <div className="col-md-6 col-12 move-top">
                        <div class="">
                          <InputBox
                            label="Date of Birth"
                            require
                            name="dob"
                            type="date"
                            disabled={allowEdit}
                            {...register("dateOfBirth", {})}
                          />
                        </div>
                      </div>
                    </div>

                    <br />
                    <div className="row">
                      <div className="col-md-6 -top-space col-12">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          <b>Country of Location</b>
                        </label>
                        <select
                          style={style}
                          class="form-select input-profile"
                          disabled={allowEdit}
                          aria-label="Default select example"
                          {...register("countryOfOrigin", {
                            required: "Country is required",
                            onChange: (e) => {
                              if (showCreateError) {
                                setShowCreateError(false);
                              }
                            },
                          })}
                        >
                          <option>Select</option>
                          {countryOptions.map((country, indx) => {
                            return (
                              <option value={country.label} key={indx}>
                                {country.label}
                              </option>
                            );
                          })}
                        </select>
                        <span className="validate-er">
                          {errors?.countryOfOrigin?.message}
                        </span>
                      </div>
                      <br />
                      <div className="col-md-6 -top-space-level col-12">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          <b>Highest Level of Education</b>
                        </label>
                        <select
                          style={style}
                          class="form-select input-profile"
                          aria-label="Default select example"
                          disabled={allowEdit}
                          {...register("highestLevelOfEducation", {
                            required: "Highest Level of Education is required",
                            onChange: (e) => {
                              if (showCreateError) {
                                setShowCreateError(false);
                              }

                              if (e.target.value === "High School") {
                                setIsHighSchool(true);
                              } else {
                                setIsHighSchool(false);
                              }
                            },
                          })}
                        >
                          <option value={""}></option>
                          <option value="High School">High School</option>
                          <option value="Some College">Some College</option>
                          <option value="Bachelor's">Bachelor's</option>
                          <option value="Master's">Master's</option>
                          <option value="Doctorate">Doctorate</option>
                          <option value="Professional">
                            Professional (e.g., MBA, MD, JD etc)
                          </option>
                        </select>
                        <span className="validate-er">
                          {errors?.highestLevelOfEducation?.message}
                        </span>
                      </div>
                    </div>

                  </div>
                  <br />
                  <div className="d-flex justify-content-center align-items-center">
                    {applicant?.id && (
                      <Button
                        isDisabled={loadProfile}
                        text={
                          !isEditButtonCaption ? "Edit Profile" : "Save Changes"
                        }
                        className={`${loadProfile ? "is-disabled" : ""
                          } purple w-50`}
                        style={{ whiteSpace: "nowrap" }}
                        onClick={
                          callHandleSubmit
                            ? handleSubmit(createApplicant)
                            : (e) => {
                              e.preventDefault();
                              fuctionProcessButtonState(!isEditButtonCaption);
                            }
                        }
                      />
                    )}
                    {!applicant?.id && (
                      <Button
                        text={"Save Changes"}
                        className={`${loadProfile ? "is-disabled" : ""
                          } purple w-50`}
                        style={{ whiteSpace: "nowrap" }}
                        onClick={handleSubmit(createApplicant)}
                      />
                    )}
                  </div>
                  <br />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {successMsg && (
        <div className="pt-50">
          <div className="d-flex justify-content-center align-items-center">
            <div className="success-box">
              {" "}
              <div className="d-flex justify-content-center align-items-center h-100">
                <div>
                  <div className="text-center d-flex cursor justify-content-center align-items-center">
                    <img src={closeX} width="50" />
                  </div>
                  <br />
                  <br />
                  <div
                    className="text-center col-lead"
                    style={{ color: "#382D8B" }}
                  >
                    Success!
                  </div>
                  <br />
                  <div className="text-center d-flex justify-content-center align-items-center">
                    Your Changes were saved successfully.
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </DashboardContainer>
  );
};

function dateInputFormat(date) {
  if (!date) return "";

  const dateArray = date.split("-");
  return `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`;
}

export default Profile;
