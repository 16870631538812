// This file contains the routing configuration
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages";
import Team from "./pages/team";
import Faqs from "./pages/Faqs";
import Policy from "./components/Policy";
import Terms from "./components/Terms";
import UpdateProfile from "./pages/update-profile/profile";
import Orders from "./pages/orders";
import ReviewRequestApplicant from "./pages/orders/review-request-applicant";
import ReviewRequestApplication from "./pages/Review/request-review";
import {ContactUs} from "./pages/contact-us/index"
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faStar, faDownload } from "@fortawesome/free-solid-svg-icons";
import ScrollToTop from "./components/ScrollToTop";
import ErrorPageMain from "./components/ErrorPage/ErrorPageMain";
import ReviewSummary from "./pages/Review/ReviewSummary";
import OrderConfirmation from "./pages/orders/order-confirmation";
import ReviewApplicationSummary from "./pages/orders/review-application-summary";
import ReviewRequestApplicationEdit from "./pages/orders/review-request-application-edit";
import ReviewRequestApplicantEdit from "./pages/orders/review-request-applicant-edit";
import OrderUpdateConfirmation from "./pages/orders/order-update-confirmation";
import ApplicantUpdateConfirmation from "./pages/orders/applicant-update-confirmation";
import ReviewPriceSummary from "./pages/orders/review-request-price-summary";
import OrderCheckout from "./pages/orders/order-checkout";
import Dashboard from "./pages/dashboard/index";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

library.add(fab, faStar, faDownload);

function App() {
  return (
    <div>
      <Router>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <ScrollToTop />
        <Switch>
          <Route path="/team">
            <Team />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/faqs">
            <Faqs />
          </Route>
          <Route path="/order-summary/:id">
            <ReviewSummary />
          </Route>
          <Route path="/privacy-policy">
            <Policy />
          </Route>
          <Route path="/profile">
            <UpdateProfile />
          </Route>
          <Route path="/terms-of-use">
            <Terms />
          </Route>
          <Route path="/orders/review-request-applicant" exact>
            <ReviewRequestApplicant />
          </Route>
          <Route path="/contact-us">
            <ContactUs />
          </Route>
          <Route path="/orders/review-request-application">
            <ReviewRequestApplication />
          </Route>
          <Route path="/orders/review-request-application-edit/:id">
            <ReviewRequestApplicationEdit />
          </Route>
          <Route path="/orders/review-request-applicant-edit/:applicationId">
            <ReviewRequestApplicantEdit />
          </Route>
          <Route path="/orders/order-confirmation">
            <OrderConfirmation />
          </Route>
          <Route path="/orders/price-summary/:applicationId">
            <ReviewPriceSummary />
          </Route>
          <Route path="/orders/checkout/:applicationId">
            <OrderCheckout />
          </Route>
          <Route path="/orders/order-update-confirmation">
            <OrderUpdateConfirmation />
          </Route>
          <Route path="/orders/applicant-update-confirmation">
            <ApplicantUpdateConfirmation />
          </Route>
          <Route path="/orders/application-summary/:id">
            <ReviewSummary />
          </Route>
          <Route path="/orders" exact>
            <Dashboard />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route component={ErrorPageMain} />
        </Switch>
      </Router>
      <ToastContainer icon={false} autoClose={5000} hideProgressBar={true} />{" "}
    </div>
  );
}

export default App;
