import { useState, useEffect, useMemo } from "react";
import "./index.css";
import { Button } from "../../components/Button/Index";
import { Modal } from "antd";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { InputBox } from "../../components/Input/InputApplication";
import { useHistory } from "react-router-dom";
import closeBtn from "../../assets/img/close-btn-dark.svg";

export const RatedView = ({ open, setOpen, feedbackResponse }) => {
  console.log(feedbackResponse);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      platformUse: feedbackResponse?.platformEaseOfUse,
      qualityOfReview: feedbackResponse?.qualityOfReview,
      recommendService: feedbackResponse?.recommendService,
      areaOfImprovement: feedbackResponse?.areaOfImprovement,
      additionalComments: feedbackResponse?.additionalComments,
      otherAreaOfImprovement: feedbackResponse?.otherAreaOfImprovement,
    },
  });

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        title={false}
        visible={open}
        footer={false}
        width={"100%"}
        maskClosable={false}
        wrapClassName="example"
        style={{ marginTop: "-1rem" }}
        bodyStyle={{ height: "100%" }}
      >
        <div
          onClick={closeModal}
          className="d-flex justify-content-end align-items-center cursor"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div className="rate-padon">
          <br />
          <div className="d-flex justify-content-center align-items-center">
            <div className="col-review-box">
              <div className="rating-content-box">
                <div className="text-center lead-p">View Rating</div>
                <div>
                  <InputBox
                    label="Platform Ease Of Use"
                    require
                    type="text"
                    disabled
                    value={feedbackResponse?.platformEaseOfUse}
                    {...register("platformUse", {})}
                  />
                </div>
                <br />
                <div>
                  <InputBox
                    label="Recommend The Service"
                    require
                    name="firstName"
                    disabled
                    value={feedbackResponse?.recommendTheService}
                    type="text"
                    {...register("recommendService", {})}
                  />
                </div>
                <br />
                <div>
                  <InputBox
                    label="Quality Of Review"
                    require
                    disabled
                    value={feedbackResponse?.qualityOfReview}
                    type="text"
                    {...register("qualityOfReview", {})}
                  />
                </div>
                <br />
                <div>
                  <InputBox
                    label="Area Of Improvement"
                    require
                    disabled
                    value={feedbackResponse?.areaOfImprovement}
                    type="text"
                    {...register("areaOfImprovement", {})}
                  />
                </div>
                <br />
                <div>
                  <div className="label-area">Additional Comments</div>
                  <textarea
                    className="additional-comment"
                    rows="4"
                    disabled
                    cols="60"
                    value={feedbackResponse?.additionalComments}
                  ></textarea>
                </div>
                <br />
                {feedbackResponse?.otherAreaOfImprovement && (
                  <div>
                    <div className="label-area">Other Areas of Improvement</div>
                    <div>
                      <textarea
                        className="additional-comment"
                        rows="4"
                        cols="60"
                        disabled
                        value={feedbackResponse?.otherAreaOfImprovement}
                      ></textarea>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
