import { useState, useEffect, useMemo } from "react";
import "./index.css";
import { useSelector, useDispatch } from "react-redux";
import { DashboardContainer } from "../../components/DashboardContainer";
import MyComponent from "react-fullpage-custom-loader";
import { Link } from "react-router-dom";
import config from "../../config";
import PdfIcon from "../../assets/img/pdf-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Redirect, useParams } from "react-router-dom";
export const Summary = () => {
  const [load, setLoad] = useState(false);
  const applications = useSelector((state) => state?.applicant?.applications);
  const { id } = useParams();

  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 700);
  }, []);

  if (applications.length === 0) {
    return <Redirect to="/orders" />;
  }

  const application = applications?.find((app) => app.id === parseInt(id));

  const downloadOutput = async (filename, downloadUrl) => {
    try {
      const path =
        downloadUrl || `${config.baseUrl}/api/v1/application/downloadOwnFile/`;
      const res = await axios({
        url: `${path}${filename}`,
        method: "GET",
        responseType: "blob",
      });
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      } else {
        //TODO: handle this case
        console.log(res);
      }
    } catch (e) {
      //TODO: handle error here
      console.log(e);
    }
  };

  return (
    <DashboardContainer>
      {load && (
        <MyComponent
          loaderType="cube-transition"
          height="100vh"
          sentences={["Please wait..."]}
          wrapperBackgroundColor="rgba(0,0,0,0.5)"
        />
      )}

      <div>
        <div className="text-center lead-p">Review summary</div>
        <hr />
        <br />
        <div className="d-flex justify-content-center align-items-center">
          <div className="col-rev-summary-box">
            <div className="container order-summary">
              <div className="order-q-col">
                <div className="order-sum-question">
                  <div>What is your intended broad field of study?</div>
                </div>
                <div className="order-sum-ans">
                  - {application?.fieldOfStudy}
                </div>
              </div>
              <div className="order-q-col">
                <div className="order-sum-question">
                  Enter your intended specific field of study.
                </div>
                <div className="order-sum-ans">
                  - {application?.specificIntendedField}
                </div>
              </div>
              <div className="order-q-col">
                <div className="order-sum-question">Upload cv (optional).</div>
                <div className="order-sum-ans">
                  <div>
                    {application?.applicantCVName ? (
                      <div className="flex-asnwers">
                        <div className="rev-right-space">
                          <img src={PdfIcon} width="40" />
                        </div>
                        <div className="rev-right-space">
                          {application?.applicantCVName}{" "}
                        </div>
                        <div className="rev-right-space">
                          <a
                            href="#!"
                            className="link-hover"
                            onClick={() =>
                              downloadOutput(application?.applicantCVName)
                            }
                          >
                            <span>
                              <FontAwesomeIcon icon="download" size="xl" />
                            </span>
                          </a>
                        </div>
                      </div>
                    ) : (
                      "CV not uploaded"
                    )}
                  </div>
                </div>
              </div>
              <div className="order-q-col">
                <div className="order-sum-question">
                  Upload a pdf version of your draft
                </div>
                <div className="order-sum-ans">
                  {application?.applicantDraftName ? (
                    <div className="flex-asnwers">
                      <div className="rev-right-space">
                        <img src={PdfIcon} width="40" />
                      </div>
                      <div className="rev-right-space">
                        {application?.applicantDraftName}{" "}
                      </div>
                      <div className="rev-right-space">
                        <a
                          href="#!"
                          className="link-hover"
                          onClick={() =>
                            downloadOutput(application?.applicantDraftName)
                          }
                        >
                          <span>
                            <FontAwesomeIcon icon="download" size="xl" />
                          </span>
                        </a>
                      </div>
                    </div>
                  ) : (
                    "Draft Not Submitted"
                  )}
                </div>
              </div>
              <div className="order-q-col">
                <div className="flex-summary-bottom">
                  <div className="d-flex   align-items-center">
                    <div className="rev-footer-lead">Review ID:</div>
                    <div className="rev-footer">{application?.orderNo}</div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="rev-footer-lead">Date:</div>
                    <div className="rev-footer">
                      {application?.applicationDate
                        ? application?.applicationDate?.slice(0, 10)
                        : ""}
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="rev-footer-lead">Status:</div>
                    <div className="rev-footer">
                      {
                        {
                          PAYMENT_REQUIRED: (
                            <div
                              className=""
                              style={{ color: "#0163de", fontWeight: "700" }}
                            >
                              Payment Required
                            </div>
                          ),
                          PAYMENT_BEING_PROCESSED: (
                            <div
                              className=""
                              style={{ color: "#0163de", fontWeight: "700" }}
                            >
                              Payment Required
                            </div>
                          ),
                          DRAFT_UPLOAD_VALIDATION: (
                            <div
                              className=""
                              style={{ color: "red", fontWeight: "700" }}
                            >
                              Validate Order
                            </div>
                          ),
                          RECEIVED: (
                            <div
                              className=""
                              style={{
                                color: "#17a2b8!important",
                                fontWeight: "700",
                              }}
                            >
                              Appication Recieved
                            </div>
                          ),
                          INCOMPLETE_APPLICATION: (
                            <div
                              className=""
                              style={{
                                color: "#17a2b8!important",
                                fontWeight: "700",
                              }}
                            >
                              Complete Request
                            </div>
                          ),
                          REVIEWED: (
                            <div
                              className=""
                              style={{ color: "#00A859", fontWeight: "700" }}
                            >
                              Reviewed
                            </div>
                          ),
                          REVIEW_IN_PROGRESS: (
                            <div
                              className=""
                              style={{ color: "#00A859", fontWeight: "700" }}
                            >
                              In Progress
                            </div>
                          ),
                          default: (
                            <div
                              className="order-summary-status"
                              style={{
                                background: "0163de",
                                fontWeight: "700",
                              }}
                            >
                              {application?.applicationStatus}
                            </div>
                          ),
                        }[application?.applicationStatus]
                      }
                    </div>
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-center align-items-center">
                  <div className="error-btn">
                    <Link to="/orders" className="edu-btn">
                      Back to reviews
                    </Link>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default Summary;
