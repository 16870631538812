import React from "react";
import Footer from "../../components/Layout/Footer/Footer";
import Header from "../../components/Layout/Header";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";

export default function ApplicantUpdateConfirmation() {
    const auth = useSelector(state => state.auth)

    if (!auth.authenticated) {
        return <Redirect to="/" />
    }



    return (
        <>
            <Header auth={auth} />
            <main>
                <div className="content-error-area container-padding mobile-space-reviews" style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8">
                                <div className="content-error-item text-center">
                                    <div className="section-title">
                                        <h4 className="mb-20">Thank you for updating your profile.</h4>
                                    </div>
                                    <div className="error-btn">
                                        <Link to='/orders' className="edu-btn">Back to orders page</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}
