import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import getApplicantThunkCreator from "../../redux-thunk/getApplicantThunkCreator";
import { createApplicantThunkCreator, updateApplicantWithApplicationIdThunkCreator } from "../../redux-thunk/createApplicantThunkCreator";
import countryList from 'react-select-country-list'
import { cloneDeep } from "lodash";

const EmploymentStatus = {
    EMPLOYED: 'Employed',
    SELF_EMPLOYED: 'Self employed',
    UNEMPLOYED: 'Unemployed',
    STUDENT: 'Student'
}

export default function Applicant({ next, profileTitle, nextButtonText, applicationId }) {
    const applicant = useSelector(state => state.applicant.profile)
    const session = useSelector(state => state.auth.session)
    const dispatch = useDispatch()
    const [load, setLoad] = useState(false)
    const [createError, setCreateError] = useState('')
    const [showCreateError, setShowCreateError] = useState(false)
    const [showChoiceUniversitiesError, setShowChoiceUniversitiesError] = useState(false)
    const countryOptions = useMemo(() => countryList().getData(), [])
    const [isHighSchool, setIsHighSchool] = useState(false)
    const [hasDraft, setHasDraft] = useState(null)

    useEffect(() => {
        dispatch(getApplicantThunkCreator(session.user.id, setIsHighSchool))
    }, [dispatch, session.user.id])

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setValue
    } = useForm({
        defaultValues: {
            firstName: applicant.firstName,
            lastName: applicant.lastName,
            hobby: applicant.hobby,
            dateOfBirth: dateInputFormat(applicant.dateOfBirth),
            countryOfOrigin: applicant.countryOfOrigin,
            highestLevelOfEducation: applicant.highestLevelOfEducation,
            yearsOfWorkingExp: applicant.yearsOfWorkingExp,
            placeOfWork: applicant.placeOfWork,
            employmentStatus: applicant.employmentStatus,
            sex: applicant.sex,
            schoolCountry1: applicant.choiceUniversities?.[0]?.country,
            degreeObtained1: applicant.choiceUniversities?.[0]?.degreeObtained,
            schoolName1: applicant.choiceUniversities?.[0]?.schoolName,
            graduationStatus1: applicant.choiceUniversities?.[0]?.graduationStatus,
            yearOfGraduation1: applicant.choiceUniversities?.[0]?.yearOfGraduation,
            schoolCountry2: applicant.choiceUniversities?.[1]?.country,
            degreeObtained2: applicant.choiceUniversities?.[1]?.degreeObtained,
            schoolName2: applicant.choiceUniversities?.[1]?.schoolName,
            graduationStatus2: applicant.choiceUniversities?.[1]?.graduationStatus,
            yearOfGraduation2: applicant.choiceUniversities?.[1]?.yearOfGraduation,
            schoolCountry3: applicant.choiceUniversities?.[2]?.country,
            degreeObtained3: applicant.choiceUniversities?.[2]?.degreeObtained,
            schoolName3: applicant.choiceUniversities?.[2]?.schoolName,
            graduationStatus3: applicant.choiceUniversities?.[2]?.graduationStatus,
            yearOfGraduation3: applicant.choiceUniversities?.[2]?.yearOfGraduation,
            haveDraft: applicant.haveDraft,
            helpDevelopDraft: applicant.helpDevelopDraft,
            payExtraForCoaching: applicant.payExtraForCoaching
        }
    });

    useEffect(() => {
        reset({
            firstName: applicant.firstName,
            lastName: applicant.lastName,
            hobby: applicant.hobby,
            dateOfBirth: dateInputFormat(applicant.dateOfBirth),
            countryOfOrigin: applicant.countryOfOrigin,
            highestLevelOfEducation: applicant.highestLevelOfEducation,
            yearsOfWorkingExp: applicant.yearsOfWorkingExp,
            placeOfWork: applicant.placeOfWork,
            employmentStatus: applicant.employmentStatus,
            sex: applicant.sex,
            schoolCountry1: applicant.choiceUniversities?.[0]?.country,
            degreeObtained1: applicant.choiceUniversities?.[0]?.degreeObtained,
            schoolName1: applicant.choiceUniversities?.[0]?.schoolName,
            graduationStatus1: applicant.choiceUniversities?.[0]?.graduationStatus,
            yearOfGraduation1: applicant.choiceUniversities?.[0]?.yearOfGraduation,
            schoolCountry2: applicant.choiceUniversities?.[1]?.country,
            degreeObtained2: applicant.choiceUniversities?.[1]?.degreeObtained,
            schoolName2: applicant.choiceUniversities?.[1]?.schoolName,
            graduationStatus2: applicant.choiceUniversities?.[1]?.graduationStatus,
            yearOfGraduation2: applicant.choiceUniversities?.[1]?.yearOfGraduation,
            schoolCountry3: applicant.choiceUniversities?.[2]?.country,
            degreeObtained3: applicant.choiceUniversities?.[2]?.degreeObtained,
            schoolName3: applicant.choiceUniversities?.[2]?.schoolName,
            graduationStatus3: applicant.choiceUniversities?.[2]?.graduationStatus,
            yearOfGraduation3: applicant.choiceUniversities?.[2]?.yearOfGraduation,
            haveDraft: applicant.haveDraft,
            helpDevelopDraft: applicant.helpDevelopDraft,
            payExtraForCoaching: applicant.payExtraForCoaching
        })

        setHasDraft(applicant.haveDraft === "Yes" ? true : (applicant.haveDraft === "No" ? false : null))
    }, [applicant, reset])

    const createApplicant = (data) => {
        if (load) {
            return
        }
        data.userId = session.user.id
        data.dateOfBirth = dateOutFormat(data.dateOfBirth)

        const dataClone = cloneDeep(data)

        if (!setChoiceUniversities(dataClone, session.user.id)) {
            if (dataClone.highestLevelOfEducation !== "High School" && dataClone.highestLevelOfEducation !== "Some College") {
                setShowChoiceUniversitiesError(true)
                return
            }
        }

        if (!applicationId && applicationId !== 0) {
            dispatch(createApplicantThunkCreator(setLoad, setShowCreateError, setCreateError, dataClone, next))
        } else {
            dispatch(updateApplicantWithApplicationIdThunkCreator(setLoad, setShowCreateError, setCreateError, dataClone, next, applicationId))
        }
    }

    return (
        <div className="mobile-space-reviews top-margin">
            <div>
                <h4>{profileTitle}</h4>
                <p>Note: Your responses will be used to <strong>personalize the editing</strong> of your statement of purpose, so please answer as accurately as possible.</p>
            </div>
            <br />
            <form action="#">
                <div>
                    <div className="row">
                        <div className="col-xl-6">
                            <label>Do you already have a draft?</label>
                            <div className="contact-select mb-20">
                                <select
                                    className="mb-0"
                                    style={{
                                        background: "white",
                                        border: "1px solid #2467EC",
                                        borderRadius: "10px",
                                    }}
                                    {...register("haveDraft", {
                                        required: "Please answer if you have a draft",
                                        onChange: (e) => {
                                            if (showCreateError) {
                                                setShowCreateError(false)
                                            }
                                            if (e.target.value === 'Yes') {
                                                setHasDraft(true)
                                            } else if (e.target.value === 'No') {
                                                setHasDraft(false)
                                            } else {
                                                setHasDraft(null)
                                            }
                                            setValue('helpDevelopDraft', '')
                                            setValue('payExtraForCoaching', '')
                                        }
                                    })}
                                >
                                    <option value={''}></option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                                <span className="text-danger">
                                    {errors?.haveDraft?.message}
                                </span>
                            </div>
                        </div>
                        {hasDraft && <div className="col-xl-6">
                            <label>Did someone help you to develop the draft?</label>
                            <div className="contact-select mb-20">
                                <select
                                    className="mb-0"
                                    style={{
                                        background: "white",
                                        border: "1px solid #2467EC",
                                        borderRadius: "10px",
                                    }}
                                    {...register("helpDevelopDraft", {
                                        required: "Please answer if you got help",
                                        onChange: (e) => {
                                            if (showCreateError) {
                                                setShowCreateError(false)
                                            }
                                        }
                                    })}
                                >
                                    <option value={''}></option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                                <span className="text-danger">
                                    {errors?.helpDevelopDraft?.message}
                                </span>
                            </div>
                        </div>}
                        <div className="col-xl-6">
                            <label>First Name</label>
                            <div className="contact-from-input mb-20">
                                <input
                                    type="text"
                                    {...register("firstName", {
                                        required: "First Name is required",
                                        onChange: (e) => {
                                            if (showCreateError) {
                                                setShowCreateError(false)
                                            }
                                        }
                                    })}
                                />
                                <span className="text-danger">
                                    {errors?.firstName?.message}
                                </span>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <label>Last Name</label>
                            <div className="contact-from-input mb-20">
                                <input
                                    type="text"
                                    {...register("lastName", {
                                        required: "Last Name is required",
                                        onChange: (e) => {
                                            if (showCreateError) {
                                                setShowCreateError(false)
                                            }
                                        }
                                    })}
                                />
                                <span className="text-danger">
                                    {errors?.lastName?.message}
                                </span>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <label>Highest Level of Education</label>
                            <div className="contact-select">
                                <select
                                    className="mb-20"
                                    {...register("highestLevelOfEducation", {
                                        required: "Highest Level of Education is required",
                                        onChange: (e) => {
                                            if (showCreateError) {
                                                setShowCreateError(false)
                                            }

                                            if (e.target.value === "High School") {
                                                setIsHighSchool(true)
                                            } else {
                                                setIsHighSchool(false)
                                            }
                                        }
                                    })}
                                >
                                    <option value={''}></option>
                                    <option value="High School">High School</option>
                                    <option value="Some College">Some College</option>
                                    <option value="Bachelor's">Bachelor's</option>
                                    <option value="Master's">Master's</option>
                                    <option value="Doctorate">Doctorate</option>
                                    <option value="Professional">Professional (e.g., MBA, MD, JD etc)</option>
                                </select>
                                <span className="text-danger">{errors?.highestLevelOfEducation?.message}</span>
                            </div>
                        </div>
                        {!isHighSchool && <div className="col-xl-6">
                            <label>List your degrees, starting with the highest.</label>
                            <div className="mb-20 table-responsive">
                                <table className="table table-sm">
                                    <thead>
                                        <tr>
                                            <th className="pr-10 pb-10">Degree</th>
                                            <th className="pr-10 pb-10">University Name</th>
                                            <th className="pr-10 pb-10">Graduation Status</th>
                                            <th className="pr-10 pb-10">Graduation Year</th>
                                            <th className="pr-10 pb-10">Country</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="pr-10 pb-20"><input
                                                style={{ border: '1px solid #2467EC' }}
                                                {...register("degreeObtained1", {
                                                    onChange: (e) => {
                                                        if (showCreateError) {
                                                            setShowCreateError(false)
                                                        }
                                                        if (showChoiceUniversitiesError) {
                                                            setShowChoiceUniversitiesError(false)
                                                        }
                                                    }
                                                })}
                                            /></td>
                                            <td className="pr-10 pb-20"><input
                                                style={{ border: '1px solid #2467EC' }}
                                                {...register("schoolName1", {
                                                    onChange: (e) => {
                                                        if (showCreateError) {
                                                            setShowCreateError(false)
                                                        }
                                                        if (showChoiceUniversitiesError) {
                                                            setShowChoiceUniversitiesError(false)
                                                        }
                                                    }
                                                })}
                                            /></td>
                                            <td className="pr-10 pb-20"><select
                                                style={{ border: '1px solid #2467EC' }}
                                                {...register("graduationStatus1", {
                                                    onChange: (e) => {
                                                        if (showCreateError) {
                                                            setShowCreateError(false)
                                                        }
                                                        if (showChoiceUniversitiesError) {
                                                            setShowChoiceUniversitiesError(false)
                                                        }
                                                    }
                                                })}
                                            >
                                                <option value={''}></option>
                                                <option value="In-progress">In-progress</option>
                                                <option value="Completed">Completed</option>
                                            </select></td>
                                            <td className="pr-10 pb-20"><input
                                                type="number"
                                                min={1900}
                                                style={{ border: '1px solid #2467EC' }}
                                                {...register("yearOfGraduation1", {
                                                    onChange: (e) => {
                                                        if (showCreateError) {
                                                            setShowCreateError(false)
                                                        }
                                                        if (showChoiceUniversitiesError) {
                                                            setShowChoiceUniversitiesError(false)
                                                        }
                                                    }
                                                })}
                                            /></td>
                                            <td className="pr-10 pb-20"><input
                                                style={{ border: '1px solid #2467EC' }}
                                                {...register("schoolCountry1", {
                                                    onChange: (e) => {
                                                        if (showCreateError) {
                                                            setShowCreateError(false)
                                                        }
                                                        if (showChoiceUniversitiesError) {
                                                            setShowChoiceUniversitiesError(false)
                                                        }
                                                    }
                                                })}
                                            /></td>
                                        </tr>
                                        <tr>
                                            <td className="pr-10 pb-20"><input
                                                style={{ border: '1px solid #2467EC' }}
                                                {...register("degreeObtained2", {
                                                    onChange: (e) => {
                                                        if (showCreateError) {
                                                            setShowCreateError(false)
                                                        }
                                                        if (showChoiceUniversitiesError) {
                                                            setShowChoiceUniversitiesError(false)
                                                        }
                                                    }
                                                })}
                                            /></td>
                                            <td className="pr-10 pb-20"><input
                                                style={{ border: '1px solid #2467EC' }}
                                                {...register("schoolName2", {
                                                    onChange: (e) => {
                                                        if (showCreateError) {
                                                            setShowCreateError(false)
                                                        }
                                                        if (showChoiceUniversitiesError) {
                                                            setShowChoiceUniversitiesError(false)
                                                        }
                                                    }
                                                })}
                                            /></td>
                                            <td className="pr-10 pb-20"><select
                                                style={{ border: '1px solid #2467EC' }}
                                                {...register("graduationStatus2", {
                                                    onChange: (e) => {
                                                        if (showCreateError) {
                                                            setShowCreateError(false)
                                                        }
                                                        if (showChoiceUniversitiesError) {
                                                            setShowChoiceUniversitiesError(false)
                                                        }
                                                    }
                                                })}
                                            >
                                                <option value={''}></option>
                                                <option value="In-progress">In-progress</option>
                                                <option value="Completed">Completed</option>
                                            </select></td>
                                            <td className="pr-10 pb-20"><input
                                                type="number"
                                                min={1900}
                                                style={{ border: '1px solid #2467EC' }}
                                                {...register("yearOfGraduation2", {
                                                    onChange: (e) => {
                                                        if (showCreateError) {
                                                            setShowCreateError(false)
                                                        }
                                                        if (showChoiceUniversitiesError) {
                                                            setShowChoiceUniversitiesError(false)
                                                        }
                                                    }
                                                })}
                                            /></td>
                                            <td className="pr-10 pb-20"><input
                                                style={{ border: '1px solid #2467EC' }}
                                                {...register("schoolCountry2", {
                                                    onChange: (e) => {
                                                        if (showCreateError) {
                                                            setShowCreateError(false)
                                                        }
                                                        if (showChoiceUniversitiesError) {
                                                            setShowChoiceUniversitiesError(false)
                                                        }
                                                    }
                                                })}
                                            /></td>
                                        </tr>
                                        <tr>
                                            <td className="pr-10 pb-20"><input
                                                style={{ border: '1px solid #2467EC' }}
                                                {...register("degreeObtained3", {
                                                    onChange: (e) => {
                                                        if (showCreateError) {
                                                            setShowCreateError(false)
                                                        }
                                                        if (showChoiceUniversitiesError) {
                                                            setShowChoiceUniversitiesError(false)
                                                        }
                                                    }
                                                })}
                                            /></td>
                                            <td className="pr-10 pb-20"><input
                                                style={{ border: '1px solid #2467EC' }}
                                                {...register("schoolName3", {
                                                    onChange: (e) => {
                                                        if (showCreateError) {
                                                            setShowCreateError(false)
                                                        }
                                                        if (showChoiceUniversitiesError) {
                                                            setShowChoiceUniversitiesError(false)
                                                        }
                                                    }
                                                })}
                                            /></td>
                                            <td className="pr-10 pb-20"><select
                                                style={{ border: '1px solid #2467EC' }}
                                                {...register("graduationStatus3", {
                                                    onChange: (e) => {
                                                        if (showCreateError) {
                                                            setShowCreateError(false)
                                                        }
                                                        if (showChoiceUniversitiesError) {
                                                            setShowChoiceUniversitiesError(false)
                                                        }
                                                    }
                                                })}
                                            >
                                                <option value={''}></option>
                                                <option value="In-progress">In-progress</option>
                                                <option value="Completed">Completed</option>
                                            </select></td>
                                            <td className="pr-10 pb-20 "><input
                                                type="number"
                                                min={1900}
                                                style={{ border: '1px solid #2467EC' }}
                                                {...register("yearOfGraduation3", {
                                                    onChange: (e) => {
                                                        if (showCreateError) {
                                                            setShowCreateError(false)
                                                        }
                                                        if (showChoiceUniversitiesError) {
                                                            setShowChoiceUniversitiesError(false)
                                                        }
                                                    }
                                                })}
                                            /></td>
                                            <td className="pr-10 pb-20"><input
                                                style={{ border: '1px solid #2467EC' }}
                                                {...register("schoolCountry3", {
                                                    onChange: (e) => {
                                                        if (showCreateError) {
                                                            setShowCreateError(false)
                                                        }
                                                        if (showChoiceUniversitiesError) {
                                                            setShowChoiceUniversitiesError(false)
                                                        }
                                                    }
                                                })}
                                            /></td>
                                        </tr>
                                    </tbody>
                                </table>
                                {showChoiceUniversitiesError && <span className="text-danger">Enter at least one record</span>}
                            </div>
                        </div>}
                        <div className="col-xl-6">
                            <label>Employment Status</label>
                            <div className="contact-select">
                                <select
                                    className="mb-20"
                                    style={{
                                        background: "white",
                                        border: "1px solid #2467EC",
                                        borderRadius: "10px",
                                    }}
                                    {...register("employmentStatus", {
                                        onChange: (e) => {
                                            if (showCreateError) {
                                                setShowCreateError(false)
                                            }
                                        }
                                    })}
                                >
                                    <option value="EMPLOYED">{EmploymentStatus['EMPLOYED']}</option>
                                    <option value="SELF_EMPLOYED">{EmploymentStatus['SELF_EMPLOYED']}</option>
                                    <option value="UNEMPLOYED">{EmploymentStatus['UNEMPLOYED']}</option>
                                    <option value="STUDENT">{EmploymentStatus['STUDENT']}</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <label>Number of Years of Work Experience</label>
                            <div className="contact-from-input mb-20">
                                <input
                                    type="number"
                                    min={0}
                                    {...register("yearsOfWorkingExp", {
                                        onChange: (e) => {
                                            if (showCreateError) {
                                                setShowCreateError(false)
                                            }
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <label>Current Place of Work</label>
                            <div className="contact-from-input mb-20">
                                <input
                                    type="text"
                                    {...register("placeOfWork", {
                                        onChange: (e) => {
                                            if (showCreateError) {
                                                setShowCreateError(false)
                                            }
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <label>Country of Origin</label>
                            <div className="contact-select">
                                <select
                                    className="mb-20"
                                    {...register("countryOfOrigin", {
                                        required: "Country is required",
                                        onChange: (e) => {
                                            if (showCreateError) {
                                                setShowCreateError(false)
                                            }
                                        }
                                    })}
                                >
                                    {countryOptions.map((country, indx) => {
                                        return <option value={country.label} key={indx}>{country.label}</option>
                                    })}
                                </select>
                                <span className="text-danger">
                                    {errors?.countryOfOrigin?.message}
                                </span>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <label>Hobbies (if any), separated by a comma (e.g., swimming, chess)</label>
                            <div className="contact-from-input mb-20">
                                <input

                                    type="text"
                                    {...register("hobby", {
                                        onChange: (e) => {
                                            if (showCreateError) {
                                                setShowCreateError(false)
                                            }
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <label>Sex</label>
                            <div className="contact-select">
                                <select
                                    className="mb-20"
                                    {...register("sex", {
                                        required: "Sex is required",
                                        onChange: (e) => {
                                            if (showCreateError) {
                                                setShowCreateError(false)
                                            }
                                        }
                                    })}
                                >
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Prefer not to say">Prefer Not to Say</option>
                                </select>
                                <span className="text-danger">{errors?.sex?.message}</span>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <label>Date of Birth</label>
                            <div className="contact-from-input mb-20">
                                <input
                                    type="date"
                                    placeholder="Date of Birth"
                                    {...register("dateOfBirth", {
                                        required: "Date of Birth is required",
                                        onChange: (e) => {
                                            if (showCreateError) {
                                                setShowCreateError(false)
                                            }
                                        }
                                    })}
                                />
                                <span className="text-danger">{errors?.dateOfBirth?.message}</span>
                            </div>
                        </div>
                        {hasDraft !== null && !hasDraft && <div className="col-xl-6">
                            <label>Would you pay extra for One-on-One coaching to develop a draft?</label>
                            <div className="contact-select mb-20">
                                <select
                                    className="mb-0"
                                    style={{
                                        background: "white",
                                        border: "1px solid #2467EC",
                                        borderRadius: "10px",
                                    }}
                                    {...register("payExtraForCoaching", {
                                        required: "Please answer if you would pay extra for coaching",
                                        onChange: (e) => {
                                            if (showCreateError) {
                                                setShowCreateError(false)
                                            }
                                        }
                                    })}
                                >
                                    <option value={''}></option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                                <span className="text-danger">
                                    {errors?.payExtraForCoaching?.message}
                                </span>
                            </div>
                        </div>}
                        {createError && showCreateError && <div style={{ marginBottom: '25px' }}>
                            <span className="text-danger">{createError}</span>
                        </div>}

                        <div className="d-flex justify-content-end align-items-center">
                            <div className="colxl-2 ">
                                <div className="cont-btn mb-20" onClick={handleSubmit(createApplicant)}>
                                    <div className="cont-btn">{load ? (nextButtonText === 'Next' ? 'Loading...' : 'Submitting...') : `${nextButtonText}`}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

function dateOutFormat(date) {
    if (!date) return ''

    const dateArray = date.split('-')
    return `${dateArray[1]}-${dateArray[2]}-${dateArray[0]}`
}

function dateInputFormat(date) {
    if (!date) return ''

    const dateArray = date.split('-')
    return `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`
}

function setChoiceUniversities(data, userId) {
    const choiceUniversities = []
    if (data.schoolName1 && data.degreeObtained1 && data.graduationStatus1
        && data.schoolCountry1 && data.yearOfGraduation1) {
        choiceUniversities.push(
            {
                country: data.schoolCountry1,
                degreeObtained: data.degreeObtained1,
                schoolName: data.schoolName1,
                graduationStatus: data.graduationStatus1,
                userId: userId,
                userType: "APPLICANT",
                yearOfGraduation: data.yearOfGraduation1
            }
        )
    }
    delete data.schoolCountry1
    delete data.degreeObtained1
    delete data.schoolName1
    delete data.graduationStatus1
    delete data.yearOfGraduation1

    if (data.schoolName2 && data.degreeObtained2 && data.graduationStatus2
        && data.schoolCountry2 && data.yearOfGraduation2) {
        choiceUniversities.push(
            {
                country: data.schoolCountry2,
                degreeObtained: data.degreeObtained2,
                schoolName: data.schoolName2,
                graduationStatus: data.graduationStatus2,
                userId: userId,
                userType: "APPLICANT",
                yearOfGraduation: data.yearOfGraduation2
            }
        )
    }
    delete data.schoolCountry2
    delete data.degreeObtained2
    delete data.schoolName2
    delete data.graduationStatus2
    delete data.yearOfGraduation2

    if (data.schoolName3 && data.degreeObtained3 && data.graduationStatus3
        && data.schoolCountry3 && data.yearOfGraduation3) {
        choiceUniversities.push(
            {
                country: data.schoolCountry3,
                degreeObtained: data.degreeObtained3,
                schoolName: data.schoolName3,
                graduationStatus: data.graduationStatus3,
                userId: userId,
                userType: "APPLICANT",
                yearOfGraduation: data.yearOfGraduation3
            }
        )
    }
    delete data.schoolCountry3
    delete data.degreeObtained3
    delete data.schoolName3
    delete data.graduationStatus3
    delete data.yearOfGraduation3

    data.choiceUniversities = choiceUniversities
    return data.choiceUniversities.length !== 0
}