import React from "react";
import Footer from "../components/Layout/Footer/Footer";
import HeaderTop from "../components/Layout/Header/top-header";
import { useSelector } from "react-redux";
//import { Redirect } from "react-router-dom";
import Faq from "../components/Faq";
import "../index.css"
export default function Faqs() {
  const auth = useSelector((state) => state.auth);

  return (
    <>
      <HeaderTop auth={auth} />
      <div className="home-section container faq-p">
      <Faq />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
}
