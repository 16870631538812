import axios from "axios";
import config from "../config";
import { addApplications } from "../redux/features/applicantSlice";

const getApplicationsThunkCreator = (setLoading, userId) => {
    return async (dispatch) => {
        try {
            const res = await axios.get(`${config.baseUrl}/api/v1/application/getApplictionsByUserId?userId=${userId}`)
            setLoading(false)
            if (res.status === 200 && res.data.result) {
                dispatch(addApplications(res.data.result))
            }
            else {
                // TODO: imporve error handling here
                console.log(res)
            }
        } catch (e) {
            setLoading(false)
            // TODO: imporve error handling here
            console.log(e)
        }
    }
};

export default getApplicationsThunkCreator;