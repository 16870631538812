import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import config from "../../config";
import axios from "axios";
import "./index.css";
import { Redirect, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CheckoutForm from "../../components/ReviewRequest/CheckoutForm";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import closeBtn from "../../assets/img/close-btn-dark.svg";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
export const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY ||
    config.TEST_PUBLISHABLE_API_KEY
);

export default function OrderCheckout({
  open,
  setOpen,
  applicationId,
  loading,
  checkoutFailed,
  clientSecret,
}) {
  const auth = useSelector((state) => state.auth);

  if (!auth.authenticated) {
    return <Redirect to="/" />;
  }

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        title={false}
        visible={open}
        footer={false}
        width={600}
        maskClosable={false}
        wrapClassName="example"
        style={{ marginTop: "-1rem" }}
        bodyStyle={{ height: "100%" }}
      >
        <div
          onClick={closeModal}
          className="d-flex justify-content-end align-items-center"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div>
          {loading ? (
            <div className="d-flex flex-column align-items-center m-auto w-75 justify-content-center min-vh-100">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : checkoutFailed ? (
            <div className="error-btn">
              <Link to="/orders" className="edu-btn">
                <span className="text-danger">Error </span>,...Try again
              </Link>
            </div>
          ) : (
            clientSecret && (
              <div className="d-flex justify-content-center align-items-center h-100 ">
                <div className="stripe-padon">
                  <h5 className="mb-40">Please enter your payment details:</h5>
                  <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm />
                  </Elements>
                </div>
              </div>
            )
          )}
        </div>
      </Modal>
    </>
  );
}
