import { configureStore } from '@reduxjs/toolkit';
import authSlice from './features/authSlice';
import applicantSlice from './features/applicantSlice';
import applicationSlice from './features/applicationSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    applicant: applicantSlice,
    application: applicationSlice
  }
})