import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { moreInfoThunkCreator } from "../../redux-thunk/moreInfoThunkCreator";



export default function ApplicationEdit({ application, next }) {
    const dispatch = useDispatch()
    const [load, setLoad] = useState(false)
    const [createError, setCreateError] = useState('')
    const [showCreateError, setShowCreateError] = useState(false)
    const [applicantReviewerMsgRequestList, setApplicantReviewerMsgRequestList] = useState([]);

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    useEffect(() => {
        setApplicantReviewerMsgRequestList(application.applicationMoreInformationResponse)
    }, [])

    const updateApplication = (data) => {

        const moreInfoData = {
            applicationId: application.id,
            applicantReviewerMsgRequestList
        }

        dispatch(moreInfoThunkCreator(setLoad, setShowCreateError, setCreateError, moreInfoData, next))
    }

    return (
        <div className="mobile-space-reviews">
            <div>
                <h4>Please answer the following questions to provide more information required.</h4>
            </div>
            <br />
            <form action="#">
                <div className="row">
                    {application.applicationMoreInformationResponse.map((item, index) => (
                        (!(item.applicantAnswer) ? <div className="col-xl-6" key={index}>
                            <label><p>{item.reviewerQuestion}</p></label>
                            <div className="contact-from-input mb-20">
                                <textarea
                                    {...register(`${item.id}`, {
                                        required: "Answer is required",
                                        onChange: (e) => {
                                            if (showCreateError) {
                                                setShowCreateError(false)
                                            }
                                            const newRequestList = cloneDeep(applicantReviewerMsgRequestList)
                                            newRequestList[index].applicantAnswer = e.target.value
                                            setApplicantReviewerMsgRequestList(newRequestList)
                                        }
                                    })}
                                />
                            </div>
                            <span className="text-danger">{errors?.[item.id]?.message}</span>
                        </div> : null)
                    ))}
                    {createError && showCreateError && <div style={{ marginBottom: '25px' }}>
                        <span className="text-danger">{createError}</span>
                    </div>}
                    <div className="d-flex align-items-center justify-content-end">
                        <div className="d-flex align-items-center">
                            <div className="colxl-2 ">
                                <div className="cont-btn mb-20" onClick={handleSubmit(updateApplication)}>
                                    <div className="cont-btn">{load ? 'Submitting...' : 'Submit'}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}