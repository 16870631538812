import React, { useState } from "react";
import useSticky from "../../../hooks/useSticky";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Drawer from "react-modern-drawer";
import MobileMenu from "./MobileMenu";
import { SampleDownloadDatas } from "./SampleDownload";
import "react-modern-drawer/dist/index.css";
import axios from "axios";
import closeBtn from "../../../assets/img/close-btn-dark.svg";
import config from "../../../config";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import LogWhite from "../../../assets/img/Logwhite.svg";
import FrameBook from "../../../assets/img/framebuka.png";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Button/Index";
import "./index.css";
import { clearAuth } from "../../../redux/features/authSlice";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Input } from "../../../components/Input//index";
import { ReactComponent as Edyter } from "../../../assets/img/logo/edyterPurple.svg";
import { clearApplicant } from "../../../redux/features/applicantSlice";
import { clearSession } from "../../../redux/SessionStorageUtils";
import { clearApplication } from "../../../redux/features/applicationSlice";
import { logoutThunkCreator } from "../../../redux-thunk/loginThunkCreator";
import closeIcon from "../../../assets/img/close-icon.svg";
import menuBar from "../../../assets/img/menu-bar.svg";
import Logo from "../../../assets/img/Log.svg";
import { Rate, Modal, Popover } from "antd";
import { success, error } from "../../Alerts/Index";

const Header = ({ auth }) => {
  // sticky nav
  const { sticky } = useSticky();
  const dispatch = useDispatch();
  const location = useLocation();
  const activeRoute = location.pathname;
  const [downloadSample, setDownloadSample] = useState(false);
  const [load, setLoad] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [downloadFiles, setDownloadFiles] = useState({});
  const [opendownloadDocs, setOpenDownDocs] = useState(false);

  const [loadDownload, setLoadDownload] = useState(false);
  const closeDownloadSample = () => {
    setDownloadSample(false);
  };

  const downloadSampleFunc = async (data) => {
    setLoadDownload(true);
    try {
      const res = await axios.post(
        `${config.baseUrl}/api/v1/auth/addPotentialUser`,
        {
          fullName: data.name,
          email: data.email,
        }
      );
      if (res?.status === 200) {
        setLoadDownload(false);
        setOpenDownDocs(true);
        setDownloadSample(false);
        setDownloadFiles(res?.data?.result?.listOfSampleDocs);
        reset({
          name: null,
          email: null,
        });
      } else {
        console.log(res);
        success("Error", res.data.message);
      }
    } catch (e) {
      setLoadDownload(false);
      error("Error", e?.response?.data?.message);
    }
  };

  const [signinOpen, setSignInOpen] = useState(false);
  const [signupOpen, setSignUpOpen] = useState(false);

  const closeModalDowloadSample = () => {
    setDownloadSample(false);
  };

  const content = (
    <div>
      <ul>
        <li>Statement of purpose</li>
        <li>Personal statement</li>
        <li>Diversity statement</li>
        <li>Scholarhip essays</li>
        <li>Motivation letter</li>
        <li>Statement of interest</li>
      </ul>
    </div>
  );
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const toggleDrawer = () => setOpen(!open);

  return (
    <div>
      <div className="new-header">
        <div className=" show-mobile">
          <br />
          <div className="d-flex w-100 justify-content-between align-items-center">
            <Link to="/" className="mD-auto">
              <img src={Logo} className="w-100" />
            </Link>
            <div className="text-dark" onClick={toggleDrawer}>
              <img src={menuBar} className="w-100" />
            </div>
          </div>
        </div>
        <div className="container-custom-new">
          <div
            className="pt-3 show-web fixed-top container-custom-new shadow-sm shadow-sm bg-white"
            style={{ height: "75px" }}
          >
            <div className="d-flex justify-content-between align-items-center ">
              <Link to="/">
                <div className="">
                  <img src={Logo} className="w-100" />
                </div>
              </Link>
              <div
                className="d-flex align-items-center"
                style={{ cursor: "pointer" }}
              >
                <Link to="/faqs">
                  <div className="mr-5 link-hover-ed  space-right primary-col bold-num">
                    FAQs
                  </div>
                </Link>
                <div
                  className="mr-5 space-right primary-col bold-num link-hover-ed "
                  onClick={() => setSignInOpen(true)}
                >
                  Sign in
                </div>
                <div
                  className="sign-up-head  "
                  onClick={() => setSignUpOpen(true)}
                >
                  Sign up
                </div>
              </div>
            </div>
          </div>

          <div className="header-viewport">
            <div className="header-flex">
              <div className="d-flex justify-content-end image-head-lead ">
                <img src={FrameBook} className="img-book show-mobile" />
              </div>
              <div className="w-100">
                <h2 className="header-lead">
                  Want a <span className="down-mark-line"> top-notch</span> edit<br />
                  on your application <br /> statements?{" "}
                </h2>
                <div className="sub-lead">
                  {" "}
                  Edyter is a{" "}
                  <Popover content={content} title="" trigger="hover">
                    <span style={{ color: "#f8a401", cursor: "pointer" }}>
                      {" "}
                      one-stop platform
                    </span>
                  </Popover>{" "}
                  for polishing your work.
                  <br />
                  Say goodbye to review headaches!
                </div>

                <br />
                <div className="pt-4 btn-head">
                  <div className="btn-lead-head">
                    <div
                      className="sign-up-orange space-right-margin"
                      onClick={() => setSignUpOpen(true)}
                    >
                      Get started
                    </div>

                    <div
                      className="download-sample cursor"
                      onClick={() => setDownloadSample(true)}
                    >
                      <span style={{ fontWeight: "bold", color: "black" }}>Download samples</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-end image-head-lead">
                  <img src={FrameBook} className="img-book show-web" />
                </div>
                <div className="show-web d-none d-flex justify-content-end mt-3">
                  <div className="d-flex align-items-center">
                    <div style={{ marginRight: "1rem" }}>
                      <Rater total={5} rating={5} isActive={true} />
                    </div>
                    <div className="bold-num">5/5</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        open={open}
        onClose={toggleDrawer}
        style={{
          width: "100%",
          background: "#382d8b",
          color: "#fff",
          height: "100vh",
        }}
        direction="right"
      >
        <div>
          <div className="draw-head">
            <div className="d-flex justify-content-between align-items-center">
              <div onClick={() => (window.location.href = "/")}>
                {" "}
                <img src={LogWhite} className="w-100" />
              </div>
              <div onClick={() => setOpen(false)}>
                <img src={closeIcon} width="27" />
              </div>
            </div>
          </div>
          <hr />

          <div className="d-flex justify-content-center align-items-center">
            <div style={{ marginTop: "3rem" }}>
              <Link to="/faqs">
                <div>FAQS</div>
              </Link>
              <br />
              <div onClick={() => setSignInOpen(true)}>Sign in</div>
            </div>
            <br />
          </div>
          <div>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "3rem" }}
            >
              <div
                className="sign-up-orange-mob"
                onClick={() => setSignUpOpen(true)}
              >
                Sign up
              </div>
            </div>
          </div>
        </div>
      </Drawer>

      <SignIn
        signinOpen={signinOpen}
        setSignInOpen={setSignInOpen}
        setSignUpOpen={setSignUpOpen}
        auth={auth}
      />
      <SignUp
        signupOpen={signupOpen}
        setSignUpOpen={setSignUpOpen}
        setSignInOpen={setSignInOpen}
      />

      <Modal
        title={false}
        visible={downloadSample}
        footer={false}
        width={600}
        onCancel={closeModalDowloadSample}
        maskClosable={true}
        centered={true}
        wrapClassName="example"
      >
        <div
          onClick={closeModalDowloadSample}
          className="d-flex justify-content-end align-items-center cursor"
        >
          <img src={closeBtn} width="15" />
        </div>

        <div className="d-flex justify-content-center align-items-center">
          <div className="form-inputs">
            <div
              className="text-center col-lead-sample h2"
              style={{ fontSize: "18px" }}
            >
              Enter your email to download a sample.
            </div>
            <br />
            <div className="mb-4">
              <Input
                label=""
                require
                placeholder="Name"
                name="name"
                type="email"
                {...register("name", {
                  required: "Enter Name",
                })}
                error={errors?.name?.message}
              />
            </div>
            <div className="mb-4">
              <Input
                label=""
                require
                placeholder="Email address"
                name="email"
                type="email"
                {...register("email", {
                  required: "Enter Email",
                })}
                error={errors?.email?.message}
              />
            </div>

            <div
              className={`${loadDownload ? "is-disabled" : ""
                } sing-buttom mb-20`}
              onClick={handleSubmit(downloadSampleFunc)}
            >
              <button type="submit" className="sing-btn">
                {loadDownload ? "LOADING..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <SampleDownloadDatas
        open={opendownloadDocs}
        setOpen={setOpenDownDocs}
        downloadDatas={downloadFiles}
      />
    </div>
  );
};

export const clearStore = (dispatch) => {
  dispatch(clearAuth());
  dispatch(clearApplicant());
  dispatch(clearApplication());
  clearSession();
};

export default Header;
