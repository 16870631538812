import { createSlice } from "@reduxjs/toolkit";
import { getSession } from "../SessionStorageUtils";

export const authSlice = createSlice({
  name: 'auth',
  initialState: getSession() || {
    session: {},
    authenticated: false,
    errorMsg: null,
  },
  reducers: {
    authSuccess: (state, action) => {
      state.session = action.payload;
      state.authenticated = true;
      state.errorMsg = null;
    },
    authFailure: (state, action) => {
      state.session = {};
      state.authenticated = false;
      state.errorMsg = action.payload.errorMsg
    },
    clearAuth: (state) => {
      state.session = {};
      state.authenticated = false;
      state.errorMsg = null
    },
  },
})

// Action creators are generated for each case reducer function
export const { authSuccess, authFailure, clearAuth } = authSlice.actions;

export default authSlice.reducer;