import axios from "axios";
import { authFailure, authSuccess } from "../redux/features/authSlice";
import config from "../config";
import { setAuthToken } from "../serviceApis/authentication";
import { PersistSession } from "../redux/SessionStorageUtils";
import { error, success } from "../components/Alerts/Index";

const loginThunkCreator = (
  setLoad,
  setShowAuthError,
  closeModal,
  data,
  setOpenActivationModal
) => {
  return async (dispatch) => {
    setLoad(true);
    try {
      const res = await axios.post(`${config.baseUrl}/api/v1/auth/login`, data);
      setLoad(false);
      if (
        res?.status === 200 &&
        res?.data?.result?.user?.roles?.includes?.("APPLICANT")
      ) {
        dispatch(authSuccess(res.data.result));
        closeModal();
        setAuthToken(res.data.result.accessToken);
        PersistSession({
          session: res.data.result,
          authenticated: true,
          errorMsg: null,
        });
      } else {
        console.log(res);
        dispatch(authFailure({ errorMsg: "Something went wrong. Try again" }));
        error("Error", "Something went wrong. Try again");
        setShowAuthError(true);
      }
    } catch (e) {
      console.log(e);
      setLoad(false);
      if (
        e?.response?.status === 401 &&
        isJson(e?.response?.data?.message) &&
        JSON.parse(e?.response?.data?.message).msg === "User is disabled"
      ) {
        dispatch(requestActivationCodeThunkCreator(data.email));
        closeModal(true); // is pending user
        setOpenActivationModal(true);
      } else {
        if (e?.response?.status === 401 || e?.response?.status === 400) {
          dispatch(authFailure({ errorMsg: "Email or password is incorrect" }));
        } else {
          dispatch(
            authFailure({ errorMsg: "Something went wrong. Try again" })
          );
        }
        setShowAuthError(true);
      }
    }
  };
};

export const requestActivationCodeThunkCreator = (pendingUserEmail) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${config.baseUrl}/api/v1/auth/resendToken/email/${pendingUserEmail}`
      );
      if (res?.status !== 200) {
        console.log(res);
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const logoutThunkCreator = (userId, sessionId) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${config.baseUrl}/api/v1/auth/logout/${userId}/${sessionId}`
      );
      if (res?.status !== 200) {
        console.log(res);
      }
    } catch (e) {
      console.log(e);
    }
  };
};

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export default loginThunkCreator;
