import axios from "axios";
import config from "../config";
import { success, error } from "../components/Alerts/Index";
import {
  addApplicant,
  applicationResult,
} from "../redux/features/applicantSlice";

export const createApplicantThunkCreator = (
  setLoadProfile,
  setShowCreateError,
  setCreateError,
  data,
  history,
  
) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${config.baseUrl}/api/v1/applicant/add`,
        data
      );
      if (res?.status === 200 && res.data.status) {
        setLoadProfile(false);
        setTimeout(() => {
          window.location.href = "/orders";
        }, 1000);

        dispatch(addApplicant(res.data.result));
        success("Success", "Profile Added Successfully");
      } else {
        dispatch(
          updateApplicantThunkCreator(
            setLoadProfile,
            setShowCreateError,
            setCreateError,
            data,
          )
        );
      }
    } catch (e) {
      console.log(e);
      setLoadProfile(false);
    }
  };
};

export const updateApplicantThunkCreator = (
  setLoadProfile,
  setShowCreateError,
  setCreateError,
  data,
  
) => {
  return async (dispatch) => {
    try {
      const res = await axios.put(
        `${config.baseUrl}/api/v1/applicant/userDetail/${data.userId}`,
        data
      );
      if (res?.status === 200 && res.data.result) {
        setLoadProfile(false);
        setTimeout(() => {
          window.location.href = "/orders";
        }, 1000);
        dispatch(addApplicant(res.data.result));
        success("Success", "Profile Updated Successfully");
      } else {
        console.log(res);
        error("error", "Something went wrong. Try again");
      }
    } catch (e) {
      console.log(e);
      setLoadProfile(false);
    }
  };
};

export const updateApplicantWithApplicationIdThunkCreator = (
  setLoad,
  setShowCreateError,
  setCreateError,
  data,
  next,
  applicationId
) => {
  return async (dispatch) => {
    setLoad(true);
    try {
      const res = await axios.put(
        `${config.baseUrl}/api/v1/applicant/userDetail/${data.userId}/${applicationId}`,
        data
      );
      setLoad(false);
      if (res?.status === 200 && res.data.result) {
        dispatch(addApplicant(res.data.result));
        success("Success", "Profile Updated Successfully");
      } else {
        console.log(res);
        error("Error", "Something went wrong. Try again");
      }
    } catch (e) {
      console.log(e);
      setLoad(false);
      error("Error", "Something went wrong. Try again");
    }
  };
};
