import React from "react";
import Footer from "../../components/Layout/Footer/Footer";
import Header from "../../components/Layout/Header";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import ApplicationEdit from "../../components/ReviewRequest/ApplicationEdit";

export default function ReviewRequestApplicationEdit() {
    const auth = useSelector(state => state.auth)
    const history = useHistory()
    const applications = useSelector(state => state.applicant.applications)
    const { id } = useParams()

    if (!auth.authenticated) {
        return <Redirect to="/" />
    }

    if (applications.length === 0) {
        return <Redirect to="/orders" />
    }

    const application = applications?.[id]


    const next = () => {
        history.push('/orders/order-update-confirmation')
    };


    return (
        <>
            <Header auth={auth} />
            <main>
                <div className="container-padding top-margin">
                    <ApplicationEdit application={application} next={next} />
                </div>
            </main>
            <Footer />
        </>
    );
}
