import { useState, useEffect, useMemo } from "react";
import "./index.css";
import { Button } from "../../components/Button/Index";
import { Modal } from "antd";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import config from "../../config";
import { DashboardContainer } from "../../components/DashboardContainer";
import { InputBox } from "../../components/Input/InputApplication";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import MyComponent from "react-fullpage-custom-loader";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import closeBtn from "../../assets/img/close-btn-dark.svg";
import React, { useCallback } from "react";
import ConfirmDraft, { DraftConfirmation } from "./Review-Draft";
import closeX from "../../assets/img/closeX.svg";
import information from "../../assets/img/i-info.svg";
import { createApplicationThunkCreator } from "../../redux-thunk/createApplicationThunkCreator";
import getApplicationThunkCreator from "../../redux-thunk/getApplicationThunkCreator";
import OrderCheckout from "../../pages/orders/order-checkout";
import { Rate, Popover } from "antd";
import { PaymentSummary } from "./PaymentSummary";
import {
  createApplicantThunkCreator,
  updateApplicantWithApplicationIdThunkCreator,
} from "../../redux-thunk/createApplicantThunkCreator";
import countryList from "react-select-country-list";
import ActiveOne from "./Active-one";
import ActiveTwo from "./Active-two";
import ActiveThree from "./Active-three";

import { cloneDeep } from "lodash";
import { success, error } from "../../components/Alerts/Index";
export const RequestReview = ({ open, setOpen, applicant, applicationId }) => {
  const closeModal = () => {
    setOpen(false);
    setFirstPhase(true);
    setSecondPhase(false);
    setThirdPhase(false);
  };

  const style = {
    height: "50px",
    background: "#FFFFFF !important",
    width: "100%",
    border: "1px solid #B7B7B7 !important",
    borderRadius: "0.960832px",
    outlineColor: "#B7B7B7",
    outline: "1px solid #B7B7B7",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
  };
  const [cvUpload, setCvUpload] = useState(false);
  const [sopUpload, setSopUpload] = useState(false);
  const [checkOutFailed, setCheckoutFailed] = useState(false);
  const [clientSecret, setClientSecret] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadPayment, setLoadPayment] = useState(false);
  const [openModaInfo, setOpenModaInfo] = useState(false);
  const [pageNul, setPageNull] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm({});
  const session = useSelector((state) => state.auth.session);
  const location = useLocation();
  const onApplicationResponse = useSelector(
    (state) => state.application.applicationSubmittedResult
  );
  const application = useSelector((state) => state.application.object);
  const reveiewApplicationID = location?.state?.id;
  const validateRequestApplication = location?.state?.validateRequestApplication
  const makePaymentApplication = location?.state?.makePaymentApplication
  const dispatch = useDispatch();
  const [checkOutOpen, setCheckOutOpen] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const history = useHistory();
  const [load, setLoad] = useState(true);
  const [applicationID, setApplicationID] = useState(null);
  const [draftConfirmation, setDraftConfirmation] = useState(false);
  const [createError, setCreateError] = useState("");
  const [showCreateError, setShowCreateError] = useState(false);
  const [showChoiceUniversitiesError, setShowChoiceUniversitiesError] =
    useState(false);
  const countryOptions = useMemo(() => countryList().getData(), []);
  const [isHighSchool, setIsHighSchool] = useState(false);
  const [hasDraft, setHasDraft] = useState(null);

  const [reviewDatas, setReveiewDatas] = useState({});
  const [secondPhase, setSecondPhase] = useState(false);
  const [firstPhase, setFirstPhase] = useState(true);
  const [thirdPhase, setThirdPhase] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const [loadOne, setLoadOne] = useState(false);

  const cv = watch("cv");
  const sop = watch("sop");
  const specificIntendedField = watch("intendedFieldofStudy");
  const fieldOfStudy = watch("fieldOfStudy");

  useEffect(() => {
    if (reveiewApplicationID) {
      dispatch(getApplicationThunkCreator(reveiewApplicationID, setLoad));
    } else {
      setLoad(false);
    }
  }, [reveiewApplicationID]);

  const proceedToSecond = (data) => {
    if (data) {
      submitPhaseOne();
    }
  };

  useEffect(() => {
    reset({
      fieldOfStudy: application?.fieldOfStudy,
      intendedFieldofStudy: application?.specificIntendedField,
    });
  }, [application]);

  useEffect(() => {
    if (validateRequestApplication?.id) {
      console.log(`validate request id: ${validateRequestApplication?.id}`)
      setFirstPhase(false);
      setThirdPhase(false);
      setDraftConfirmation(true);
      setSecondPhase(true);
    }
  }, [validateRequestApplication?.id])

  useEffect(() => {
    if (makePaymentApplication?.id) {
      showPaymentCheckout()
    }
  }, [makePaymentApplication?.id])

  const submitPhaseOne = () => {
    setLoadOne(true);
    const data = {
      userId: session?.user?.id,
      fieldOfStudy: fieldOfStudy,
      specificIntendedField: specificIntendedField,
    };
    console.log(`review id: ${reveiewApplicationID}`)
    console.log(`application id: ${application?.id}`)
    if (!(reveiewApplicationID || application?.id)) {
      console.log("created")
      axios
        .post(`${config.baseUrl}/api/v1/application/create`, data)
        .then((res) => {
          setLoadOne(false);
          if (res.status === 200 && res?.data?.result) {
            setFirstPhase(false);
            setThirdPhase(false);
            setSecondPhase(true);
            setApplicationID(res.data?.result?.id);
            success("Success", res.data.message);
            dispatch(getApplicationThunkCreator(res.data?.result?.id, setLoad));
          } else {
          }
        })
        .catch((e) => {
          setLoadOne(false);
          error("Error", e?.response?.data?.message);
        });
    } else {
      console.log("updated")
      axios
        .put(
          `${config.baseUrl}/api/v1/application/update/${reveiewApplicationID ? reveiewApplicationID : application?.id}`,
          data
        )
        .then((res) => {
          setLoadOne(false);
          if (res.status === 200 && res?.data?.result) {
            setFirstPhase(false);
            setThirdPhase(false);
            setSecondPhase(true);
            setApplicationID(res.data?.result?.id);
            success("Success", res.data.message);
            dispatch(getApplicationThunkCreator(res.data?.result?.id, setLoad));
          } else {
          }
        })
        .catch((e) => {
          setLoadOne(false);
          error("Error", e?.response?.data?.message);
        });
    }
  };

  const content = (
    <div style={{ width: "40%" }}>
      <ul>
        <li>
          <div className="d-flex align-items-center">
            <div>1. </div>
            <div>Why do you have an interest in the program? </div>
          </div>
        </li>
        <li>
          <div className="d-flex align-items-center">
            <div>2. </div>
            <div>
              {" "}
              What particular activities or efforts have you undertaken that
              demonstrate initiative and preparation for the program?{" "}
            </div>
          </div>
        </li>
        <li>
          <div className="d-flex align-items-center">
            <div>3. </div>
            <div>
              {" "}
              skills, attributes, awards, achievements, experiences and
              exposures make you a suitable candidate for the program?{" "}
            </div>
          </div>
        </li>
        <li>
          <div className="d-flex align-items-center">
            <div>4. </div>
            <div>
              {" "}
              What specific objective do you hope to achieve from the program?{" "}
            </div>
          </div>
        </li>
        <li>
          <div className="d-flex align-items-center">
            <div>5. </div>
            <div>
              {" "}
              Include any other information that supports your application. Such
              information might include publications, reason for low GPA,
              changing fields, particular professors in your field or special
              topics of interest
            </div>
          </div>
        </li>
      </ul>
    </div>
  );

  console.log(onApplicationResponse);

  const proceedToDraftConfirmation = (data) => {
    if (data) {
      setFirstPhase(false);
      setThirdPhase(false);
      setSecondPhase(true);
      createApplication();
    }
  };

  useEffect(() => {
    if (onApplicationResponse.id && !firstPhase) { // cannot jump from first to third
      setFirstPhase(false);
      setThirdPhase(false);
      setDraftConfirmation(true);
      setSecondPhase(true);
    }
  }, [onApplicationResponse]);

  const createApplication = (data) => {
    setLoad(true);
    const formData = new FormData();
    formData.append("draft", sop[0]);
    formData.append("applicationId", applicationID);
    formData.append("cv", cv[0]);
    formData.append("userId", session.user.id);

    dispatch(
      createApplicationThunkCreator(
        setLoad,
        setShowCreateError,
        setCreateError,
        formData
      )
    );
  };

  const resetWizard = () => {
    console.log("I am called here to cancel all");
    setFirstPhase(true);
    setDraftConfirmation(false);
    setSecondPhase(false);
    setThirdPhase(false);
  };

  const loadPaymentSdk = () => {
    setLoadPayment(true);
    axios
      .post(
        `${config.baseUrl}/api/v1/payment/create-payment-intent/${makePaymentApplication?.id || onApplicationResponse?.id}`
      )
      .then((res) => {
        setLoading(false);
        if (res.status === 200 && res?.data?.result) {
          setClientSecret(res.data.result.clientSecret);
          setCheckoutFailed(false);
          setCheckOutOpen(true);
          setLoadPayment(false);
          setOpen(false);
        } else {
          console.log(res);
          setCheckoutFailed(true);
        }
      })
      .catch((e) => {
        setLoadPayment(false);
        setLoading(false);
        console.log(e);
        setCheckoutFailed(true);
      });
  };

  const proceedToPayment = (e) => {
    e.preventDefault();
    loadPaymentSdk();
  };

  const resetForms = () => {
    reset({
      check1: null,
    });
  };
  const showPaymentCheckout = () => {
    setThirdPhase(true);
    setSecondPhase(false);
    setFirstPhase(false);
    setDraftConfirmation(false);
  };
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    return () => { };
  }, [thirdPhase, secondPhase, firstPhase]);

  const closeOpenModal = () => {
    setOpenModaInfo(false);
  };

  return (
    <DashboardContainer>
      {load && (
        <MyComponent
          loaderType="cube-transition"
          height="100vh"
          sentences={["Please wait..."]}
          wrapperBackgroundColor="rgba(0,0,0,0.5)"
        />
      )}
      <div>
        <div className="top-pad">
          {!pageNul && (
            <div>
              <div className="text-center lead-p">Request a review</div>
            </div>
          )}
          <div className="align-items-center">
            <div className="container-review">
              <div className="">
                <div className="text-center d-flex justify-content-center align-items-center">
                  {firstPhase && <ActiveOne />}
                  {secondPhase && <ActiveTwo />}
                  {thirdPhase && <ActiveThree />}
                </div>
                <br />

                <br />
                <form>
                  {firstPhase && (
                    <div>
                      <div class="">
                        <label className="">
                          <span>
                            {" "}
                            Select your intended broad field of study.
                          </span>
                        </label>

                        <select
                          style={style}
                          class="mt-4 form-select input-profile"
                          {...register("fieldOfStudy", {
                            required: "Field of study is required",
                          })}
                        >
                          <option selected value={""}>
                            Select
                          </option>
                          <option value="Arts and Humanities">
                            Arts and Humanities
                          </option>
                          <option value="Business Administration and Management">
                            Business Administration and Management
                          </option>
                          <option value="Engineering/Applied Sciences">
                            Engineering/Applied Sciences
                          </option>
                          <option value="Health and Medical Sciences">
                            Health and Medical Sciences
                          </option>
                          <option value="Pure Sciences">Pure Sciences</option>
                          <option value="Social Sciences">
                            Social Sciences
                          </option>
                        </select>
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors?.fieldOfStudy?.message}
                        </span>
                      </div>
                      <br />
                      <div class="">
                        <label className="">
                          <span>
                            {" "}
                            Enter your intended specific field of study.
                          </span>
                        </label>
                        <InputBox
                          label=""
                          require
                          name="intendedFieldofStudy"
                          type="text"
                          {...register("intendedFieldofStudy", {
                            required: "Intended Field of study is required",
                          })}
                          error={errors?.intendedFieldofStudy?.message}
                        />
                      </div>
                    </div>
                  )}
                  {secondPhase && (
                    <div>
                      {draftConfirmation ? (
                        <ConfirmDraft
                          gotoPayment={showPaymentCheckout}
                          application={validateRequestApplication?.id ? validateRequestApplication : onApplicationResponse}
                          setPageNull={setPageNull}
                          setDraftConfirmation={setDraftConfirmation}
                          resetWizard={resetWizard}
                          setSecondPhase={setSecondPhase}
                          resetForms={resetForms}
                          setFirstPhase={setFirstPhase}
                          setLoad={setLoad}
                        />
                      ) : (
                        <div>
                          <div></div>

                          <div className="flex-file-uploads">
                            <div>
                              <div className="d-flex justify-content align-items-center">
                                <label
                                  className=""
                                  onClick={() => {
                                    setOpenModaInfo(true);
                                  }}
                                >
                                  <span
                                    className="w-100"
                                    style={{ fontWeight: "700" }}
                                  >
                                    {" "}
                                    Upload a pdf version of your{" "}
                                    <span className="clickable-hov">
                                      {" "}
                                      draft.{" "}
                                      <sup>
                                        <ExclamationCircleFilled
                                          style={{ color: "#382d8b" }}
                                        />
                                      </sup>
                                    </span>
                                  </span>
                                </label>
                              </div>
                              <InputBox
                                label=""
                                require
                                height
                                title=" "
                                name="sop"
                                type="file"
                                {...register("sop", {
                                  required: "Sop file upload is required",
                                })}
                                error={errors?.sop?.message}
                              />
                            </div>

                            <br />
                            <div class="file-margin">
                              <label className="top-label-weight">
                                <span style={{ fontWeight: "700" }}>
                                  Upload your CV (optional).
                                  <span className="clickable- " style={{ color: 'white' }}>
                                    {" "}
                                    draft.{" "}

                                  </span>
                                </span>
                              </label>

                              <InputBox
                                label=""
                                height
                                require
                                title=" "
                                name="cv"
                                type="file"
                                {...register("cv", {})}
                                error={errors?.cv?.message}
                              />
                            </div>
                          </div>

                          <br />

                          <div class="">
                            <br />

                            <div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  {...register("check1", {
                                    required: "You must agree to this term",
                                  })}
                                  value=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  class="form-check-label privacy-policy-text"
                                  for="flexCheckDefault"
                                  style={{
                                    fontWeight: "300",
                                    fontSize: "13px",
                                    fontStyle: "italic",
                                  }}
                                >
                                  I declare that the ideas in this Statement of
                                  Purpose (SOP) are mine and have not been
                                  copied wholly or partly from another work. All
                                  ideas that are not mine have been fully
                                  referenced. I acknowledge that Edyter is not
                                  responsible for making plagiarism checks on my
                                  behalf and that I am solely responsible for
                                  the contents of the work that I am submitting.
                                </label>
                              </div>
                              <span className="check-error">
                                {errors?.check1?.message}
                              </span>
                              <br />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {thirdPhase && (application.applicationStatus !== 'RECEIVED' ?
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className="section-title">
                          <h4 className="mb-20">No payment required at this time</h4>
                        </div>
                        <div className="error-btn"><Link to='/orders' className="edu-btn">Back to orders page</Link></div>
                    </div> :
                    <div>
                      <div className="review-summary-box text-center">
                        <div>
                          <div className="review-summary-lead">
                            Cost summary
                          </div>
                          <hr />
                          <br />
                          <div className="review-summary-top">
                            <div className="summary-flex">
                              <div className="top-title-summary">
                                Order description
                              </div>
                              <div className="title-summary-val">
                                {" "}
                                Document Editing (Edyter)
                              </div>
                            </div>
                            <br />
                            <div className="summary-flex">
                              <div className="top-title-summary">
                                Order reference
                              </div>
                              <div className="title-summary-val">
                                {makePaymentApplication?.id || onApplicationResponse?.id}
                              </div>
                            </div>
                            <br />
                            <div className="summary-flex">
                              <div className="top-title-summary">
                                Order quantity
                              </div>
                              <div className="title-summary-val">1</div>
                            </div>
                            <hr />
                            <div className="review-summary-bottom">
                              <div className="summary-bottom-flex">
                                <div className="bottom-title-summary">
                                  Price
                                </div>
                                <div className="bottom-value">$5.99</div>
                              </div>
                              <br />
                              <div className="summary-bottom-flex">
                                <div className="bottom-title-summary">Tax</div>
                                <div className="bottom-value">$0</div>
                              </div>
                              <br />
                              <div className="summary-bottom-flex">
                                <div className="bottom-title-summary">
                                  Total
                                </div>
                                <div className="bottom-value">$5.99</div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <br />
                          <div className="d-flex justify-content-center align-items-center">
                            <Button
                              onClick={proceedToPayment}
                              text="Continue to payment"
                              loading={loadPayment}
                              disabled={loadPayment}
                              className="purple pay-type-b"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <br />
                  <div class="">
                    {firstPhase && (
                      <div className="d-flex justify-content-between align-items-center">
                        <Button
                          text="Back"
                          className="purple w-25"
                          onClick={() => {
                            history.push("/orders");
                          }}
                        />
                        <Button
                          text="Next"
                          className="purple w-25"
                          onClick={handleSubmit(proceedToSecond)}
                        />
                      </div>
                    )}
                  </div>
                  {secondPhase && !draftConfirmation && (
                    <div className="d-flex justify-content-between align-items-center">
                      <Button
                        text="Previous"
                        className="purple w-25"
                        onClick={() => {
                          setFirstPhase(true);
                          setThirdPhase(false);
                          setSecondPhase(false);
                        }}
                      />
                      <Button
                        text="Next"
                        loading={load}
                        disabled={load}
                        className="purple w-25"
                        onClick={handleSubmit(proceedToDraftConfirmation)}
                      />
                    </div>
                  )}
                  <br />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={false}
        visible={openModaInfo}
        footer={false}
        centered={true}
        width={700}
        onCancel={closeOpenModal}
      >
        <div
          onClick={closeOpenModal}
          className="d-flex justify-content-end align-items-center cursor"
        >
          <img src={closeBtn} width="15" />
        </div>
        <form>
          <div className="d-flex justify-content-life align-items-center">
            <div className="">
              <div
                className="text-left col-lead-sample h2"
                style={{ fontSize: "18px" }}
              >
                Generally, for a statement of purpose it is useful to consider:
              </div>
              <br />

              <div>
                <div>
                  <div className="mb-3 row">
                    <div className="col-md-12 col-12">
                      <div>
                        <div className="d-flex align-items-center">
                          <div> </div>
                          <div>
                            {"  "}
                            Why do you have an interest in the program?
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="col-md-12 col-12">
                      <div>
                        <div className="d-flex align-items-top">
                          <div> </div>
                          <div>
                            {"   "}
                            What particular activities or efforts have you
                            undertaken that demonstrate initiative and
                            preparation for the program?{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="col-md-12 col-12">
                      <div>
                        <div className="d-flex align-items-top">
                          <div> </div>
                          <div>
                            {" "}
                            What particular activities or efforts have you
                            undertaken that demonstrate initiative and
                            preparation for the program?{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-top">
                    <div className="col-md-12 col-12">
                      <div>
                        <div className="d-flex align-items-center">
                          <div> </div>
                          <div>
                            {" "}
                            What specific objective do you hope to achieve from
                            the program?{" "}
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="col-md-12 col-12">
                      <div>
                        <div className="d-flex align-items-center">
                          <div> </div>
                          <div>
                            <br /> Include any other information that supports
                            your application. Such information might include
                            publications, reason for low GPA, changing fields,
                            particular professors in your field or special
                            topics of interest
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="d-flex justify-content-end align-items-center">
                    <a
                      href="https://www.youtube.com/watch?v=HVaNqVBw8VE"
                      target="_blank"
                      style={{ color: "#F8A401" }}
                    >
                      <p
                        className="primary-col font-weight-bold"
                        style={{ fontWeight: 700 }}
                      >
                        <u>Learn more</u>
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>

      <OrderCheckout
        open={checkOutOpen}
        loading={loading}
        clientSecret={clientSecret}
        checkOutFailed={checkOutFailed}
        applicationId={onApplicationResponse?.id}
        setOpen={setCheckOutOpen}
      />
    </DashboardContainer>
  );
};

export default RequestReview;
