import React from "react";
import { Modal } from "antd";
import closeBtn from "../../../assets/img/close-btn-dark.svg";
import Logo from "../../../assets/img/Log.svg";

export default function PrivacyPolicy({
  openPrivacyPolicy,
  setOpenPrivacyPolicy,
}) {
  const closePrivacyPolicy = () => {
    setOpenPrivacyPolicy(false);
  };

  return (
    <Modal
      title={false}
      visible={openPrivacyPolicy}
      footer={false}
      width={"90%"}
      maskClosable={false}
      onCancel={closePrivacyPolicy}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div href="/" className="mD-auto">
          <img src={Logo} className="w-75" />
        </div>
        <div
          onClick={closePrivacyPolicy}
          className="d-flex cursor justify-content-end cursor align-items-center"
        >
          <img src={closeBtn} width="15" />
        </div>
      </div>

      <br />
      <h2 className="text-center pt-4">Privacy Policy</h2>
      <br />
      <div className="container">
        <p className="privacy-policy-text">
          At EDYTER, we care about the privacy of your data and are committed to
          protecting it. This Privacy Policy explains what information we
          collect about you and why. Kindly take your time to go through it to
          understand how your information gets processed. WHAT IS THE SCOPE OF
          THIS PRIVACY POLICY? This Privacy Policy is incorporated into EDYTER
          Terms of Service Agreement and applies to the information we obtain
          from you through your use of our site and services as outlined in this
          Policy. WHAT IS EDYTER BUSINESS MODEL? EDYTER offers editing services,
          personalized guidance and assistance to consumers of such services,
          the nature of which may be presented as a “Platform as a service
          (PaaS)”.
        </p>
        <div>
          <div className="privacy-header">INFORMATION COLLECTION AND USE:</div>
          <div className="privacy-policy-text">
            What Information does EDYTER collect about me?
            <br />
            When you interact with our site and/or sign up for our Services, we
            collect information that could be used to identify you. In addition,
            some of the information we collect may be stored in a manner that
            cannot be used to identify you personally.
            <br />
            <br />
            Information you provide us when you create an account when you sign
            up for and/or use our Services, you voluntarily give us certain
            Personal Data, including: <br /> Your username, email address,
            contact details, and language preferences. If you log into
            WWW.EDYTER.COM with a social networking credential, such as with
            your Facebook or Google account, we will ask permission to access
            basic information from that account, such as your name and email
            address. You can stop sharing that information with us at any time
            by removing our access to that account. <br /> We do not collect
            your payment information. Payment for our services will be conducted
            through a third-party payment process – Stripe, the Privacy Policy
            of Stripe will apply to the information that you provide on the
            Stripe website.
            <br />
            <br />
            Other Information we collect
            <br />
            When you use our site, we collect this additional information: User
            Content: This includes all the text, documents, or other content or
            information uploaded, entered, or otherwise transmitted by you in
            connection with your use of our Services. The use and protection of
            these information is guided by our Privacy Policy and Terms of
            Service. <br />
            <br /> Automatically collected Information
            <br /> Certain data about the device(s) that you use to connect to
            our Site and/or use our Services are automatically logged in our
            system. These data may include: Location information: This is the
            geographic area where you use your computer and/or mobile devices
            (as indicated by an Internet Protocol (IP) address or similar
            identifier) when interacting with our Site. <br />
            <br /> Log data: As with most websites and technology services
            delivered over the internet, our servers automatically collect data
            when you access or use our Site and record it in log files. This log
            data may include IP address, browser type and settings, date and
            time of use, information about browser configuration, language
            preferences, and cookie data. <br />
            <br />
            Usage information: <br /> This is information about the EDYTER Site
            and/or Services you use and how you use them. We may also obtain
            data from our third-party partners and service providers to analyze
            how users use our Site and/or Services. For example, we will know
            how many users access a specific page on the Site and which links
            they clicked on. We use this aggregated information to better
            understand and optimize the Site.
            <br />
            <br />
            Device information: This is data from your computer or mobile
            device, such as the type of hardware and software you are using (for
            example, your operating system and browser type), as well as unique
            device identifiers for devices that are using our services. Cookies:
            EDYTER Site also place and use cookies in your device when you visit
            our Site. We use these cookies to enable us to hold session
            information as you navigate from page to page within the Site,
            improve your experience, and track and analyze usage and other
            statistical information.
            <br />
            <br /> Information collected from other sources We may collect
            information about you from third parties, such as marketing partners
            and researchers, where they are legally allowed to share your
            Information with us. We may combine the Information we receive from
            other sources with Information we collect from you (or your device)
            and use it as described in this Privacy Policy. How do we use your
            Information? We use, process, and store your information as
            necessary to perform our contract with you and for our legitimate
            business interests, including: <br />
            <br /> To help us provide and administer our Site and/or Services,
            authenticate users for security purposes, provide personalized user
            features and access, process transactions, conduct research, develop
            new features, and improve the features, algorithms, and usability of
            our Site and/or Services. <br />
            <br /> To communicate with you about your use of our Site and/or
            Services, product announcements, and updates, as well as respond to
            your requests for assistance, including providing account
            verification support if you’re having difficulty accessing your
            account. o To send you direct marketing emails and special offers
            about EDYTER, from which you can unsubscribe at any time. If you are
            in the European Economic Area (EEA) or the UK, we will only send you
            marketing information if you consent to us doing so at the time you
            create your account or any point thereafter. <br /> <br />
            To display User Content associated with your account and make sure
            it is available to you when you use our Services. o To provide human
            proofreading services for users who submit User Content in
            connection with this specific service. <br />
            <br /> To calculate aggregate statistics on the number of unique
            devices using our Site and/or Services, and to detect and prevent
            fraud and/or misuse of those. <br />
          </div>
          <div>
            <div className="privacy-header">
              DOES EDYTER REVIEW USER CONTENT?
            </div>
            <div className="privacy-policy-text">
              EDYTER employees do not monitor your User Content stored in or
              transferred through our Site. Your User Content may, however, be
              viewed if we need to respond to a request for support or
              investigate the violation of our Terms of Service. Furthermore, if
              you request our editing or mentoring services, our mentors or
              reviewers will also read the User Content you submit for this
              specific service, as necessary to perform our contract with you
              and for our legitimate business interests. Finally, your
              Information may be viewed where necessary to protect the rights,
              property, or personal safety of EDYTER and its users, or to comply
              with our legal obligations, such as responding to warrants, court
              orders, or other legal processes.
            </div>
          </div>
          <div>
            <div className="privacy-header">
              INFORMATION ACCESS AND DISCLOSURE.{" "}
            </div>
            <div className="privacy-policy-text">
              Does EDYTER share my Information? <br /> We can disclose your
              personal data to third parties under certain circumstances. These
              may include:
              <br />
              <br />
              When we use service providers who assist us in meeting business
              operations needs, including hosting, delivering, and improving our
              Services. We also use service providers for specific services and
              functions, including email communication, customer support
              services, and analytics. These service providers may only access,
              process, or store Personal Data pursuant to our instructions and
              to perform their duties to us. <br />
              <br /> When we have your express consent to share your Personal
              Data. <br />
              <br />
              When it is necessary to investigate potential violations of the
              Terms of Service, to enforce those Terms of Service, or to
              investigate, prevent, or act regarding illegal activities,
              suspected fraud, or potential threats against persons, property,
              or the systems on which we operate our Site and/or Services.{" "}
              <br />
              <br /> When we determine that the access, preservation, or
              disclosure of your Personal Data is required by law to protect the
              rights, property, or personal safety of EDYTER and users of our
              Site and/or Services, or to respond to lawful requests by public
              authorities and law enforcement.
              <br />
              <br />
              When we need to do so in connection with a merger, acquisition,
              bankruptcy, reorganization, sale of some or all of our assets or
              stock, public offering of securities, or steps in consideration of
              such activities (e.g., due diligence). In these cases, some or all
              of your Personal Data may be shared with or transferred to another
              entity, subject to this Privacy Policy. In some cases, we may
              disclose some non-Personal Data publicly and to third parties.
              This information may be disclosed as part of reports about usage
              of our Services and progress reports that may be provided to
              partners. We will not share your Personal Data with third parties
              for the purposes of enabling them to deliver their advertisements
              to you.
              <br />
              <br />
              Does EDYTER sell or rent my Personal Data?
              <br />
              No, we do not sell or rent your Personal Data to anyone.
              <br />
            </div>
          </div>
          <div>
            <div className="privacy-header"> DOES EDYTER USE COOKIES? </div>
            <div className="privacy-policy-text">
              Cookies are small text files stored on your device and used by web
              browsers to deliver personalized content and remember logins and
              account settings. Our Site uses cookies and similar technologies,
              including tracking pixels and web beacons, to collect usage and
              analytic data that helps us provide our Site and/or Services to
              you, as well as to help deliver ads for relevant products and
              services to you when you visit certain pages on the Site and then
              visit certain third-party sites.
            </div>
          </div>
          <div>
            <div className="privacy-header">
              HOW DO THIRD-PARTY APPS AND PLUGINS WORK?
            </div>
            <div className="privacy-policy-text">
              Some third-party applications and services that work with us may
              ask for permission to access your Information. Those applications
              will provide you with notice and request your consent to obtain
              such access or information. Please consider your selection of such
              applications and services, and your permissions, carefully. Some
              third parties’ embedded content or plugins on our Site may allow
              their operators to learn that you have visited the Site, and they
              may combine this knowledge with other data they have collected
              about your visits to other websites or online services that can
              identify you. Data collected by third parties through these apps
              and plugins is subject to each parties’ own policies. We encourage
              you to read those policies and understand how other companies use
              your data.
            </div>
          </div>
          <div>
            <div className="privacy-header">Will EDYTER SEND ME emails?</div>
            <div className="privacy-policy-text">
              From time to time, we may want to contact you with information
              about product announcements, Services updates, and special offers.
              We also may want to contact you with information about products
              and services. You may opt out of such communications at any time
              by clicking the “unsubscribe” link found within our emails and
              changing your contact preferences. All our users will continue to
              receive transactional messages related to our Services, even if
              you unsubscribe from promotional emails. For EU and UK users: We
              only send marketing communications to you with your prior consent.
              <br />
              <br />
              Does EDYTER ever make any of my Personal Data or User Content
              public?
              <br /> No, EDYTER do not share Personal Data or User Content
              except in the limited circumstances described in various sections
              of this Policy.
            </div>
          </div>
          <div>
            <div className="privacy-header">
              Does EDYTER collect information from children?
            </div>
            <div className="privacy-policy-text">
              EDYTER does not knowingly collect personal information from
              children under the age of 16. If we determine we have collected
              personal information from a child younger than 16 years of age, we
              will take reasonable measures to remove that information from our
              systems. If you are under the age of 16, please do not submit any
              personal information through the Site. We encourage parents and
              legal guardians to monitor their children’s internet usage and to
              help enforce this Policy by instructing their children never to
              provide personal information through the Site without their
              permission. Please note that in some countries the age of digital
              consent is lower than 16. If you are in one of those countries,
              you must be at least of age to use the Site and/or Services.
            </div>
          </div>
          <div>
            <div className="privacy-header">
              DATA STORAGE, TRANSFER, RETENTION, AND DELETION
            </div>
            <div className="privacy-policy-text">
              Where is my Information stored? Information received by us will be
              transferred to, processed, and stored in the United States. When
              you access our Site and/or use our Services on your device, User
              Content you save will be stored locally on that device and synced
              with our servers. If you post or transfer any Information to or
              through our Site and/or Services, you are agreeing to such
              Information, including Personal Data and User Content, being
              hosted, and accessed in the United States.
            </div>
          </div>
          <div>
            <div className="privacy-header">HOW SECURE IS MY INFORMATION ?</div>
            <div className="privacy-policy-text">
              We are committed to protecting the security of your Information
              and we will take all reasonable precautions to protect it. Given
              that internet data transmissions cannot be totally and completely
              secure, we cannot ensure the security of Information you transmit
              to us. Thus, while transmitting your Personal Data and User
              Content, you acknowledge that you do so at your own risk. We use
              industry-standard encryption to protect your data in transit and
              in storage. Once we receive your data, we protect it on our
              servers using a combination of technical, physical, and logical
              security safeguards. We recommend that you take the appropriate
              steps to secure all computing devices that you use in connection
              with our Site and/or Services. If we learn of a security system
              breach, we may attempt to notify you and provide information on
              protective steps, if available, through the email address that you
              have provided to us or by posting a notice on the Site. Depending
              on your country or state (in the US) of residence, we may be under
              obligation to inform you of such breaches in writing.
            </div>
          </div>
          <div>
            <div className="privacy-header">
              DOES EDYTER COLLECTS INFORMATION FROM CHILDREN?
            </div>
            <div className="privacy-policy-text">
              EDYTER does not knowingly collect personal information from
              children under the age of 16. If we determine we have collected
              personal information from a child younger than 16 years of age, we
              will take reasonable measures to remove that information from our
              systems. If you are under the age of 16, please do not submit any
              personal information through the Site. We encourage parents and
              legal guardians to monitor their children’s internet usage and to
              help enforce this Policy by instructing their children never to
              provide personal information through the Site without their
              permission. Please note that in some countries the age of digital
              consent is lower than 16. If you are in one of those countries,
              you must be at least of age to use the Site and/or Services.
            </div>
          </div>
          <div>
            <div className="privacy-header">
              HOW LOGN IS PERSONAL DATA RETAINED ?
            </div>
            <div className="privacy-policy-text">
              As we described above, you can remove your Personal Data from our
              Site by sending us an email with such request. We may keep some of
              your Personal Data for as long as reasonably necessary for our
              legitimate business interests, which may include fraud detection
              and prevention, and to comply with such obligations as tax, legal
              reporting, and auditing obligations. What happens if EDYTER closes
              my account? We may close your account due to your violation of our
              Terms of Service. In such situation, you may contact us through
              email to request that we delete your Personal Data. We will
              evaluate such request and act accordingly, on a case by case
              basis.
            </div>
          </div>
          <div>
            <div className="privacy-header">
              WILL THIS PRIVACY POLICY EVER CHANGE?
            </div>
            <div className="privacy-policy-text">
              With time, and as EDYTER evolves, we may need to update this
              Policy to keep abreast with changes in our Site, Services,
              business, and laws of various regions that are applicable to us
              and you. If there are any material changes that will affect your
              rights under this Privacy Policy, we will notify you by email to
              your email address. We will also update this Site with any other
              revisions to this Policy, along with their effective date. We
              recommend that you periodically check back here to stay informed
              of any changes. Please note that your continued use of our Site
              after any changes means that you agree with and consent to be
              bound by the new Policy. If you disagree with any changes in our
              Privacy Policy and you do not wish for your Personal Data to be
              subject to it, you will need to delete your account.
            </div>
          </div>
          <div>
            <div className="privacy-header">UK & EU Users Privacy Policy</div>
            <div className="privacy-policy-text">
              EDYTER uses, processes, and stores Personal Data as necessary to
              perform our contract with you. We rely on your consent to process
              Personal Data to send promotional emails and to place cookies on
              your devices. Additionally, EDYTER may process Personal Data
              pursuant to legal obligation or to protect your vital interests or
              those of another person. When we transfer the personal data of EU
              & UK residents outside of those regions, we do so using
              appropriate safeguards of user privacy. What rights do I have, and
              how can I exercise them? In accordance with the Privacy laws of
              the EU & the UK, individuals located in the continent have certain
              rights in respect to their personal information, including the
              right to access, correct, or delete Personal Data we process
              through your use of the Site and/or Services. If you’re a user
              based in the EEA or the UK, you have the right to:
              <br />
              <br />
              Request a Personal Data report by submitting a ticket USING THE
              CONTACT US LINK ON OUR WEBSITE (WWW.EDYTER.COM). This report will
              include the Personal Data we have about you. You must be signed
              into your account to submit this request and we may request
              additional information from you to verify your identity before we
              disclose any information. <br />
              <br /> Have your Personal Data corrected or deleted. You can also
              update some Personal Data such as your name and email address, as
              well as language preference, through your account. You can also
              remove your Personal Data from our Site by deleting your account.{" "}
              <br />
              <br /> Object to EDYTER processing your Personal Data. You can ask
              us to stop using your Personal Data, including when we use your
              Personal Data to send you marketing emails. We only send marketing
              communications to users located in the EEA & the UK with your
              prior consent, and you may withdraw your consent at any time by
              clicking the “unsubscribe” link found within EDYTER emails and
              changing your contact preferences. Please note that you will
              continue to receive transactional messages related to our
              Services, even if you unsubscribe from marketing emails. <br />
              <br />
              Complain to a regulator. If you’re based in the EEA & the UK and
              you believe that EDYTER have not duly complied with the Data
              Protection laws of the EU & the UK, you have a right to lodge a
              complaint with your local supervisory authority. Who is EDYTER EU
              & UK Representative? MR. PASCHAL OVAGA has been appointed EDYTER
              representative in the EEA & the UK for data protection matters,
              pursuant to Article 27 of the General Data Protection Regulation
              of the European Union and the UK General Data Protection
              Regulation, respectively. The address of the EU Representative is
              Oude Molenweg 6, 6417 PW, Heerlen, Netherlands, and he can be
              reached on the phone at +31638895819.
            </div>
          </div>
          <div>
            <div className="privacy-header">
              YOUR EU DATA REPRESENTATIVE ADDRESS AND CONTACT INFORMATION. FOR
              CALIFORNIA USERS
            </div>
            <div className="privacy-policy-text">
              In addition to our Privacy Policy above, this part applies to
              California residents only. Summary of Information we Collect{" "}
              <br />
              <br />
              California law requires that we provide you with additional
              information about how we collect, use, and disclose your Personal
              Information under the California Consumer Privacy Act (CCPA).
              Under the CCPA, we are required to provide you with the categories
              of personal information we collect and disclose for business
              purposes. Those categories are: identifiers (such as name, email
              address, or social network account and profile data); commercial
              information (such as transaction data for a paid account);
              financial data (such as payment method or financial account
              information); internet or other network or device activity (such
              as IP address and usage information); location information (e.g.,
              the general location where you use your device when interacting
              with the Site, and/or Services); sensory information (such as
              audio recordings if you call our customer service); inference data
              about you; and other information that identifies or can be
              reasonably associated with you. EDYTER and our service providers
              may use the categories of personal information we collect about
              you consistent with the various business and commercial purposes
              we outlined under this Policy. We may also use the categories of
              personal information for compliance with applicable laws and
              regulations, and we may aggregate the information we collect or
              de-identify the information to limit or prevent identification of
              a particular user.
              <br />
              <br />
              What are your Rights as a California resident? California law
              gives you certain rights, which may permit you to request that we:
              <br />
              <br /> Provide access to and/or a copy of certain information we
              hold about you. <br />
              <br />
              Provide you a summary of the categories of personal information we
              have collected or disclosed about you in the last twelve months;
              the categories of sources of such information; the business or
              commercial purpose for collecting or selling your personal
              information; and the categories of third parties with whom we
              shared personal information. <br />
              <br /> Delete certain information we have about you. You may have
              the right to receive information about the financial incentives
              that we offer to you, if any. You also have the right not to be
              discriminated against for exercising your rights. Certain
              information may be exempt from such requests under applicable law.
              For example, we need certain types of information so that we can
              provide the Site and/or Services to you. If you ask us to delete
              it, you may no longer be able to access or use the Site and/or
              Services. These rights can be exercised by: <br />
              <br /> Requesting a personal data report or a Summary of such by
              submitting a ticket USING THE CONTACT US LINK ON OUR WEBSITE
              (WWW.EDYTER.COM). <br />
              <br /> Deleting your personal information from our Site by
              deleting your account. You will need to provide us with
              information sufficient to verify your identity before we can
              satisfy your request. To do so, you will need to log into your
              account or provide us with certain information regarding yourself
              and/or your usage of the Site and/or Services. You can also
              designate an authorized agent to make a request on your behalf. To
              do so, you need to provide us with written authorization for the
              agent to act on your behalf; however, you will still be required
              to verify your identity directly with us.
              <br />
              <br /> The CCPA sets forth certain obligations for businesses that
              sell personal information. Based on the definition of sell under
              the CCPA and under current regulatory guidance, we don’t engage in
              such activity. We may share certain information as described in
              this Policy, and we allow third parties to collect certain
              information about your activity, for example through cookies. You
              can control these cookies through browser settings and other
              controls. California Shine the Light law As a California resident,
              you can ask for a list of third parties that have received your
              information for their direct marketing purposes during the
              previous calendar year. This list also contains the types of
              information shared. We provide this list freely. To make a
              request, contact us at WWW.EDYTER.COM.
            </div>
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    </Modal>
  );
}
