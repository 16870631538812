export const faqData = {
  title: "",
  rows: [
    {
      title: "Why should I use Edyter?",
      content:
        "Over the years we have individually assisted hundreds of students to clearly articulate their purpose to selection committees. It is our passion! We built Edyter based on this extensive experience.",
    },
    {
      title: "What am I expecting to get back after the editing?",
      content:
        "You will receive two documents. One is a clean copy of the edited version of your draft. The second contains your initial draft along with the edits and changes that we have made. That is, the second document highlights changes.",
    },
    {
      title: "Can you make any guarantees?",
      content:
        "Yes. Your work will be timely and of excellent quality. In any case that you are not statisfied with the edited version, we are glad to rework the draft. Our goal is to ensure that you leave with a smile!",
    },
    {
      title: "What kind of documents do you edit?",
      content:
        "We edit all application related essays: statements of purpose, motivation letters, personal statements, scholarship essays and diversity statements, etc.",
    },
    {
      title: "How fast is your service?",
      content:
        "Very fast. The max time is 3 hours. Most requests will be processed under that.",
    },
    {
      title: "Do you also write application essays for applicants?",
      content:
        "No. We perfectly edit your statement to ensure your story shines through. While doing this, we make sure to preserve your story, which uniquely captures your motivation.",
    },
    {
      title: "Do I have to come with a draft?",
      content:
        "Yes, but your draft does not have to be perfect. It is our job to do so.",
    },
    {
      title: "How does it work?",
      content:
        "Simple. Sign up and log in, request a review, and upload your draft. We send an email after your request is processed.",
    },
    {
      title: "Can I use my card on your platform?",
      content:
        "Our platform is safe for all purposes. Moreover, the payment is handled by Stripe, so we do not store or make use of your details.",
    },
    {
      title:
        "Do you have resources for writing a winning statement of purpose?",
      content:
        "Yes. We have embedded an excellent video on our landing page to help guide you in preparing your statement. You can also visit the “resources” section on the footer.",
    },

    {
      title: "Will I be able to see the changes on my draft?",
      content:
        "Yes. An advantage of our approach is that you are also able to see the changes that we have made. That gives you the flexibility to either accept or deny specific changes if you wish.",
    },



    {
      title: "Can I see some samples?",
      content: "Yes, on our home page, you can download some samples.",
    },
    {
      title: "I have more questions; how can I get in touch?",
      content:
        "We welcome any opportunity to have a chat. Please use the “contact us” or the email shown on our home page to send us your question.",
    },
    {
      title: "What do you think makes a statement of purpose great?",
      content:
        "A winning statement of purpose should be a coherent statement that depicts thoughtfulness, preparation, initiative and a clear purpose. It captures these elements and highlights you as a strong fit for the program.",
    },
  ],
};
