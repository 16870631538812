import React, { useEffect, useState } from "react";
import Footer from "../../components/Layout/Footer/Footer";
import Header from "../../components/Layout/Header";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useParams, useHistory, Link } from "react-router-dom";
import getApplicationThunkCreator from "../../redux-thunk/getApplicationThunkCreator";

export default function ReviewPriceSummary() {
    const auth = useSelector(state => state.auth)
    const application = useSelector(state => state.application.object)
    const { applicationId } = useParams()
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        if (loading) {
            dispatch(getApplicationThunkCreator(applicationId, setLoading))
        }
    }, [])

    if (!auth.authenticated) {
        return <Redirect to="/" />
    }


    return (
        <>
            <Header auth={auth} />
            <main>
                <div className="container-padding top-margin  mobile-space-reviews" style={{ height: '100vh', display: 'flex', justifyContent: 'center' }}>
                    {loading ? <div className="d-flex flex-column align-items-center m-auto w-75 justify-content-center min-vh-100">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div> :
                       // (application.id === applicationId && application.applicationStatus !== 'PAYMENT_REQUIRED' ?
                       (application.id === applicationId && application.applicationStatus !== 'RECEIVED' ?
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="section-title">
                                    <h4 className="mb-20">No payment required at this time</h4>
                                </div>
                                <div className="error-btn"><Link to='/orders' className="edu-btn">Back to orders page</Link></div>
                            </div> :
                            <div className="d-flex flex-column">
                                <div>
                                    <div className="section-title">
                                        <h3 className="mb-40 mt-20">Order Summary Test</h3>
                                    </div>
                                    <div>
                                        <p><strong>Order:</strong> Review Request</p>
                                        <p><strong>Order Reference:</strong>{` ${applicationId}`}</p>
                                        <p><strong>Quantity:</strong> 1</p>
                                        <p><strong>Price:</strong> US$ 2</p>
                                        <p><strong>Total:</strong> US$ 2</p>
                                    </div>
                                </div>
                                <div className="cont-btn mt-60 mb-20" onClick={() => {
                                    history.push(`/orders/checkout/${applicationId}`)
                                }}>
                                    <div className="cont-btn">Make Payment</div>
                                </div>
                            </div>)
                    }
                </div>
            </main>
            <Footer />
        </>
    );
}
