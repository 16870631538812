import { createSlice } from "@reduxjs/toolkit";

export const applicantSlice = createSlice({
    name: 'applicant',
    initialState: {
        profile: {},
        applications: []
    },
    reducers: {
        addApplicant: (state, action) => {
            console.log('profile data', action.payload)
            state.profile = action.payload;
        },
        clearApplicant: (state) => {
            state.profile = {};
            state.applications = []
        },
        addApplications: (state, action) => {
            state.applications = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { addApplicant, clearApplicant, addApplications } = applicantSlice.actions;

export default applicantSlice.reducer;