import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import loginThunkCreator from "../../../redux-thunk/loginThunkCreator";
import OTPInput from "otp-input-react";
import axios from "axios";
import { Input } from "../../Input/index";
import "./auth.css";
import Logo from "../../../assets/img/Log.svg";
import { Link, useHistory } from "react-router-dom";
import config from "../../../config";
import closeBtn from "../../../assets/img/close-btn-dark.svg";
import eyeShow from "../../../assets/img/eye-show.svg";
import eyeOff from "../../../assets/img/eye-off.svg";
import { authSuccess } from "../../../redux/features/authSlice";
import { setAuthToken } from "../../../serviceApis/authentication";
import { PersistSession } from "../../../redux/SessionStorageUtils";
import ResetPassword from "./ResetPassword";
import { EmailValidator } from "commons-validator-js";
import closeX from "../../../assets/img/closeX.svg";
import MyComponent from "react-fullpage-custom-loader";

const SignIn = ({ setSignInOpen, signinOpen, setSignUpOpen, auth }) => {
  const [pass, setPass] = useState("");
  const [email, setEmail] = useState("");
  const [showAuthError, setShowAuthError] = useState(false);
  const style = {
    height: "50px",
    background: "#FFFFFF !important",
    width: "100%",
    border: "0.899471px solid #B7B7B7",
    borderRadius: "0.960832px",
    outlineColor: "#B7B7B7",
    outline: "1px solid #B7B7B7",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
  };
  const [
    openActivationConfirmationErrorModal,
    setOpenActivationConfirmationErrorModal,
  ] = useState(false);
  const history = useHistory();
  const [openActivationModal, setOpenActivationModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [openActivationConfirmationModal, setOpenActivationConfirmationModal] =
    useState(false);
  const [activationErrorMsg, setActivationErrorMsg] = useState("");
  const [showActivationError, setShowActivationError] = useState(false);
  const [pendingUserEmail, setPendingUserEmail] = useState("");
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [loadActivation, setLoadActivation] = useState(false);

  const [load, setLoad] = useState(false);
  const [loadResend, setLoadResend] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const dispatch = useDispatch();
  const validator = new EmailValidator();
  const [eye, seteye] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const closeActivationConfirmationModal = () => {
    setOpenActivationConfirmationModal(false);
  };

  const onLogin = () => {
    if (!validator.isValid(email)) {
      setEmailInvalid(true);
      return;
    }
    const data = {
      email: email,
      password: pass,
      roleName: "APPLICANT",
    };
    dispatch(
      loginThunkCreator(
        setLoad,
        setShowAuthError,
        closeModal,
        data,
        setOpenActivationModal
      )
    );
  };

  const closeModal = (isPendingUser) => {
    if (isPendingUser) {
      setPendingUserEmail(email);
    } else {
      setPendingUserEmail("");
    }
    setEmail("");
    setPass("");
    reset({ email: "", pass: "" });
    if (showAuthError) {
      setShowAuthError(false);
    }
    setSignInOpen(false);
  };

  const closeActivationCodeModal = () => {
    if (showActivationError) {
      setShowActivationError(false);
    }
    setOtp("");
    setPendingUserEmail("");
    setOpenActivationModal(false);
  };

  const handleChange = (otp) => {
    if (showActivationError) {
      setShowActivationError(false);
    }
    setOtp(otp);
  };

  const activateUser = async () => {
    setLoadActivation(true);
    try {
      const res = await axios.post(
        `${config.baseUrl}/api/v1/auth/activateUser/email/${pendingUserEmail}/${otp}`
      );
      setLoadActivation(false);
      if (res?.status === 200 && res.data.result) {
        dispatch(authSuccess(res.data.result));
        setOpenActivationConfirmationModal(true);
        setOpenActivationModal(false);
        setAuthToken(res.data.result.accessToken);
        PersistSession({
          session: res.data.result,
          authenticated: true,
          errorMsg: null,
        });
        closeActivationCodeModal();
      } else {
        console.log(res);
        setActivationErrorMsg(res.data.message);
        setShowActivationError(true);
      }
    } catch (e) {
      setLoadActivation(false);
      setOpenActivationConfirmationErrorModal(true);
      console.log(e);
      if (e?.response?.status === 400) {
        if (isJson(e?.response?.data?.message)) {
          const msgObj = JSON.parse(e?.response?.data?.message);
          setActivationErrorMsg(
            `Invalid code. Number of attempts left: ${msgObj.noOfTrials}`
          );
        } else if (
          e?.response?.data?.message ===
          "You have exceeded activation token trial time. A new token has been sent your email address."
        ) {
          setActivationErrorMsg(
            "Maximum number of attempts exceeded. A new code has been sent to your email"
          );
        } else {
          setActivationErrorMsg("Something went wrong. Try again");
        }
      } else {
        setActivationErrorMsg("Something went wrong. Try again");
      }
      setShowActivationError(true);
    }
  };

  const closeActivationConfirmationErrorModal = () => {
    setOpenActivationConfirmationErrorModal(false);
  };

  const resendCode = async () => {
    if (showActivationError) {
      setShowActivationError(false);
    }
    setLoadResend(true);
    try {
      const res = await axios.get(
        `${config.baseUrl}/api/v1/auth/resendToken/email/${pendingUserEmail}`
      );
      setLoadResend(false);
      if (res?.status !== 200) {
        console.log(res);
      } else {
        setOpenActivationModal(true);
        setOpenActivationConfirmationErrorModal(false);
      }
    } catch (e) {
      setLoadResend(false);
      console.log(e);
    }
  };

  return (
    <>
      <Modal
        title={false}
        visible={signinOpen}
        footer={false}
        width={"100%"}
        maskClosable={false}
        onCancel={closeModal}
        wrapClassName="example"
        style={{ marginTop: "1rem" }}
        bodyStyle={{ height: "100%" }}
      >
        <div className="d-flex justify-content-end align-items-center">
          {/* <div href="/" className="mD-auto">
            <img src={Logo} className="w-75" />
          </div> */}
          <div
            onClick={closeModal}
            className="d-flex cursor justify-content-end cursor align-items-center"
          >
            <img src={closeBtn} width="15" />
          </div>
        </div>

        <div className=" pt-30">
          <br />
          <form>
            <div className="signup-form-wrapper-new">
              <div className="form-inputs">
                <div className="text-center col-lead">Sign in</div>
                <div className="mb-4">
                  <Input
                    label=""
                    require
                    placeholder="Email"
                    name="email"
                    type="email"
                    {...register("email", {
                      required: "Email is required",
                      onChange: (e) => {
                        if (showAuthError) {
                          setShowAuthError(false);
                        }
                        if (emailInvalid) {
                          setEmailInvalid(false);
                        }
                        setEmail(e.target.value);
                      },
                    })}
                  />
                </div>
                <span className="text-danger">{errors?.email?.message}</span>
                {emailInvalid && (
                  <span className="text-danger">
                    Enter a valid email address
                  </span>
                )}
                <div className="mb-4">
                  <div
                    class="d-flex w-100 justify-content-between align-items-center  mt-4"
                    style={style}
                  >
                    <div
                      className="d-flex w- h-100 justify-content"
                      style={{
                        width: "85%",
                      }}
                    >
                      <input
                        type={eye ? `text` : "password"}
                        placeholder="Password"
                        className="d-flex w-100 h-100 px-3"
                        {...register("pass", {
                          required: "Password is required",
                          onChange: (e) => {
                            if (showAuthError) {
                              setShowAuthError(false);
                            }
                            setPass(e.target.value);
                          },
                        })}
                        style={{
                          border: "0 !important",
                          outline: "0 !important",
                          outlineColor: "none !important",
                          outline: "none !important",
                        }}
                      />
                    </div>

                    <div
                      className="cursor d-flex h-100 justify-content-center align-items-center"
                      style={{
                        width: "15%",
                      }}
                      onClick={() => seteye(!eye)}
                    >
                      {eye ? (
                        <div>
                          <img src={eyeShow} width="20" />
                        </div>
                      ) : (
                        <div>
                          <img src={eyeOff} width="20" />
                        </div>
                      )}
                    </div>
                  </div>
                  <span className="text-danger">{errors?.pass?.message}</span>
                  {auth?.errorMsg && showAuthError && (
                    <div className="signup-action">
                      <span className="text-danger">{auth?.errorMsg}</span>
                    </div>
                  )}
                </div>
                <div className="forgot-password-b mb-4 text-left">
                  <div></div>
                  <div
                    className="primary-col link-hover-ed"
                    onClick={() => {
                      setOpenEmailModal(true);
                      setSignInOpen(false);
                    }}
                  >
                    Forgot password?
                  </div>
                </div>

                <div
                  className={`${load ? 'is-disabled' : ''} sing-buttom mb-20`}
                  onClick={handleSubmit(onLogin)}
                >
                  <button type="submit" className="sing-btn" >
                    {load ? "LOADING..." : "Sign in"}
                  </button>
                </div>
                <div className="registered wrapper">
                  <div className="not-register">
                    <br />
                    <div className="bold-num text-center">
                      Don't have an account?{" "}
                      <span className="bold-num">
                        <a
                          className="link-hover-ed text-no-decoration"
                          href="#!"
                          style={{ textDecoration: "none" }}
                          onClick={() => {
                            closeModal();
                            setSignUpOpen(true);
                          }}
                        >
                          Sign up
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <br />
          <br />
          <br />
        </div>
      </Modal>

      <Modal
        title={false}
        visible={openActivationConfirmationModal}
        footer={false}
        width={"100%"}
        onCancel={closeActivationConfirmationModal}
        wrapClassName="example"
        style={{ marginTop: "1rem" }}
        bodyStyle={{ height: "100%" }}
        maskClosable={false}
      >
        <div
          onClick={closeActivationConfirmationModal}
          className="d-flex cursor justify-content-end align-items-center"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div className="pt-50">
          <div className="signup-form-wrapper-new">
            <div className="form-inputs">
              <div className="forgot-password-bord" style={{ padding: "20px" }}>
                {" "}
                <div className="d-flex justify-content-center align-items-center h-100">
                  <div>
                    <div className="text-center d-flex justify-content-center align-items-center">
                      <img src={closeX} width="50" />
                    </div>
                    <br />
                    <div
                      className="text-center col-lead"
                      style={{ color: "#382D8B" }}
                    >
                      Verified!
                    </div>
                    <div>You have successfully verified your Account.</div>
                    <br />
                    <div
                      className="sing-buttom  mb-20"
                      onClick={() => {
                        history.push("/dashboard");
                      }}
                    >
                      <button type="submit" className="sing-btn">
                        Continue
                      </button>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={false}
        visible={openActivationConfirmationErrorModal}
        footer={false}
        width={"100%"}
        onCancel={closeActivationConfirmationErrorModal}
        wrapClassName="example"
        style={{ marginTop: "1rem" }}
        bodyStyle={{ height: "100%" }}
        maskClosable={false}
      >
        <div
          onClick={closeActivationConfirmationErrorModal}
          className="d-flex justify-content-end cursor align-items-center"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div className="pt-50">
          <div className="signup-form-wrapper-new">
            <div className="form-inputs">
              <div className="forgot-password-bord" style={{ padding: "20px" }}>
                {" "}
                <div className="d-flex justify-content-center align-items-center h-100">
                  <div>
                    <div className="text-center d-flex justify-content-center align-items-center">
                      <img src={closeX} width="50" />
                    </div>
                    <br />
                    <div
                      className="text-center col-lead"
                      style={{ color: "red" }}
                    >
                      Error!
                    </div>
                    <div>
                      Looks like the verification code has expired. Use the
                      button to request for another code.
                    </div>
                    <br />
                    <div
                      className="sing-buttom mb-20  link-hover-ed cursor"
                      onClick={resendCode}
                    >
                      <button type="submit" className="sing-btn">
                        Resend verification code
                      </button>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={false}
        visible={openActivationModal}
        footer={false}
        onCancel={closeActivationCodeModal}
        width={"100%"}
        wrapClassName="example"
        style={{ marginTop: "1rem" }}
        bodyStyle={{ height: "100%" }}
        maskClosable={false}
      >
        <div
          onClick={closeActivationCodeModal}
          className="d-flex justify-content-end cursor align-items-center"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div className="signup-form-wrapper-new">
          <div className="form-inputs ">
            <div className="forgot-password-bord">
              <div
                className="text-center col-lead mt-4"
                style={{ color: "#382D8B" }}
              >
                Enter code
              </div>
              <div className="text-center">
                Please enter the code we just sent to you.
              </div>
              <div
                className="d-flex justify-content-center align-items-center h-100 pt-4 pb-4"
                style={{ marginLeft: ".8rem" }}
              >
                <OTPInput
                  value={otp}
                  onChange={handleChange}
                  OTPLength={6}
                  separator={<span></span>}
                  inputStyles={{
                    width: "70%",
                    height: "3.5rem",
                    borderRadius: "5px",
                    fontSize: "16px",
                    color: "#382D8B",
                    fontWeight: "400",
                    fontSize: "29px",
                    outlineColor: "1px solid #B7B7B7",
                    outline: "1px solid #B7B7B7",
                    lineHeight: "107.5%",
                    color: "#382D8B",
                    border: "1px solid #B7B7B7",
                  }}
                />
              </div>
              {activationErrorMsg && showActivationError && (
                <div className="signup-action">
                  <span className="text-danger">{activationErrorMsg}</span>
                </div>
              )}
              <div className="d-flex flex-column">
                <div className="sing-buttom mb-20" onClick={activateUser}>
                  <button type="submit" className="sing-btn">
                    {loadActivation ? "Submitting..." : "Verify"}
                  </button>
                </div>
                <div
                  onClick={resendCode}
                  className="text-center link-hover-ed cursor bold-num primary-col mb-3  d-flex justify-content-center align-items-center"
                >
                  {" "}
                  {loadResend ? "Sending..." : "Resend code"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ResetPassword
        openEmailModal={openEmailModal}
        setOpenEmailModal={setOpenEmailModal}
        setSignInOpen={setSignInOpen}
      />
    </>
  );
};

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export default SignIn;
