import { useState, useEffect, useMemo } from "react";

export const ActiveOne = () => {
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="system-flex">
          <div>
            <div className="circle-arrow-req">
              <div className="d-flex justify-content-center align-items-center h-100">
                <span className="circle-inner-req">1</span>
              </div>
            </div>
          </div>
          <div class="arrow-req">
            <div class="line-req"></div>
            <div class="point-req"></div>
          </div>{" "}
          <div className="circle-arrow-inactive">
            <div className="d-flex justify-content-center align-items-center h-100">
              <span className="circle-inner-inactive">2</span>
            </div>
          </div>
          <div class="arrow-req">
            <div class="line-req"></div>
            <div class="point-req"></div>
          </div>{" "}
          <div className="circle-arrow-inactive">
            <div className="d-flex justify-content-center align-items-center h-100">
              <span className="circle-inner-inactive">3</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveOne;
