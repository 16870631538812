import React, { useState } from "react";
import axios from "axios";
import config from "../../../config";
import { useForm } from "react-hook-form";
import { Modal } from "antd";
import OTPInput from "otp-input-react";
import { Link } from "react-router-dom";
import eyeShow from "../../../assets/img/eye-show.svg";
import eyeOff from "../../../assets/img/eye-off.svg";
import { useHistory } from "react-router-dom";
import { success, error } from "../../../components/Alerts/Index";
import { useDispatch } from "react-redux";
import { authSuccess } from "../../../redux/features/authSlice";
import { setAuthToken } from "../../../serviceApis/authentication";
import closeX from "../../../assets/img/closeX.svg";
import { PersistSession } from "../../../redux/SessionStorageUtils";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditions from "./TermsConditions";
import { EmailValidator } from "commons-validator-js";
import closeBtn from "../../../assets/img/close-btn-dark.svg";
import { Input } from "../../Input/index";
import Logo from "../../../assets/img/Log.svg";
import "./auth.css";

const SignUp = ({ setSignUpOpen, signupOpen, setSignInOpen }) => {
  const dispatch = useDispatch();
  const style = {
    height: "50px",
    background: "#FFFFFF !important",
    width: "100%",
    border: "0.899471px solid #B7B7B7",
    borderRadius: "0.960832px",
    outlineColor: "#B7B7B7",
    outline: "1px solid #B7B7B7",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
  };
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [terms, setOpenTerms] = useState(false);
  const history = useHistory();
  const [cpass, setCPass] = useState("");
  const [
    openActivationConfirmationErrorModal,
    setOpenActivationConfirmationErrorModal,
  ] = useState(false);
  const [openActivationModal, setOpenActivationModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [userId, setUserId] = useState("");
  const [load, setLoad] = useState("");
  const [signUpErrorMsg, setSignUpErrorMsg] = useState("");
  const [showSignUpError, setShowSignUpError] = useState(false);
  const [activationErrorMsg, setActivationErrorMsg] = useState("");
  const [showActivationError, setShowActivationError] = useState(false);
  const [loadResend, setLoadResend] = useState(false);
  const [eye, seteye] = useState(false);
  const [eye2, seteye2] = useState(false);
  const [openActivationConfirmationModal, setOpenActivationConfirmationModal] =
    useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const validator = new EmailValidator();
  const passwordRegex = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const closeActivationConfirmationModal = () => {
    setOpenActivationConfirmationModal(false);
  };

  const handleChange = (otp) => {
    if (showActivationError) {
      setShowActivationError(false);
    }
    setOtp(otp);
  };

  const closeSignUpModal = () => {
    setEmail("");
    setPass("");
    setCPass("");
    reset({ email: "", pass: "", cpass: "", termsAndConditions: false });
    if (showSignUpError) {
      setShowSignUpError(false);
    }
    setSignUpOpen(false);
  };

  const closeActivationCodeModal = () => {
    if (showActivationError) {
      setShowActivationError(false);
    }
    setOtp("");
    setOpenActivationModal(false);
  };

  const registerMentee = async () => {
    if (!validator.isValid(email)) {
      setEmailInvalid(true);
      return;
    }

    if (!passwordRegex.test(password)) {
      setPasswordInvalid(true);
      return;
    }

    setLoad(true);
    const data = {
      email: email,
      password: password || cpass,
      roleName: "APPLICANT",
    };

    try {
      const res = await axios.post(
        `${config.baseUrl}/api/v1/auth/signUp`,
        data
      );
      setLoad(false);
      if (res?.status === 200 && res?.data?.result) {
        closeSignUpModal();
        setUserId(res.data.result.id);
        setOpenActivationModal(true);
      } else {
        console.log(res);
        setSignUpErrorMsg(res.data.message);
        setShowSignUpError(true);
      }
    } catch (e) {
      console.log(e);
      setLoad(false);
      error("Error", "Something went wrong. Try again");
      setShowSignUpError(true);
    }
  };

  const closeActivationConfirmationErrorModal = () => {
    setOpenActivationConfirmationErrorModal(false);
  };

  const activateUser = async () => {
    setLoad(true);
    try {
      const res = await axios.post(
        `${config.baseUrl}/api/v1/auth/activateUser/${userId}/${otp}`
      );
      setLoad(false);
      if (res?.status === 200 && res.data.result) {
        dispatch(authSuccess(res.data.result));
        setAuthToken(res.data.result.accessToken);
        setOpenActivationConfirmationModal(true);
        setOpenActivationModal(false);
        PersistSession({
          session: res.data.result,
          authenticated: true,
          errorMsg: null,
        });
        closeActivationCodeModal();
      } else {
        console.log(res);
        setActivationErrorMsg(res.data.message);
        setShowActivationError(true);
      }
    } catch (e) {
      setLoad(false);
      setOpenActivationConfirmationErrorModal(true);
      setOpenActivationModal(false);
      console.log(e);
      if (e?.response?.status === 400) {
        if (isJson(e?.response?.data?.message)) {
          const msgObj = JSON.parse(e?.response?.data?.message);
          error(
            "Error",
            `Invalid code. Number of attempts left: ${msgObj.noOfTrials}`
          );
        } else if (
          e?.response?.data?.message ===
          "You have exceeded activation token trial time. A new token has been sent your email address."
        ) {
          setActivationErrorMsg("l");
          error(
            "Error",
            "Maximum number of attempts exceeded. A new code has been sent to your emai"
          );
        } else {
          error("Error", "Something went wrong. Try again");
        }
      } else {
        error("Error", "Something went wrong. Try again");
      }
      setShowActivationError(true);
    }
  };

  const resendCode = async () => {
    if (showActivationError) {
      setShowActivationError(false);
    }
    setLoadResend(true);
    try {
      const res = await axios.get(
        `${config.baseUrl}/api/v1/auth/resendToken/${userId}`
      );
      setLoadResend(false);
      if (res?.status !== 200) {
        console.log(res);
      } else {
        setOpenActivationModal(true);
        setOpenActivationConfirmationErrorModal(false);
      }
    } catch (e) {
      setLoadResend(false);
      console.log(e);
    }
  };

  return (
    <>
      <Modal
        title={false}
        visible={signupOpen}
        footer={false}
        maskClosable={false}
        width={"100%"}
        onCancel={closeSignUpModal}
        style={{ marginTop: "1rem" }}
        bodyStyle={{ height: "100vh" }}
      >
        <div className="d-flex justify-content-end align-items-center">
          {/* <div href="/" className="mD-auto">
            <img src={Logo} className="w-75" />
          </div> */}
          <div
            onClick={closeSignUpModal}
            className="d-flex cursor justify-content-end cursor align-items-center"
          >
            <img src={closeBtn} width="15" />
          </div>
        </div>

        <div className=" pt-30">
          <br />
          <br />
          <br />
          <form>
            <div className="signup-form-wrapper-new">
              <div className="form-inputs">
                <br />
                <br />
                <div className="text-center col-lead">Sign up</div>
                <div className="mb-4">
                  <Input
                    label=""
                    require
                    placeholder="Email"
                    name="email"
                    type="email"
                    {...register("email", {
                      required: "Email is required",
                      onChange: (e) => {
                        if (showSignUpError) {
                          setShowSignUpError(false);
                        }
                        if (emailInvalid) {
                          setEmailInvalid(false);
                        }
                        setEmail(e.target.value);
                      },
                    })}
                  />
                </div>
                <span className="text-danger">{errors?.email?.message}</span>
                {emailInvalid && (
                  <span className="text-danger">
                    Enter a valid email address
                  </span>
                )}
                {/* <div className="mb-4">
                  <Input
                    label=""
                    require
                    placeholder="Password"
                    name="pass"
                    type="password"
                    {...register("pass", {
                      required: "Password is required",
                      onChange: (e) => {
                        if (showSignUpError) {
                          setShowSignUpError(false);
                        }
                        if (passwordInvalid) {
                          setPasswordInvalid(false);
                        }
                        setPass(e.target.value);
                      },
                    })}
                    error={errors?.pass?.message}
                  />
                  <p style={{ fontSize: "12px" }} className="pt-4">
                    Use <b>8</b> or more characters with at least{" "}
                    <b>one upper case</b> letter,
                    <b>one lower case</b> letter, <b>one digit</b> and{" "}
                    <b>one special character</b>
                  </p>
                  <span className="text-danger">{errors?.pass?.message}</span>
                  {passwordInvalid && (
                    <span className="text-danger">Use a valid password</span>
                  )}
                </div> */}
                <div className="mb-4">
                  <div
                    class="d-flex w-100 justify-content-between align-items-center  mt-4"
                    style={style}
                  >
                    <div
                      className="d-flex w- h-100 justify-content"
                      style={{
                        width: "85%",
                      }}
                    >
                      <input
                        type={eye ? `text` : "password"}
                        placeholder="Password"
                        className="d-flex w-100 h-100 px-3"
                        {...register("pass", {
                          required: "Password is required",
                          onChange: (e) => {
                            if (showSignUpError) {
                              setShowSignUpError(false);
                            }
                            if (passwordInvalid) {
                              setPasswordInvalid(false);
                            }
                            setPass(e.target.value);
                          },
                        })}
                        style={{
                          border: "0 !important",
                          outline: "0 !important",
                          outlineColor: "none !important",
                          outline: "none !important",
                        }}
                      />
                    </div>

                    <div
                      className="cursor d-flex h-100 justify-content-center align-items-center"
                      style={{
                        width: "15%",
                      }}
                      onClick={() => seteye(!eye)}
                    >
                      {eye ? (
                        <div>
                          <img src={eyeShow} width="20" />
                        </div>
                      ) : (
                        <div>
                          <img src={eyeOff} width="20" />
                        </div>
                      )}
                    </div>
                  </div>
                  <p style={{ fontSize: "12px" }} className="pt-4">
                    Use <b>8</b> or more characters with at least{" "}
                    <b>one upper case</b> letter,
                    <b> one lower case</b> letter, <b> one digit</b> and{" "}
                    <b>one special character.</b>
                  </p>
                  <span className="text-danger">{errors?.pass?.message}</span>
                  {passwordInvalid && (
                    <span className="text-danger">Use a valid password</span>
                  )}
                </div>

                <div className="mb-4">
                  <div
                    class="d-flex w-100 justify-content-between align-items-center  mt-4"
                    style={style}
                  >
                    <div
                      className="d-flex w- h-100 justify-content"
                      style={{
                        width: "85%",
                      }}
                    >
                      <input
                        type={eye2 ? `text` : "password"}
                        placeholder="Confirm password"
                        className="d-flex w-100 h-100 px-3"
                        {...register("cpass", {
                          required: "Confirm Password is required",
                          onChange: (e) => {
                            if (showSignUpError) {
                              setShowSignUpError(false);
                            }
                            setCPass(e.target.value);
                          },
                          validate: (value) =>
                            value === password ||
                            "Confirm Password and Password do not match",
                        })}
                        style={{
                          border: "0 !important",
                          outline: "0 !important",
                          outlineColor: "none !important",
                          outline: "none !important",
                        }}
                      />
                    </div>

                    <div
                      className="cursor d-flex h-100 justify-content-center align-items-center"
                      style={{
                        width: "15%",
                      }}
                      onClick={() => seteye2(!eye2)}
                    >
                      {eye2 ? (
                        <div>
                          <img src={eyeShow} width="20" />
                        </div>
                      ) : (
                        <div>
                          <img src={eyeOff} width="20" />
                        </div>
                      )}
                    </div>
                  </div>
                  <span className="text-danger"> {errors?.cpass?.message}</span>
                  {signUpErrorMsg && showSignUpError && (
                    <div className="signup-action">
                      <span className="text-danger">{signUpErrorMsg}</span>
                    </div>
                  )}
                </div>
                <div className="registered wrapper">
                  <div className="text-center form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      {...register("check1", {
                        required: "You must agree to terms and privacy policy",
                      })}
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      class="form-check-label privacy-policy-text"
                      for="flexCheckDefault"
                      style={{
                        fontWeight: "300",
                        fontSize: "13px",
                        fontStyle: "italic",
                      }}
                    >
                    </label>
                    By signing up to Edyter, you agree to our{" "}
                    <span
                      className="bold-num cursor link-hover-ed linkss"
                      onClick={() => setOpenTerms(true)}
                    >
                      Terms{" "}
                    </span>{" "}
                    and{" "}
                    <span
                      className="bold-num cursor link-hover-ed linkss"
                      onClick={() => setOpenPrivacyPolicy(true)}
                    >
                      {" "}
                      Privacy Policy
                    </span>
                    <div className="text-danger">
                      {errors?.check1?.message}
                    </div>
                  </div>
                </div>
                <div
                  className={`${load ? 'is-disabled' : ''} sing-buttom mb-20`}
                  onClick={handleSubmit(registerMentee)}
                >
                  <button type="submit" className="sing-btn">
                    {load ? "LOADING..." : "Sign up"}
                  </button>
                </div>
                <div className="not-register">
                  <br />
                  <div className="bold-num text-center">
                    Have an account?{" "}
                    <span className="bold-num">
                      <a
                        className="link-hover-ed text-no-decoration"
                        href="#!"
                        style={{ textDecoration: "none" }}
                        onClick={() => {
                          closeSignUpModal();
                          setSignInOpen(true);
                        }}
                      >
                        Sign in
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="signup-action">
                <div className="course-sidebar-list">
                  <div className="d-flex">
                    <input
                      type="checkbox"
                      id="sing-up"
                      {...register("termsAndConditions", {
                        required: "Check the box to agree",
                        onChange: (e) => {
                          if (showSignUpError) {
                            setShowSignUpError(false);
                          }
                        },
                      })}
                    />
                    <label className="sign-check" htmlFor="sing-up">
                      <span>
                        I agree to the{" "}
                        <a
                          href="#!"
                          className="link-hover"
                          onClick={() => {
                            setOpenTerms(true);
                          }}
                        >
                          {" "}
                          Terms and Conditions
                        </a>{" "}
                        and{" "}
                        <a
                          href="#!"
                          className="link-hover"
                          onClick={() => {
                            setOpenPrivacyPolicy(true);
                          }}
                        >
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </div>
                <span className="text-danger">
                  {errors?.termsAndConditions?.message}
                </span>
              </div> */}
          </form>
          <br />
          <br />
          <br />
        </div>
      </Modal>
      <PrivacyPolicy
        openPrivacyPolicy={openPrivacyPolicy}
        setOpenPrivacyPolicy={setOpenPrivacyPolicy}
      />
      <TermsConditions openTerms={terms} setOpenTerms={setOpenTerms} />

      <Modal
        title={false}
        visible={openActivationModal}
        footer={false}
        onCancel={closeActivationCodeModal}
        width={"100%"}
        wrapClassName="example"
        style={{ marginTop: "1rem" }}
        bodyStyle={{ height: "100%" }}
        maskClosable={false}
      >
        <div
          onClick={closeActivationCodeModal}
          className="d-flex justify-content-end cursor align-items-center"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div className="signup-form-wrapper-new">
          <div className="form-inputs ">
            <div className="forgot-password-bord">
              <div
                className="text-center col-lead mt-4"
                style={{ color: "#382D8B" }}
              >
                Enter code
              </div>
              <div className="text-center">
                Please enter the code that was just sent to you.
              </div>
              <div
                className="d-flex justify-content-center align-items-center h-100 pt-4 pb-4"
                style={{ marginLeft: ".8rem" }}
              >
                <OTPInput
                  value={otp}
                  onChange={handleChange}
                  OTPLength={6}
                  separator={<span></span>}
                  inputStyles={{
                    width: "70%",
                    height: "3.5rem",
                    borderRadius: "5px",
                    fontSize: "16px",
                    color: "#382D8B",
                    fontWeight: "400",
                    fontSize: "29px",
                    outlineColor: "1px solid #B7B7B7",
                    outline: "1px solid #B7B7B7",
                    lineHeight: "107.5%",
                    color: "#382D8B",
                    border: "1px solid #B7B7B7",
                  }}
                />
              </div>
              {activationErrorMsg && showActivationError && (
                <div className="signup-action">
                  <span className="text-danger">{activationErrorMsg}</span>
                </div>
              )}
              <div className="d-flex flex-column">
                <div className="sing-buttom mb-20" onClick={activateUser}>
                  <button type="submit" className="sing-btn">
                    {load ? "Submitting..." : "Verify"}
                  </button>
                </div>
                <div
                  onClick={resendCode}
                  className="text-center bold-num primary-col mb-3  link-hover-ed cursor d-flex justify-content-center align-items-center"
                >
                  {" "}
                  {loadResend ? "Sending..." : "Resend code"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title={false}
        visible={openActivationConfirmationErrorModal}
        footer={false}
        width={"100%"}
        onCancel={closeActivationConfirmationErrorModal}
        wrapClassName="example"
        style={{ marginTop: "1rem" }}
        bodyStyle={{ height: "100%" }}
        maskClosable={false}
      >
        <div
          onClick={closeActivationConfirmationErrorModal}
          className="d-flex cursor justify-content-end align-items-center"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div className="pt-50">
          <div className="signup-form-wrapper-new">
            <div className="form-inputs">
              <div className="forgot-password-bord" style={{ padding: "20px" }}>
                {" "}
                <div className="d-flex justify-content-center align-items-center h-100">
                  <div>
                    <div className="text-center d-flex justify-content-center align-items-center">
                      <img src={closeX} width="50" />
                    </div>
                    <br />
                    <div
                      className="text-center col-lead"
                      style={{ color: "red" }}
                    >
                      Error!
                    </div>
                    <div>
                      Looks like you have used an invalid token or the
                      activation token has expired. Click the button and we will
                      send another code.
                    </div>
                    <br />
                    <div className="sing-buttom mb-20" onClick={resendCode}>
                      <button type="submit" className="sing-btn">
                        Resend Verification code
                      </button>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title={false}
        visible={openActivationConfirmationModal}
        footer={false}
        width={"100%"}
        onCancel={closeActivationConfirmationModal}
        wrapClassName="example"
        style={{ marginTop: "1rem" }}
        bodyStyle={{ height: "100%" }}
        maskClosable={false}
      >
        <div
          onClick={closeActivationConfirmationModal}
          className="d-flex cursor justify-content-end align-items-center"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div className="pt-50">
          <div className="signup-form-wrapper-new">
            <div className="form-inputs">
              <div className="forgot-password-bord" style={{ padding: "20px" }}>
                {" "}
                <div className="d-flex justify-content-center align-items-center h-100">
                  <div>
                    <div className="text-center d-flex justify-content-center align-items-center">
                      <img src={closeX} width="50" />
                    </div>
                    <br />
                    <div
                      className="text-center col-lead"
                      style={{ color: "#382D8B" }}
                    >
                      Verified!
                    </div>
                    <div>You have successfully verified your Account.</div>
                    <br />

                    <div
                      className="sing-buttom mb-20"
                      onClick={() => {
                        history.push("/orders");
                      }}
                    >
                      <button type="submit" className="sing-btn">
                        Continue
                      </button>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export default SignUp;
