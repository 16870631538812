import axios from "axios";
import config from "../config";

export const moreInfoThunkCreator = (setLoad, setShowCreateError, setCreateError, data, next) => {
    return async (dispatch) => {
        setLoad(true);
        try {
            const res = await axios.post(`${config.baseUrl}/api/v1/application/moreInfo`, data)
            setLoad(false)
            if (res?.status === 200) {
                next()
            } else {
                console.log(res)
                setCreateError("Something went wrong. Try again")
                setShowCreateError(true)

            }
        } catch (e) {
            console.log(e)
            setLoad(false)
            setCreateError("Something went wrong. Try again")
            setShowCreateError(true)
        }
    }
};