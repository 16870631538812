import React from "react";
import HomeMain from "../components/Home/HomeMain";
import Footer from "../components/Layout/Footer/Footer";
import Header from "../components/Layout/Header";
import "antd/dist/antd.css";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

export default function Home() {
  const auth = useSelector((state) => state.auth);

  if (auth.authenticated) {
    return <Redirect to="/orders" />;
  }

  return (
    <>
      <Header auth={auth} />
      <HomeMain auth={auth} /> 
      <Footer />
    </>
  );
}
