import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";
import { Button } from "../../../components/Button/Index";
import { InputBox } from "../../Input/InputApplication";
import { useForm } from "react-hook-form";
import closeBtn from "../../../assets/img/close-btn-dark.svg";
import { ReactComponent as Edyter } from "../../../assets/img/logo/edyterBlack.svg";
import axios from "axios";
import "./index.css";
import PrivacyPolicy from "../Header/PrivacyPolicy";
import TermsConditions from "../Header/TermsConditions";
import config from "../../../config";
import { error, success } from "../../Alerts/Index";

const Footer = () => {
  const [openContactModal, setOpenContactModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [contactUsError, setContactUsError] = useState(false);
  const [showContactUsError, setShowContactUsError] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [terms, setOpenTerms] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
  } = useForm();

  const closeContact = () => {
    reset({ email: "", name: "", msg: "", topic: "" });
    if (showContactUsError) {
      setShowContactUsError(false);
    }
    setOpenContactModal(false);
  };

  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const name = watch("name");
  const email = watch("email");
  const subject = watch("subject");
  const message = watch("message");

  const SubmitContact = async (data) => {
    console.log(data);
    if (name && email && subject && message) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/api/v1/auth/contactus`, {
          email: email,
          fullName: name,
          message: message,
          purpose: subject,
        })
        .then((res) => {
          setLoad(false);
          if (res.status === 200 && res?.data?.result) {
            setOpenConfirmationModal(true);
            setOpenContactModal(false);
            reset({
              name: null,
              email: null,
              subject: null,
              message: null,
            });
          } else {
            console.log(res);
            error("Error", res.data.message);
          }
        })
        .catch((e) => {
          setLoad(false);
        });
    } else {
      error("Error", "Fields are required");
    }
  };

  return (
    <>
      <footer className="new-footer">
        <div className="container-custom-new">
          <div className="row footer-top">
            <div className="col-12 col-md-6">
              <div className="">
                <div className="">
                  <Link to="/">
                    <Edyter style={{ height: 100, width: 140 }} />
                  </Link>
                  <div className="footer-mute-white">
                    Great editing service for your application essays.
                    <br />
                  </div>
                </div>
                <br />
                <div className="font-weight-bold text-white footer-icons-top">
                  Follow us
                </div>

                <div className="footer-icon-col">
                  <a href="#!" className="footer-iconx">
                    <FontAwesomeIcon icon={["fab", "facebook"]} />
                  </a>
                  <a href="#!" className="footer-iconx">
                    <FontAwesomeIcon icon={["fab", "twitter"]} />
                  </a>
                  <a href="#!" className="footer-iconx">
                    <FontAwesomeIcon icon={["fab", "instagram"]} />
                  </a>
                  <a href="#!" className="footer-iconx">
                    <FontAwesomeIcon icon={["fab", "linkedin"]} />
                  </a>
                  <a
                    href="https://www.youtube.com/watch?v=HVaNqVBw8VE"
                    target="_blank"
                    style={{ color: "#F8A401" }}
                  >
                    <FontAwesomeIcon icon={["fab", "youtube"]} />
                  </a>
                </div>
                <div className="footer-mute-white mt-3">
                  or email us at{" "}
                  <span className="footer-mute-white">
                    <u>info@edyter.com</u>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3">
              <div className="footer-heading-white">Resources</div>
              <Link to="/faqs">
                <div className="footer-mute">FAQs</div>
              </Link>
              <div className="footer-mute">
                {" "}
                <a
                  href="https://www.youtube.com/watch?v=HVaNqVBw8VE"
                  target="_blank"
                >
                  Youtube
                </a>
              </div>
              <div className="footer-mute">Blog</div>
            </div>

            <div className="col-12 col-md-3">
              <div
                className="footer-heading"
                onClick={() => setOpenContactModal(true)}
              >
                {" "}
                Contact us
              </div>
              <div className="cursor-pointer footer-mute"></div>
            </div>
          </div>
        </div>
        <div className="footer-sub-top">
          <hr />
        </div>
        <div className="container-sub">
          <div className="flex-sub-footer">
            <div className="bottom-items">
              <div
                className="footer-space-right cursor"
                onClick={() => setOpenPrivacyPolicy(true)}
              >
                Privacy Policy
              </div>
              <div
                className="footer-space-right cursor"
                onClick={() => setOpenTerms(true)}
              >
                Terms of Use
              </div>
            </div>
            <div className="copy-right-ed">
              <div>
                {" "}
                <span>
                  {" "}
                  © {new Date().getFullYear()} Edyter, All Rights Reserved
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <Modal
        title={false}
        visible={openContactModal}
        footer={false}
        centered={true}
        width={600}
        onCancel={closeContact}
      >
        <div
          onClick={closeContact}
          className="d-flex justify-content-end align-items-center cursor"
        >
          <img src={closeBtn} width="15" />
        </div>
        <form>
          <div className="d-flex justify-content-center align-items-center">
            <div className="form-inputs">
              <div
                className="text-center col-lead-sample h2"
                style={{ fontSize: "18px" }}
              >
                Contact us
              </div>
              <br />
              <div className="text-center">
                Please use the form below to get in touch.
              </div>
              <div>
                <div>
                  <div className="mb-3 row">
                    <div className="col-md-6 col-12">
                      <div>
                        <InputBox
                          require
                          placeholder="Full name"
                          name="name"
                          type="text"
                          {...register("name", {})}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div>
                        <InputBox
                          require
                          placeholder="Email"
                          name="email"
                          type="email"
                          {...register("email", {})}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div>
                      <div>
                        <InputBox
                          require
                          placeholder="Subject"
                          name="subject"
                          type="text"
                          {...register("subject", {})}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div>
                      <div>
                        <textarea
                          className="additional-comment"
                          rows="4"
                          {...register("message", {})}
                          cols="60"
                          placeholder="Message"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-100 mb-1 d-flex justify-content-center align-items-center">
                  <Button
                    text="Send"
                    onClick={handleSubmit(SubmitContact)}
                    isDisabled={load}
                    loading={load}
                    className="purple w-50"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>
      <PrivacyPolicy
        openPrivacyPolicy={openPrivacyPolicy}
        setOpenPrivacyPolicy={setOpenPrivacyPolicy}
      />
      <TermsConditions openTerms={terms} setOpenTerms={setOpenTerms} />

      <Modal
        title={false}
        visible={openConfirmationModal}
        footer={false}
        width={600}
        onCancel={closeConfirmationModal}
        maskClosable={true}
        wrapClassName="example"
        style={{ marginTop: "-1rem" }}
      >
        <div
          onClick={closeConfirmationModal}
          className="d-flex justify-content-end align-items-center cursor"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div className="rate-padon">
          <br />
          <div className="text-center ">
            <h2 style={{ fontSize: "16px" }}>
              Thank you for your message. We will be in touch shortly.
            </h2>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Footer;
