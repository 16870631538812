import React, { useState } from "react";
import Line from "../../assets/img/line-thru.svg";
import "./index.css";
import { Rate } from "antd";
import "./testimonials.css";
import SpeedEd from "../../assets/img/speed-ed.svg";
import QualityEd from "../../assets/img/quality-ed.svg";
import ConfidentialEd from "../../assets/img/confidential-ed.svg";
import AuthenticEd from "../../assets/img/authentic-ed.svg";
import { testimonyData } from "./ratings";
import WomanRec from "../../assets/img/banner/woman-rec.svg";
import CarouselSlide from "react-grid-carousel";
import YoutubeEmbed from "../YoutubeEmbed";
import Rater from "../../assets/img/start-rev.svg";
import "react-rater/lib/react-rater.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

const HomeMain = ({ auth }) => {
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  const responsive = {
    0: {
      items: 1,
    },
    568: {
      items: 2,
    },
    1024: {
      items: 3,
      itemsFit: "contain",
    },
  };

  const items = [
    <div className="item" data-value="1">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #4036</div>
            <div className="text-center">Pakistan</div>
            <Rate disabled value={5} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test"></div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="2">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #3355</div>
            <div className="text-center">United States</div>
            <Rate disabled value={5} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test"></div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="3">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #3354</div>
            <div className="text-center">United States</div>
            <Rate disabled value={5} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test"></div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="4">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #3065</div>
            <div className="text-center">United States</div>
            <Rate disabled value={5} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test"></div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="5">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #3875</div>
            <div className="text-center">Vietnam</div>
            <Rate disabled value={5} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test"></div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="6">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #567</div>
            <div className="text-center">Nigeria</div>
            <Rate disabled value={5} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test">
            You took the words right out of my heart and mind. Love this, keep
            up the good work.
          </div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="7">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #518</div>
            <div className="text-center">India</div>
            <Rate disabled value={5} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test"></div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="8">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #517</div>
            <div className="text-center">India</div>
            <Rate disabled value={5} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test">
            Thank you so much for this editing help. It has been aboon for
            someone like me.
          </div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="9">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #456</div>
            <div className="text-center">India</div>
            <Rate disabled value={5} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test"></div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="10">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #3661</div>
            <div className="text-center">India</div>
            <Rate disabled value={5} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test"></div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="11">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #1978</div>
            <div className="text-center">Thailand</div>
            <Rate disabled value={5} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test"></div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="12">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #3777</div>
            <div className="text-center">Ghana</div>
            <Rate disabled value={5} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test">
            You guys actually did an outstanding work. Thank You.
          </div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="13">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #3070</div>
            <div className="text-center">Nigeria</div>
            <Rate disabled value={5} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test">
            Give an option or space for us to add additional requirements that
            schools might need.
          </div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="14">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #3847</div>
            <div className="text-center">Saudi Arabia</div>
            <Rate disabled value={4} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test">Include comments</div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="15">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #4034</div>
            <div className="text-center">China</div>
            <Rate disabled value={5} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test"></div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="16">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #4510</div>
            <div className="text-center">Nigeria</div>
            <Rate disabled value={5} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test">THANK YOU</div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="17">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #4572</div>
            <div className="text-center">Peru</div>
            <Rate disabled value={5} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test">
            Great work, I’m very grateful.Thanks!
          </div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="18">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #4805</div>
            <div className="text-center">Nigeria</div>
            <Rate disabled value={5} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test">Thanks!</div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="19">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #4688</div>
            <div className="text-center">Nigeria</div>
            <Rate disabled value={5} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test">Wow!</div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="20">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #4427</div>
            <div className="text-center">India</div>
            <Rate disabled value={5} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test">
            Thanks anon for helping with my SOP.
          </div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="21">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #3106</div>
            <div className="text-center">Nigeria</div>
            <Rate disabled value={4} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test"></div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="22">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #3538</div>
            <div className="text-center">Nigeria</div>
            <Rate disabled value={4} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test"></div>
        </div>
        <br />
      </div>
    </div>,
    <div className="item" data-value="23">
      <div>
        <div className="testimonial-body">
          <div className="testimonial-p">
            <div className="testimonal-head">Client #4251</div>
            <div className="text-center">Nigeria</div>
            <Rate disabled value={4} style={{ fontSize: "20px" }} />
          </div>
          <br />
          <div className="comment-test"></div>
        </div>
        <br />
      </div>
    </div>,
  ];
  return (
    <div className="">
      <div className="home-section">
        <section className="hiw-area">
          <div className="container-mobile-h">
            <br />
            <br />
            <h2 className="lead-home text-center">
              How it works
            </h2>

            <div className="d-flex justify-content-center align-items-center on-mobile">
              <div className="system-flex-arrow">
                <div>
                  <div className="circle-arrow">
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <span className="circle-inner">1</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="sub-row-text-dark ">Create an Account</div>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                  <img src={Line} />
                </div>{" "}
                <div className="circle-arrow">
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <span className="circle-inner">2</span>
                  </div>
                </div>
                <div>
                  <div className="sub-row-text-dark bold-num">
                    Fill bio and upload your draft
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                  <img src={Line} />
                </div>{" "}
                <div className="circle-arrow">
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <span className="circle-inner">3</span>
                  </div>
                </div>
                <div>
                  <div className="sub-row-text-dark bold-num">
                    Submit to review
                  </div>
                </div>
              </div>
              <br />
            </div>
            <div className="show-web">
              <div className="d-flex justify-content-center align-items-center">
                <div className="system-flex">
                  <div>
                    <div className="circle-arrow">
                      <div className="d-flex justify-content-center align-items-center h-100">
                        <span className="circle-inner">1</span>
                      </div>
                    </div>
                  </div>
                  <div class="arrow">
                    <div class="line"></div>
                    <div class="point"></div>
                  </div>{" "}
                  <div className="circle-arrow">
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <span className="circle-inner">2</span>
                    </div>
                  </div>
                  <div class="arrow">
                    <div class="line"></div>
                    <div class="point"></div>
                  </div>{" "}
                  <div className="circle-arrow">
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <span className="circle-inner">3</span>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div className="d-flex justify-content-center align-items-center">
                <div className="system-flex">
                  <div style={{ paddingRight: "3rem" }}>
                    <div className="sub-row-text-dark ">Create an account</div>
                  </div>
                  <div style={{ paddingRight: "0rem" }}>
                    <div className="sub-row-text-dark bold-num">
                      Fill bio and upload draft
                    </div>
                  </div>
                  <div style={{ paddingRight: "2rem", marginLeft: '5rem' }}>
                    <div className="sub-row-text-dark bold-num">
                      Submit to review
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="show-web">
          <div className="edit-border">
            <div className="container top-padding">
              <div className="row">
                <div className="col-md-6 ">
                  <div className="content-part">
                    <img src={WomanRec} className="img-edit" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="content-part">
                    <div>
                      <div className="edit-caption">
                        We edit and provide{" "}
                        <span className="down-mark-line"> revisions </span> too!
                      </div>
                      <div className="edit-caption-sub"> All for <span style={{ textDecoration: 'line-through'}}>$5.99</span> (free) and your feedback. </div>
                      <div className="edit-caption-sub">
                        Overall user rating
                      </div>
                      <div className="edit-caption-sub">
                        <div className="d-flex align-items-center">
                          <img src={Rater} className="w-75" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="show-mobile">
          <div className="edit-border">
            <div className="container top-padding">
              <div className="row">
                <div className="col-md-12 ">
                  <div className="content-padding-edit d-flex justify-content-center align-items-centee">
                    <img src={WomanRec} className="w-75" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="" style={{ padding: "0px 50px" }}>
                    <div>
                      <div className="edit-caption">
                        We edit and provide{" "}
                        <span className="down-mark-line"> revisions </span> too!{" "}
                      </div>
                     <div className="edit-caption-sub">All for <span style={{ textDecoration: 'line-through'}}>$5.99</span> (free) and your feedback. </div>
                      <div className="edit-caption-sub">
                        Overall user rating
                      </div>
                      <div className="edit-caption-sub">
                        <div className="d-flex align-items-top">
                          <div className="rate-space">
                            <Rate allowHalf defaultValue={4.9} />
                          </div>
                          <div className="" style={{ fontWeight: "800" }}>
                            4.9/5
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="why-edyter">
          <h3 className="lead-home text-center">Why Edyter</h3>
          <div class="d-flex justify-content-center align-items-center">
            <br />
            <div className="show-mobile">
              <div className="text-center edyter-whys">
                <img src={SpeedEd} width="40" />
                <br />
                <div className="edyter-phases-head">Speed</div>
                <div className="phase-sub">
                  Max
                  <span className=""> time </span> now{" "}
                  <span className="bold-num">2 hours!</span>
                </div>
              </div>
              <div className="text-center edyter-whys">
                <img src={AuthenticEd} width="40" />
                <br />
                <div className="edyter-phases-head">Originality</div>
                <div className="phase-sub">
                  Our edit shines through and we{" "}
                  <span className="bold-num">preserve</span> your story.{" "}
                </div>
              </div>
              <div className="text-center edyter-whys">
                <img src={QualityEd} width="40" />
                <br />
                <div className="edyter-phases-head">Quality</div>
                <div className="phase-sub">
                  We built Edyter based on our
                  <span
                    className="bold-num"
                    style={{ whiteSpace: "nowrap", width: "100%" }}
                  >
                    {" "}
                    extensive experience.
                  </span>
                </div>
              </div>
              <div className="text-center edyter-whys">
                <img src={ConfidentialEd} width="40" />
                <br />
                <div className="edyter-phases-head">Confidentiality</div>
                <div className="phase-sub">
                  We ensure that your data is{" "}
                  <span className="bold-num">safe</span> with us.
                </div>
              </div>
            </div>
          </div>

          <div className="edyter-phases show-web ">
            <div className="d-flex justify-content-center align-items-center">
              <div className="">
                <div className="d-flex align-items-top">
                  <div className="col-lg-6" style={{ marginRight: "10rem" }}>
                    <div className="d-flex align-items-center">
                      <div className="icon-right-p">
                        <img src={SpeedEd} width="40" />
                      </div>
                      <div className="edyter-phases-head ">Speed</div>
                    </div>
                    <div className="phase-sub">
                      Max
                      <span className=""> time </span> now{" "}
                      <span className="bold-num">2 hours!</span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex align-items-center">
                      <div className="icon-right-p">
                        <img src={AuthenticEd} width="45" />
                      </div>
                      <div className="edyter-phases-head "> Originality</div>
                    </div>
                    <div className="phase-sub">
                      Our edit shines through and we{" "}
                      <span className="bold-num">preserve</span> your story.
                    </div>
                  </div>
                </div>

                <br />
                <br />
                <div className="d-flex  align-items-top">
                  <div className="col-lg-6" style={{ marginRight: "10rem" }}>
                    <div className="d-flex align-items-center">
                      <div className="icon-right-p">
                        <img src={QualityEd} width="50" />
                      </div>
                      <div className="edyter-phases-head ">Quality</div>
                    </div>
                    <div className="phase-sub">
                      We built Edyter <br /> based on our <br />{" "}
                      <span
                        className="bold-num"
                        style={{ whiteSpace: "nowrap", width: "100%" }}
                      >
                        {" "}
                        extensive experience.
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex align-items-center">
                      <div className="icon-right-p">
                        <img src={ConfidentialEd} width="40" />
                      </div>
                      <div className="edyter-phases-head ">Confidentiality</div>
                    </div>
                    <div className="phase-sub">
                      We ensure that your data is{" "}
                      <span className="bold-num">safe</span> with us.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="section-video">
          <div className="edit-border-video">
            <div className="container top-padding">
              <div className="row">
                <div className="col-md-6 ">
                  <div className="statement">
                    How to write a winning statement of purpose?
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="content-part-statement">
                    <div className="w-100">
                      <YoutubeEmbed embedId="HVaNqVBw8VE" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="testimonal-dyter">
          <h3 className="lead-home-test text-center">
            What our users are saying
          </h3>
          <div className="testimonail-box show-web">
            <CarouselSlide cols={1} rows={1} gap={30} loop autoplay={3000}>
              {testimonyData?.rows?.map((data) => (
                <CarouselSlide.Item>
                  <div className="center-div">
                    <div className="testimonial-body ">
                      <div className="testimonial-p">
                        <div className="testimonal-head">
                          Client #{data.client}
                        </div>
                        <div className="text-center">{data.country}</div>
                        <Rate
                          disabled
                          value={data.rate}
                          style={{ fontSize: "20px" }}
                        />
                      </div>
                      <div className="comment-test">{data.content}</div>
                    </div>
                  </div>
                </CarouselSlide.Item>
              ))}
            </CarouselSlide>
          </div>
        </section>

        <section className="carousel-mobile show-mobile">
          <br />
          <br />
          <AliceCarousel infinite={true} animationDuration={1500} autoPlay={true} mouseTracking items={items} disableButtonsControls={true} renderDotsItem={false} responsive={responsive} />
        </section>
      </div>
    </div>
  );
};

export default HomeMain;
