import React, { useState } from "react";
import useSticky from "../../../hooks/useSticky";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Drawer from "react-modern-drawer";
import MobileMenu from "./MobileMenu";
import "react-modern-drawer/dist/index.css";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import radius from "../../../assets/img/radius.svg";
import FrameBook from "../../../assets/img/framebuka.png";
import { Button } from "../../../components/Button/Index";
import LogWhite from "../../../assets/img/Logwhite.svg";
import "./index.css";
import { clearAuth } from "../../../redux/features/authSlice";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ReactComponent as Edyter } from "../../../assets/img/logo/edyterPurple.svg";
import { clearApplicant } from "../../../redux/features/applicantSlice";
import { clearSession } from "../../../redux/SessionStorageUtils";
import { clearApplication } from "../../../redux/features/applicationSlice";
import { logoutThunkCreator } from "../../../redux-thunk/loginThunkCreator";
import closeIcon from "../../../assets/img/close-icon.svg";
import menuBar from "../../../assets/img/menu-bar.svg";
import Logo from "../../../assets/img/Log.svg";
import { Rate } from "antd";

const HeaderTop = ({ auth }) => {
  // sticky nav
  const { sticky } = useSticky();
  const dispatch = useDispatch();
  const location = useLocation();
  const activeRoute = location.pathname;

  const [collapsed, setCollapsed] = useState(true);

  const [signinOpen, setSignInOpen] = useState(false);
  const [signupOpen, setSignUpOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const toggleDrawer = () => setOpen(!open);

  return (
    <div>
      <div className="">
        <div className="container show-mobile">
          <br />
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/">
              <div className="">
                <img src={Logo} className="w-100" />
              </div>
            </Link>
            <div className="text-dark" onClick={toggleDrawer}>
              <img src={menuBar} className="w-100" />
            </div>
          </div>
        </div>
        <div className="container-custom-new">
          <div className="pt-3 show-web">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="text-dark">
                <Link to="/">
                  <img src={Logo} />
                </Link>
              </div>
              <div
                className="d-flex align-items-center"
                style={{ cursor: "pointer" }}
              >
                <Link to="/faqs">
                  <div className="mr-5 space-right primary-col bold-num">
                    FAQs
                  </div>
                </Link>
                <div
                  className="mr-5 space-right primary-col bold-num "
                  onClick={() => setSignInOpen(true)}
                >
                  Sign In
                </div>
                <div
                  className="sign-up-head "
                  onClick={() => setSignUpOpen(true)}
                >
                  Sign Up
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        open={open}
        onClose={toggleDrawer}
        style={{
          width: "100%",
          background: "#382d8b",
          color: "#fff",
          height: "635px",
        }}
        direction="right"
      >
        <div>
          <div className="draw-head">
            <div className="d-flex justify-content-between align-items-center">
              <div onClick={() => (window.location.href = "/")}>
                {" "}
                <img src={LogWhite} className="w-100" />
              </div>
              <div onClick={() => setOpen(false)}>
                <img src={closeIcon} width="27" />
              </div>
            </div>
          </div>
          <hr />

          <div className="d-flex justify-content-center align-items-center">
            <div style={{ marginTop: "3rem" }}>
              <div>FAQS</div>
              <br />
              <div onClick={() => setSignInOpen(true)}>Sign In</div>
            </div>
            <br />
          </div>
          <div>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "4rem" }}
            >
              <div
                className="sign-up-orange-mob"
                onClick={() => setSignUpOpen(true)}
              >
                Sign Up
              </div>
            </div>
          </div>
        </div>
      </Drawer>

      <SignIn
        signinOpen={signinOpen}
        setSignInOpen={setSignInOpen}
        setSignUpOpen={setSignUpOpen}
        auth={auth}
      />
      <SignUp
        signupOpen={signupOpen}
        setSignUpOpen={setSignUpOpen}
        setSignInOpen={setSignInOpen}
      />
    </div>
  );
};

export const clearStore = (dispatch) => {
  dispatch(clearAuth());
  dispatch(clearApplicant());
  dispatch(clearApplication());
  clearSession();
};

export default HeaderTop;
