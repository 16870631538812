import { setAuthToken } from "../serviceApis/authentication";

export function PersistSession(auth) {
    sessionStorage.setItem('auth', JSON.stringify(auth))
}

export function getSession() {
    const auth = JSON.parse(sessionStorage.getItem('auth'));
    if (auth?.session?.accessToken) {
        setAuthToken(auth?.session?.accessToken)
    }
    return auth
}

export function clearSession() {
    sessionStorage.clear()
}