import React from "react";
import EmptyIcon from "../../assets/img/empty.svg";
import { Link } from "react-router-dom";
import "./index.css";

export const EmptyState = ({ text, showButton, buttonText, buttonColor }) => {
  return (
    <div style={{ height: "60vh" }}>
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="text-center">
          <img src={EmptyIcon} className="w-25" />
          <div className="empty-text">
            You don’t have any reviews yet. They will appear here when you do.
          </div>
          <div
            onClick={() => {
              window.location.href = "/orders/review-request-application";
            }}
            className="primary-col d-flex justify-content-center align-items-center clickable-hov"
          >
            <b>Request a new review</b>
          </div>
        </div>
      </div>
    </div>
  );
};
