import { forwardRef } from "react";
import "./index.css";
export const InputBox = forwardRef((props, ref) => {
  const style = {
    ...props.style,
    height: props.height ? "100%" : "50px",
    background: "#FFFFFF !important",
    width: "100%",
    border: "0.899471px solid #B7B7B7 !important",
    borderRadius: "0.960832px",
    outlineColor: "#B7B7B7",
    outline: "1px solid #B7B7B7",
    fontWeight: "500",
    fontSize: "14px",
  };
  return (
    <div>
      <label className="label" htmlFor={props.name}>
        {props.label}
      </label>
      <div>
        {props.prependIcon && (
          <div className="prependIcon">{props.prependIcon}</div>
        )}
        <input
          {...props}
          ref={ref}
          title={props.title}
          style={{
            ...style,
            boxShadow: "none",
            background: "#F7F9FC",
            height: "50px",
            background: "#FFFFFF !important",
            width: "100%",
            border: "1px solid #B7B7B7 !important",
            borderRadius: "0.960832px",
            outlineColor: "#B7B7B7",
            outline: "1px solid #B7B7B7",
            fontWeight: "500",
            fontSize: "14px",
            ...style,
          }}
          placeholder={props.placeholder}
          disabled={props.disabled}
          value={props.value}
          class="form-control input-profile"
        />
      </div>
      {props.error && <p className="validate-error ">{props.error}</p>}
    </div>
  );
});
