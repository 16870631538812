import React from "react";
import Footer from "./Layout/Footer/Footer";
import HeaderTop from "./Layout/Header/top-header";
import PolicyContent from "./PolicyContent";
import { useSelector } from "react-redux";
//import { Redirect } from "react-router-dom";
import Faq from "../components/Faq";
import "../index.css";
export default function Faqs() {
  const auth = useSelector((state) => state.auth);

  return (
    <>
      <HeaderTop auth={auth} />
      <div className="home-section  faq-p">
        <PolicyContent />
      </div>
      <Footer />
    </>
  );
}
