import React, { useEffect, useState } from "react";
import Footer from "../../components/Layout/Footer/Footer";
import Header from "../../components/Layout/Header";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import "./index.css"
import { useStripe } from "@stripe/react-stripe-js";
import { stripePromise } from "./order-checkout";
import { DashboardContainer } from "../../components/DashboardContainer";
import { Elements } from "@stripe/react-stripe-js";

export default function OrderConfirmation() {
  return (
    <>
      <DashboardContainer>
        <Elements stripe={stripePromise}>
          <PaymentStatus />
        </Elements>
      </DashboardContainer>
    </>
  );
}

const PaymentStatus = () => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // Inspect the PaymentIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Your payment was successful, check your email for confirmation.");
          setHasError(false);
          setIsSuccess(true);
          break;

        case "processing":
          setMessage(
            "Payment being processed. We'll update you when payment is received."
          );
          setHasError(false);
          setIsSuccess(false);
          break;

        case "requires_payment_method":
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage("Payment failed. Please try another payment method.");
          setHasError(true);
          setIsSuccess(false);
          break;

        default:
          setMessage("Something went wrong. Try again");
          setHasError(true);
          setIsSuccess(false);
          break;
      }
    });
  }, [stripe]);

  return (
    <div
      className="d-flex justify-content-center align-items-center "
      style={{ height: "100vh" }}
    >
      <div className="order-check-border" >
        <div className="content-error-item text-center">
          <div className="section-title">
            <h4 className="mb-20">{message}</h4>
          </div>
          {!hasError && isSuccess && (
            <div className="error-btn">
              <Link to="/orders" className="edu-btn">
                Back to Reviews
              </Link>
            </div>
          )}
          {hasError && !isSuccess && (
            <div className="error-btn">
              <Link to="/orders" className="edu-btn">
                Try again
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
