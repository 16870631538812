import React from "react";
import Footer from "../components/Layout/Footer/Footer";
import Header from "../components/Layout/Header";
import { useSelector } from "react-redux";
import cynthia from '../assets/img/team/Cynthia.jpeg'
import martilord from '../assets/img/team/Martilord.jpg'
import amanda from '../assets/img/team/Amanda.jpg'
import angelique from '../assets/img/team/Angelique.jpg'
import yasmeen from '../assets/img/team/Yasmeen.JPG'
import ndu from '../assets/img/team/Ndu.jpg'
import { Redirect } from "react-router-dom";

export default function Team() {
    const auth = useSelector(state => state.auth)

    if (auth.authenticated) {
        return <Redirect to='/orders' />
    }

    return (
        <>
            <Header auth={auth} />
            <div className="mobile-space">
                <div className="container-padding ">
                    <div className="mb-20">
                        <div className="mt-10 d-flex flex-column">
                            <h4 style={{ fontWeight: '700' }}>Arts and Sciences</h4>
                            <div className="team-rows padding2">
                                <img src={angelique} alt="team not found" />
                                <div>
                                    <h5 style={{ fontWeight: '700' }}>Angelique, Researcher, Harvard Stem Cell Institute</h5>
                                    <p>Angelique is currently a lab manager and researcher with Massachusetts General Hospital and Harvard Stem Cell Institute. She received her A.S. from County College of Morris and her B.A. in Biology with a minor in Hispanic Studies from University of Pennsylvania. Angelique has published in Molecular and Cellular Biology, Cancer, Cancer Discovery, and Clinical Cancer Research. Her research interests and passions lie in healthcare equity, childrens’ rights, LGBTQIA+ justice, meditation benefits, and restorative justice practices. She is currently preparing for her MCAT examination with aspirations of becoming a pediatrician. During her free time, she can be found rock climbing, bouldering, hiking, practicing Argentine Tango, or visiting her favorite bookstores.</p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-50 d-flex flex-column">
                            <h4 style={{ fontWeight: '700' }}>Health Sciences</h4>
                            <div className="team-rows">
                                <img src={martilord} alt="team not found" />
                                <div style={{}}>
                                    <h5 style={{ fontWeight: '700' }}>Martilord, MD, PhD</h5>
                                    <p style={{ marginBottom: '8px' }}>Dr Martilord is an assistant professorial research fellow at the department of health policy, London school of Economics and Political Science (LSE), UK. His research interest lies in the economics and dynamic complexities of surgical systems in low- and middle-income countries. He has published in International Journal of Health Policy and Management, BMC Health Services Research, BMJ Open, Journal of Global Health, PLOS ONE, and BMJ Quality and Safety.</p>
                                    <p>Martilord trained as a medical doctor at the College of Medicine, University of Nigeria, Enugu, Nigeria; and is registered and licensed by the Medical and Dental Council of Nigeria (MDCN) and the UK General Medical Council (GMC). He also holds a master's degree in Health Economics, Policy, and Law from Erasmus University Rotterdam, The Netherlands, and a PhD in global health economics from Radboud University, Nijmegen, The Netherlands.</p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-50 d-flex flex-column">
                            <h4 style={{ fontWeight: '700' }}>Medical Sciences</h4>
                            <div className="team-rows">
                                <img src={cynthia} alt="team not found" />
                                <div>
                                    <h5 style={{ fontWeight: '700' }}>Cynthia MD C'2027, Ph.D. C'2026</h5>
                                    <p>Cynthia is a fourth-year medical student at Meharry Medical College, a second-year Ph.D. student at the University of Pennsylvania Wharton Healthcare Management and Economics, and a Clinical Intern at Yale School of Medicine Department of Orthopaedics and Rehabilitation. Her research interest lies in healthcare economics, policy, diversity, and equity. She has published in Cancer Discovery, International journal of molecular sciences, The American Journal of Physical and Rehabilitation Medicine, Journal of spine intervention, Advanced wound care (SAWC) & wound healing society (WHS). Ms. Chude is an Associate Fellow of the Leonard Davis Institute (Penn LDI), an NIH diversity supplement grant recipient, and a Bloomberg Scholar.</p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-50 d-flex flex-column">
                            <h4 style={{ fontWeight: '700' }}>Pure Sciences</h4>
                            <div className="team-rows padding1">
                                <img src={amanda} alt="team not found" />
                                <div>
                                    <h5 style={{ fontWeight: '700' }}>Amanda, Researcher, UPenn</h5>
                                    <p>Amanda is a well-seasoned cancer research specialist at the University of Pennsylvania. Within the past year, she has published two papers and submitted two additional manuscripts. Amanda studied Biochemistry as an undergraduate at Temple University and graduated in 2014. As an undergraduate, she was selected by the Wistar Institute for a prestigious research opportunity where she began to gain skills in biomedical research and exposure to cancer studies. In 2017, she joined Thomas Jefferson University as a research assistant and lab manager under Dr. Sara E. Meyer. There she developed a novel mouse model of epigenetic mutant AML and worked to better understand the impact of such mutations in hematological disease. Amanda joined the laboratory of Dr. Ravi K. Amaravadi at the University of Pennsylvania in 2020 and currently contributes to work in cancer biology that focuses on cellular autophagy - a process crucial to cell survival by which cells recycle their intracellular components.  Amanda has worked to characterize the chemical biology of novel lysosomal autophagy inhibitors in cancer and is currently working with other novel pharmaceutical compounds that augment autophagy.</p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-50 d-flex flex-column">
                            <h4 style={{ fontWeight: '700' }}>Engineering/Applied Sciences</h4>
                            <div className="team-rows padding1">
                                <img src={yasmeen} alt="team not found" />
                                <div>
                                    <h5 style={{ fontWeight: '700' }}>Yasmeen</h5>
                                    <p style={{ marginBottom: '8px' }}>Yasmeen is an engineer turned business owner and entrepreneur. She has a B.S in biomedical engineering from the University of Massachusetts at Dartmouth and has worked in research and pharma for about 6 years. She has done research at MGH and Brigham and Women's Hospital, and most recently was the head manufacturer for a clinical trial with the pharmaceutical company Editas. She has a strong interest in finance and is currently a licensed financial advisor with the state of Massachusetts. She has a side business which is primarily involved in financial education and in helping individuals and families understand how to maximize wealth through investment.</p>
                                    <p>During her free time, she enjoys swimming, hiking, nature or can be found enjoying her time with pets and loved ones.</p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-50 d-flex flex-column">
                            <h4 style={{ fontWeight: '700' }}>Business Administration and Management</h4>
                            <div className="team-rows">
                                <img src={ndu} alt="team not found" />
                                <div>
                                    <h5 style={{ fontWeight: '700' }}>Ndubuisi</h5>
                                    <p style={{ marginBottom: '8px' }}>Ndu is a cofounder of Edyter. He enjoys mentoring students. In fact, at NO cost and for more than 7 years he has guided scores of applicants to craft top-notch application essays. It's this passion that has inspired him to found Edyter. Previously, he studied chemical engineering and business strategy and entrepreneurship at the University of Toronto and Queen's University respectively, both in Canada. He has worked as a researcher in both schools and as well at the National Research Council of Canada, and in fact currently conducts research at The Wharton School.</p>
                                    <p>He enjoys reading, hiking, tennis, soccer, meeting people and absorbing nature!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}
