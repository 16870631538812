import { useState, useEffect, useMemo } from "react";
import "./index.css";
import { Button } from "../../components/Button/Index";
import { Modal } from "antd";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { InputBox } from "../../components/Input/InputApplication";
import { useHistory } from "react-router-dom";
import { ExclamationCircleFilled } from '@ant-design/icons';
import axios from "axios";
import config from "../../config";
import getApplicationsThunkCreator from "../../redux-thunk/getApplicationsThunkCreator";
import closeBtn from "../../assets/img/close-btn-dark.svg";
import { error, success } from "../../components/Alerts/Index";
export const DraftConfirmation = ({
  application,
  setOpenPayment,
  open,
  setOpen,
}) => {
  const session = useSelector((state) => state.auth.session);
  const applicant = useSelector((state) => state.applicant.profile);
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [loadSubmit, setLoadSubmit] = useState(false);
  const [editHead, setEditHead] = useState(false);
  const [editBody, setEditBody] = useState(false);
  const [loadCancel, setLoadCancel] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      draftBody: application?.draftBodyContent,
      draftHead: application?.draftHeaderContent,
    },
  });

  console.log(application);
  useEffect(() => {
    reset({
      draftBody: application?.draftBodyContent,
      draftHead: application?.draftHeaderContent,
    });
  }, [application, reset]);

  const cancelOrder = () => {
    setLoadCancel(true);
    axios
      .post(`${config.baseUrl}/api/v1/application/submitDraftContents`, {
        applicationId: application?.id,
        isIncomplete: true,
        headerContent: application?.draftHeaderContent,
        bodyContent: application?.draftBodyContent,
      })
      .then((res) => {
        setLoadCancel(false);
        if (res.status === 200 && res?.data?.result) {
          success("Success", res?.data?.result);
          dispatch(getApplicationsThunkCreator(setLoading, session.user.id));
          setOpen(false);
          history.push("/orders");
        } else {
          error("Error", res?.data?.message);
        }
      })
      .catch((e) => {
        setLoadCancel(false);
        const message = e?.response?.data?.message;
        error("Error", message);
      });
  };

  const showCancelConfirm = (e) => {
    e.preventDefault()
    Modal.confirm({
      title: 'This action will delete your order. Are you sure you want to proceed?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        setOpen(false)
        cancelOrder()

      },
      onCancel() {
        console.log('Cancel');
        setOpen(false)
      },
    });
  };

  const submitOrder = (data) => {
    setLoadSubmit(true);
    axios
      .post(`${config.baseUrl}/api/v1/application/submitDraftContents`, {
        applicationId: application?.id,
        isIncomplete: false,
        headerContent: data.draftHead,
        bodyContent: data.draftBody,
      })
      .then((res) => {
        setLoadSubmit(false);
        if (res.status === 200 && res?.data?.result) {
          success("Success", res?.data?.result);
          setOpenPayment(true);
          setOpen(false);
        } else {
          error("Error", res?.data?.message);
        }
      })
      .catch((e) => {
        setLoadSubmit(false);
        const message = e?.response?.data?.message;
        error("Error", message);
      });
  };

  return (
    <div>
      <Modal
        title={false}
        visible={open}
        footer={false}
        width={"65%"}
        maskClosable={false}
        wrapClassName="example"
        style={{ marginTop: "-1rem" }}
        bodyStyle={{ height: "100%" }}
      >
        <div
          onClick={() => {
            setOpen(false);
            dispatch(getApplicationsThunkCreator(setLoading, session.user.id));
          }}
          className="d-flex justify-content-end align-items-center cursor"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div className="text-left lead-p">Final check</div>
        <div className="text-left" style={{ fontStyle: "italic", fontSize: '13px' }}>
          Please confirm that you have submitted the right document. Check that
          the title is well-captured, and the content (body) is complete as
          contained in your document. Click edit to effect any necessary changes.

        </div>
        <br />
        <div className="">
          <div className="">
            <div>
              <div className="draft-head">
                <div className="draft-head-flex">
                  <div className="draft-heading">Title:</div>
                  <div className="draft-edit" onClick={() => setEditHead(true)}>
                    {editHead ? "" : "Edit"}
                  </div>
                </div>
                <br />
                {!editHead ? (
                  <div className="draft-content">
                    {application?.draftHeaderContent}
                  </div>
                ) : (
                  <div class="w-100">
                    <textarea
                      className="additional-comment"
                      rows="6"
                      style={{ height: "100%", fontFamily: "inherits" }}
                      name="draftHead"
                      type="text"
                      {...register("draftHead", {})}
                      cols="60"
                    ></textarea>
                  </div>
                )}
              </div>
              <div className="drfat-content">
                <div className="draft-content-flex">
                  <div className="draft-heading">Content:</div>
                  <div className="draft-edit" onClick={() => setEditBody(true)}>
                    {editBody ? "" : "Edit"}
                  </div>
                </div>
                <br />
                <div className="draft-content">
                  {editBody ? (
                    <div class="w-100">
                      <textarea
                        className="additional-comment"
                        rows="4"
                        {...register("draftBody", {})}
                        style={{
                          height: "100vh",
                          paddingTop: "20px",
                          fontFamily: "inherits",
                        }}
                        cols="60"
                        placeholder="Your feedback"
                      ></textarea>
                    </div>
                  ) : (
                    <div> {application?.draftBodyContent}</div>
                  )}
                </div>
              </div>
              <br />
              <br />
              <div className="mt-5 d-flex justify-content-between align-items-center">
                <Button
                  text="Cancel"
                  className="orange w-25"
                  onClick={showCancelConfirm}
                  loading={loadCancel}
                />
                <Button
                  text="Submit"
                  loading={loadSubmit}
                  onClick={handleSubmit(submitOrder)}
                  className="purple w-25"
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DraftConfirmation;
