import { useState, useEffect, useMemo } from "react";
import "./index.css";
import { Button } from "../../components/Button/Index";
import { Modal } from "antd";
import closeBtn from "../../assets/img/close-btn-dark.svg";
import PdfIcon from "../../assets/img/pdf-icon.svg";
import axios from "axios";
import config from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DownloadDatas = ({ open, setOpen, downloadDatas }) => {
  console.log(downloadDatas);
  const closeModal = () => {
    setOpen(false);
  };

  const downloadOutput = async (filename, downloadUrl) => {
    try {
      const path =
        downloadUrl || `${config.baseUrl}/api/v1/application/downloadOwnFile/`;
      const res = await axios({
        url: `${path}${filename}`,
        method: "GET",
        responseType: "blob",
      });
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      } else {
        //TODO: handle this case
        console.log(res);
      }
    } catch (e) {
      //TODO: handle error here
      console.log(e);
    }
  };

  return (
    <div>
      <Modal
        title={false}
        visible={open}
        footer={false}
        width={600}
        onCancel={closeModal}
        maskClosable={true}
        wrapClassName="example"
        style={{ marginTop: "-1rem" }}
      >
        <div
          onClick={closeModal}
          className="d-flex justify-content-end align-items-center cursor"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div className="rate-padon">
          <br />
          <div className="order-sum-ans">
            <label style={{ color: "#222" }}>
              <b>Reviewed document (with changes highlighted)</b>
            </label>
            <div>
              {downloadDatas?.revisionDocumentName ? (
                <div className="flex-asnwers">
                  <div className="rev-right-space">
                    <img src={PdfIcon} width="40" />
                  </div>
                  <div className="rev-right-space">
                    {downloadDatas?.revisionDocumentName}{" "}
                  </div>
                  <div className="rev-right-space">
                    <a
                      href="#!"
                      className="link-hover"
                      onClick={() =>
                        downloadOutput(
                          downloadDatas?.revisionDocumentName,
                          `${config.baseUrl}/api/v1/application/downloadRevisionFile/`
                        )
                      }
                    >
                      <span>
                        <FontAwesomeIcon icon="download" size="xl" />
                      </span>
                    </a>
                  </div>
                </div>
              ) : (
                "CV not uploaded"
              )}
            </div>
          </div>
          <br />
          <div className="order-sum-ans">
            <label style={{ color: "#222" }}>
              <b>Reviewed document (no markups)
 </b>
            </label>
            <div>
              {downloadDatas?.reviewedDocumentName ? (
                <div className="flex-asnwers">
                  <div className="rev-right-space">
                    <img src={PdfIcon} width="40" />
                  </div>
                  <div className="rev-right-space">
                    {downloadDatas?.reviewedDocumentName}{" "}
                  </div>
                  <div className="rev-right-space">
                    <a
                      href="#!"
                      className="link-hover"
                      onClick={() =>
                        downloadOutput(
                          downloadDatas?.reviewedDocumentName,
                          `${config.baseUrl}/api/v1/application/downloadReviewedFile/`
                        )
                      }
                    >
                      <span>
                        <FontAwesomeIcon icon="download" size="xl" />
                      </span>
                    </a>
                  </div>
                </div>
              ) : (
                "CV not uploaded"
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
