// This file contains the routing configuration
import React, { useState, Fragment } from "react";
import "./index.css";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { PaymentSummary } from "../../pages/Review/PaymentSummary";
import ConfirmOrder from "../../pages/dashboard/ConfirmOrder";
import { RateOrder } from "../../pages/dashboard/RateOrder";
import { error, success } from "../../components/Alerts/Index";
import config from "../../config";
import getApplicationsThunkCreator from "../../redux-thunk/getApplicationsThunkCreator";
import { RatedView } from "../../pages/dashboard/ratedView";
import { ExclamationCircleFilled } from "@ant-design/icons";
import axios from "axios";
import { Pagination } from "antd";
import { ShowPreview } from "../../pages/dashboard/ShowPreview";
import { useSelector, useDispatch } from "react-redux";
import arrow from "../../assets/img/icon-right.png";
import { Modal } from "antd";
import Media from "react-media";

import { DownloadDatas } from "../../pages/dashboard/downloadData";
function Table({ data }) {
  const getHumanDate = (date) => {
    return moment(date).format("MMM Do YY, hh:mm A");
  };
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const session = useSelector((state) => state.auth?.session);
  const getApplications = () => {
    dispatch(getApplicationsThunkCreator(setLoading, session?.user?.id));
  };

  const [openCompletePayment, setOpenCompletePayment] = useState(false);
  const [applicationId, setApplicationId] = useState("");
  const [openValidateOrder, setOpenValidateOrder] = useState(false);
  const [openRateUs, setOpenRateUs] = useState(false);
  const [application, setApplication] = useState({});
  const [openRated, setOpenRated] = useState(false);
  const [previewData, setPreviewData] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [previewFilename, setPreviewFileName] = useState("");
  const [downloadDocs, setOpenDownDocs] = useState(false);
  const [feedbackResponse, setFeedbackResponse] = useState({});
  const [downloadDatas, setDownloadDatas] = useState(false);

  const downloadOutput = async (filename, downloadUrl) => {
    try {
      const path = `${config.baseUrl}/api/v1/application/downloadOwnFile/`;
      const res = await axios({
        url: `${path}${filename}`,
        method: "GET",
        responseType: "blob",
      });
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      } else {
        //TODO: handle this case
        console.log(res);
      }
    } catch (e) {
      //TODO: handle error here
      console.log(e);
    }
  };

  const deleteOrder = (id) => {
    axios
      .post(`${config.baseUrl}/api/v1/application/deleteApplication/${id}`)
      .then((res) => {
        if (res.status === 200 && res?.data?.result) {
          getApplications();
          success("Success", "Draft Deleted Successfully");
        } else {
          error("Error", res?.data?.message);
        }
      })
      .catch((e) => {
        const message = e?.response?.data?.message;
        error("Error", message);
      });
  };

  const shouldConfirmDelete = (id) => {
    Modal.confirm({
      title:
        "This action will delete your order. Are you sure you want to proceed?",
      icon: <ExclamationCircleFilled />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteOrder(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <div style={{ overflowX: "auto" }}>
      <table class="">
        <thead>
          <tr>
            <th scope="">Review ID</th>
            <th scope="">Date</th>
            <th scope="">Status</th>
            <th scope=""></th>
            <th scope=""></th>
          </tr>
        </thead>
        <tbody>
          {data.map((data) => {
            return (
              <tr>
                <td className="primary-col">
                  <Link to={`/orders/application-summary/${data.id}`}>
                    <b>{data.orderNo}</b>
                  </Link>
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {data.applicationDate
                    ? data.applicationDate.slice(0, 10)
                    : ""}
                </td>
                <td>
                  {
                    {
                      REVIEWED: (
                        <div className="text-success font-weight-bold is-disabled">
                          Reviewed
                        </div>
                      ),
                      default: (
                        <div className="text-info is-disabled">
                          {data.applicationStatus}
                        </div>
                      ),
                      REVIEW_IN_PROGRESS: (
                        <div className="text-info is-disabled">In Progress</div>
                      ),
                      REVIEWED: (
                        <div className="text-success is-disabled">Reviewed</div>
                      ),
                      RECEIVED: (
                        <div className="text-info is-disabled">
                          Application Received
                        </div>
                      ),
                      DRAFT_UPLOAD_VALIDATION: (
                        <div
                          className="redi-btnn"
                          onClick={() => {
                            history.push({
                              pathname: "/orders/review-request-application",
                              state: { id: data.id, validateRequestApplication: data },
                            });
                          }}
                        >
                          Validate request
                        </div>
                      ),
                      INCOMPLETE_APPLICATION: (
                        <div
                          className="info-btnn"
                          style={{ background: "#87ab69" }}
                          onClick={() => {
                            history.push({
                              pathname: "/orders/review-request-application",
                              state: { id: data.id },
                            });
                          }}
                        >
                          Complete request
                        </div>
                      ),
                      PAYMENT_BEING_PROCESSED: (
                        <div className="text-info is-disabled">
                          Payment being processed
                        </div>
                      ),
                      PAYMENT_REQUIRED: (
                        <div
                          className="info-btnn"
                          onClick={() => {
                            history.push({
                              pathname: "/orders/review-request-application",
                              state: { id: data.id, makePaymentApplication: data },
                            });
                          }}
                        >
                          Make payment
                        </div>
                      ),
                    }[data.applicationStatus]
                  }
                </td>
                <td>
                  {data.applicationStatus === "REVIEWED" &&
                    (data.previewDone ? (
                      <div
                        onClick={() => {
                          setOpenDownDocs(true);
                          setDownloadDatas(data);
                        }}
                        className="orange-btnn"
                        style={{ backgroundColor: "green" }}
                      >
                        Download
                      </div>
                    ) : (
                      <div
                        className="purple-btnn"
                        onClick={() => {
                          setPreviewData(data.reviewedBodyContent);
                          setPreviewFileName(data.reviewedDocumentName);
                          setApplicationId(data.id);
                          setShowPreview(true);
                          setDownloadDatas(data);
                        }}
                      >
                        Preview
                      </div>
                    ))}
                </td>

                <td>
                  {" "}
                  {data.previewDone && (
                    <div
                      className="orange-btnn"
                      onClick={() => {
                        setApplicationId(data.id);
                        setFeedbackResponse(data?.applicantFeedbackResponse);
                        data.ratingDone
                          ? setOpenRated(true)
                          : setOpenRateUs(true);
                      }}
                    >
                      {data.ratingDone ? "View Rating" : "Rate Us"}
                    </div>
                  )}
                </td>
                {data.applicationStatus === "DRAFT_UPLOAD_VALIDATION" ||
                  (data.applicationStatus === "INCOMPLETE_APPLICATION" && (
                    <td>
                      <div
                        onClick={() => {
                          shouldConfirmDelete(data?.id);
                        }}
                        className="orange-btnn"
                        style={{ backgroundColor: "red" }}
                      >
                        Delete
                      </div>
                    </td>
                  ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />

      <div className="container">
        <br />
        <Media
          queries={{
            small: "(max-width: 599px)",
            medium: "(min-width: 600px) and (max-width: 1199px)",
            large: "(min-width: 1200px)",
          }}
        >
          {(matches) => (
            <Fragment>
              {matches.small && (
                <div className="d-flex justify-content-center align-items-center show-mobile">
                  <div className="text-center">
                    <img src={arrow} width="30" />
                    <div>Scroll to the right</div>
                  </div>
                </div>
              )}
              {matches.medium && (
                <div>
                  <br />
                  <br />
                  <br />
                </div>
              )}
              {matches.large && (
                <div>
                  <br />
                  <br />
                </div>
              )}
            </Fragment>
          )}
        </Media>
      </div>
      <br />
      <br />

      <PaymentSummary
        open={openCompletePayment}
        setOpen={setOpenCompletePayment}
        applicationId={applicationId}
      />

      <ConfirmOrder
        open={openValidateOrder}
        setOpen={setOpenValidateOrder}
        application={application}
        setOpenPayment={setOpenCompletePayment}
      />

      <RateOrder
        open={openRateUs}
        setOpen={setOpenRateUs}
        applicationId={applicationId}
      />
      <RatedView
        open={openRated}
        feedbackResponse={feedbackResponse}
        setOpen={setOpenRated}
      />

      <ShowPreview
        open={showPreview}
        setOpen={setShowPreview}
        previewContent={previewData}
        applicationId={applicationId}
        previewFilename={previewFilename}
        downloadDatas={downloadDatas}
      />

      <DownloadDatas
        open={downloadDocs}
        setOpen={setOpenDownDocs}
        downloadDatas={downloadDatas}
      />
    </div>
  );
}

export default Table;
