import { useState, useEffect, useMemo } from "react";
import "./index.css";
import { Button } from "../../components/Button/Index";
import { Modal } from "antd";
import getApplicationsThunkCreator from "../../redux-thunk/getApplicationsThunkCreator";
import closeBtn from "../../assets/img/close-btn-dark.svg";
import axios from "axios";
import config from "../../config";
import { DownloadDatas } from "./downloadData";
import { RateOrder } from "./RateOrder";
import { useDispatch, useSelector } from "react-redux";
export const ShowPreview = ({
  open,
  downloadDatas,
  setOpen,
  previewContent,
  previewFilename,
  applicationId,
}) => {
  const [openRateUs, setOpenRateUs] = useState(false);
  const session = useSelector((state) => state.auth.session);
  const [documentModal, setOpenDocumentModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [takeOffChoiceBtns, setTakeOffChoiceBtns] = useState(true);
  const dispatch = useDispatch();
  const downloadOutput = async (filename, downloadUrl) => {
    try {
      const path =
        downloadUrl || `${config.baseUrl}/api/v1/application/downloadOwnFile/`;
      const res = await axios({
        url: `${path}${filename}`,
        method: "GET",
        responseType: "blob",
      });
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      } else {
        //TODO: handle this case
        console.log(res);
      }
    } catch (e) {
      //TODO: handle error here
      console.log(e);
    }
  };

  const acknowledgeRate = () => {
    axios
      .get(
        `${config.baseUrl}/api/v1/application/updateApplicationPreviewFlag/applicationId/${applicationId}`
      )
      .then((res) => {
        if (res.status === 200 && res?.data?.result) {
          setShowDownload(true);
          setTakeOffChoiceBtns(false);
        } else {
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <Modal
        title={false}
        visible={open}
        footer={false}
        width={"100"}
        maskClosable={false}
        wrapClassName="example"
        style={{ marginTop: "-5rem" }}
        bodyStyle={{ height: "100%" }}
      >
        <div
          onClick={() => {
            setOpen(false);
            setShowDownload(false);
            setTakeOffChoiceBtns(false);
            setShowDownload(false)
            setTakeOffChoiceBtns(true);
            dispatch(getApplicationsThunkCreator(setLoading, session.user.id));
          }}
          className="d-flex justify-content-end align-items-center cursor"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <div className="preview-container">
              <div>
                {previewContent?.split("\n").map((i, key) => {
                  return (
                    <div className="preview-content" key={key}>
                      {i}
                    </div>
                  );
                })}
              </div>
              <div className="mt-5">
                <div className="d-flex justify-content-center align-items-center">
                  <br />
                  <div>
                    {takeOffChoiceBtns && (
                      <div className=" text-center mt-4 lead-p">
                        Do you wish to give us feedback?. We will appreciate it.
                      </div>
                    )}
                    <br />
                    <div className="d-flex justify-content-center align-items-center">
                      {takeOffChoiceBtns && (
                        <div>
                          <div className="d-flex align-items-center">
                            <div
                              onClick={() => {
                                acknowledgeRate();
                                setOpenRateUs(true);
                              }}
                              className="orange text-center p-3"
                              style={{
                                background: "#382d8b",
                                cursor: "pointer",
                                borderRadius: "99px",
                                marginRight: "1rem",
                                width: "90px",
                              }}
                            >
                              YES
                            </div>
                            <div
                              className="orange text-center p-3"
                              style={{
                                borderRadius: "99px",
                                cursor: "pointer",
                                width: "90px",
                              }}
                              onClick={acknowledgeRate}
                            >
                              No
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <br />
                    <br />
                    {showDownload && (
                      <div className="d-flex justify-content-center">
                        <Button
                          onClick={() => {
                            setOpenDocumentModal(true);
                          }}
                          text="Download Pdf"
                          className="info download-pdf w-100"
                          style={{ borderRadius: "99px" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <DownloadDatas
        open={documentModal}
        setOpen={setOpenDocumentModal}
        downloadDatas={downloadDatas}
      />

      <RateOrder
        open={openRateUs}
        setOpen={setOpenRateUs}
        applicationId={applicationId}
      />
    </div>
  );
};
