import React from "react";
import { Modal } from "antd";
import closeBtn from "../../../assets/img/close-btn-dark.svg";
import Logo from "../../../assets/img/Log.svg";

export default function TermsAndConditions({ openTerms, setOpenTerms }) {
  const closeTerms = () => {
    setOpenTerms(false);
  };

  return (
    <Modal
      title={false}
      visible={openTerms}
      footer={false}
      width={"90%"}
      maskClosable={false}
      onCancel={closeTerms}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div href="/" className="mD-auto">
          <img src={Logo} className="w-75" />
        </div>
        <div
          onClick={closeTerms}
          className="d-flex cursor justify-content-end cursor align-items-center"
        >
          <img src={closeBtn} width="15" />
        </div>
      </div>

      <br />

      <h2 className="text-center pt-4">Terms of Use</h2>
      <div className="container">
        <p className="privacy-policy-text">
          <br />
          These terms and conditions (the "Terms and Conditions") govern the use
          of www.edyter.com (the "Site"). This Site is owned and operated by
          Edyter. This Site is an ecommerce website.
        </p>
        <p>
          By using this Site, you indicate that you have read and understand
          these Terms and Conditions and agree to abide by them at all times.
        </p>
        <p style={{ textTransform: "uppercase" }}>
          THESE TERMS AND CONDITIONS CONTAIN A DISPUTE RESOLUTION CLAUSE THAT
          IMPACTS YOUR RIGHTS ABOUT HOW TO RESOLVE DISPUTES. PLEASE READ IT
          CAREFULLY.
        </p>
        <div>
          <div className="privacy-header">Intellectual Property</div>
          <div className="privacy-policy-text">
            All content published and made available on our Site is the property
            of Edyter and the Site's creators. This includes, but is not limited
            to images, text, logos, documents, downloadable files and anything
            that contributes to the composition of our Site.
            <br />
          </div>
          <div>
            <div className="privacy-header">Age Restrictions</div>
            <div className="privacy-policy-text">
              The minimum age to use our Site is 15 years old. By using this
              Site, users agree that they are over 15 years old. We do not
              assume any legal responsibility for false statements about age.
            </div>
          </div>
          <div>
            <div className="privacy-header">Acceptable Use</div>
            <div className="privacy-policy-text">
              As a user of our Site, you agree to use our Site legally, not to
              use our Site for illegal purposes, and not to:
              <br />
              <br />
              <p className="privacy-policy-text">
                Harass or mistreat other users of our Site;
              </p>
              <p className="privacy-policy-text">
                Violate the copyright of an author of a work under the copyright
                law;
              </p>
              <p className="privacy-policy-text">
                Violate the rights of other users of our Site;
              </p>
              <p className="privacy-policy-text">
                {" "}
                Violate the intellectual property rights of the Site owners or
                any third party to the Site;
              </p>
              <p className="privacy-policy-text">
                Hack into the account of another user of the Site;
              </p>
              <p className="privacy-policy-text">
                Act in any way that could be considered fraudulent; or
              </p>
              <p className="privacy-policy-text">
                Post any material that may be deemed inappropriate or offensive
              </p>
              <br />
              <p className="privacy-policy-text">
                If we believe you are using our Site illegally or in a manner
                that violates these Terms and Conditions, we reserve the right
                to limit, suspend or terminate your access to our Site. We also
                reserve the right to take any legal steps necessary to prevent
                you from accessing our Site.
              </p>
            </div>
          </div>
          <div>
            <div className="privacy-header">Accounts</div>
            <div className="privacy-policy-text">
              When you create an account on our Site, you agree to the
              following:
              <br />
              1. You are solely responsible for your account and the security
              and privacy of your account, including passwords or sensitive
              information attached to that account; and
              <br />
              2. All personal information you provide to us through your account
              is up to date, accurate, and truthful and that you will update
              your personal information if it changes.
              <br />
              We reserve the right to suspend or terminate your account if you
              are using our Site illegally or if you violate these Terms and
              Conditions.
            </div>
          </div>
          <div>
            <div className="privacy-header">Sale of Services</div>
            <div className="privacy-policy-text">
              These Terms and Conditions govern the sale of services available
              on our Site.
              <br />
              The following services are available on our Site:
              <br />
              Editing, proof reading, reviewing documents, software as a
              service.
              <br />
              The services will be paid for in full when the services are
              ordered.
              <br />
              These Terms and Conditions apply to all the services that are
              displayed on our Site at the time you access it. All information,
              descriptions, or images that we provide about our services are as
              accurate as possible. However, we are not legally bound by such
              information, descriptions, or images as we cannot guarantee the
              accuracy of all services we provide. You agree to purchase
              services from our Site at your own risk.
              <br />
              We reserve the right to modify, reject or cancel your order
              whenever it becomes necessary. If we cancel your order and have
              already processed your payment, we will give you a refund equal to
              the amount you paid. You agree that it is your responsibility to
              monitor your payment instrument to verify receipt of any refund.
            </div>
          </div>
          <div>
            <div className="privacy-header">Payments</div>
            <div className="privacy-policy-text">
              We accept the following payment methods on our Site:
              <br />
              - Stripe.
              <br />
              When you provide us with your payment information, you authorize
              our use of and access to the payment instrument you have chosen to
              use. By providing us with your payment information, you authorize
              us to charge the amount due to this payment instrument.
              <br />
              If we believe your payment has violated any law or these Terms and
              Conditions, we reserve the right to cancel or reverse your
              transaction.
            </div>
          </div>
          <div>
            <div className="privacy-header">Consumer Protection Law</div>
            <div className="privacy-policy-text">
              Where any consumer protection legislation in your jurisdiction
              applies and cannot be excluded, these Terms and Conditions will
              not limit your legal rights and remedies under that legislation.
              These Terms and Conditions will be read subject to the mandatory
              provisions of that legislation. If there is a conflict between
              these Terms and Conditions and that legislation, the mandatory
              provisions of the legislation will apply.
            </div>
          </div>
          <div>
            <div className="privacy-header">Limitation of Liability</div>
            <div className="privacy-policy-text">
              Edyter and our directors, officers, agents, employees,
              subsidiaries, and affiliates will not be liable for any actions,
              claims, losses, damages, liabilities and expenses including legal
              fees from your use of the Site
            </div>
          </div>
          <div>
            <div className="privacy-header">Indemnity</div>
            <div className="privacy-policy-text">
              Except where prohibited by law, by using this Site you indemnify
              and hold harmless Edyter and our directors, officers, agents,
              employees, subsidiaries, and affiliates from any actions, claims,
              losses, damages, liabilities and expenses including legal fees
              arising out of your use of our Site or your violation of these
              Terms and Conditions.
            </div>
          </div>

          <div>
            <div className="privacy-header">Applicable Law</div>
            <div className="privacy-policy-text">
              These Terms and Conditions are governed by the laws of the State
              of Delaware.
            </div>
          </div>
          <div>
            <div className="privacy-header">Warranty Disclaimers</div>
            <div className="privacy-policy-text">
              Edyter and its licensors expressly disclaim any and all warranties
              and conditions, express or implied, regarding the site, services,
              and software, including, but not limited to, any implied
              warranties or conditions of merchantability, merchantable quality,
              fitness for a particular purpose, title, noninfringement,
              satisfactory quality or arising from a course of dealing, law,
              usage, or trade practice, or regarding security, quiet enjoyment,
              reliability, timeliness, and performance. You agree that your use
              of the site, services and software are at your own sole risk and
              that the site, services, and any software are provided on an “as
              is,” “as available,” and “with all faults” basis, without any
              express or implied warranties of any kind. Without limiting the
              foregoing, Edyter and its licensors do not warrant that the
              operation of the site and provision of services and/or software
              will meet your requirements or will be uninterrupted or
              error-free.
            </div>
          </div>
          <div>
            <div className="privacy-header">Dispute Resolution</div>
            <div className="privacy-policy-text">
              Subject to any exceptions specified in these Terms and Conditions,
              if you and Edyter are unable to resolve any dispute through
              informal discussion, then you and Edyter agree to submit the issue
              first before a non-binding mediator and to an arbitrator in the
              event that mediation fails. The decision of the arbitrator will be
              final and binding. Any mediator or arbitrator must be a neutral
              party acceptable to both you and Edyter.
              <br />
              <br />
              Notwithstanding any other provision in these Terms and Conditions,
              you and Edyter agree that you both retain the right to bring an
              action in small claims court and to bring an action for injunctive
              relief or intellectual property infringement.
              <br />
            </div>
          </div>
          <div>
            <div className="privacy-header">Severability</div>
            <div className="privacy-policy-text">
              If at any time any of the provisions set forth in these Terms and
              Conditions are found to be inconsistent or invalid under
              applicable laws, those provisions will be deemed void and will be
              removed from these Terms and Conditions. All other provisions will
              not be affected by the removal and the rest of these Terms and
              Conditions will still be considered valid.
              <br />
            </div>
          </div>
          <div>
            <div className="privacy-header">Changes</div>
            <div className="privacy-policy-text">
              These Terms and Conditions may be amended from time to time in
              order to maintain compliance with the law and to reflect any
              changes to the way we operate our Site and the way we expect users
              to behave on our Site. We will notify users by email of changes to
              these Terms and Conditions or post a notice on our Site.
              <br />
            </div>
          </div>
          <div>
            <div className="privacy-header">Contact Details</div>
            <div className="privacy-policy-text">
              Please contact us if you have any questions or concerns. Our
              contact details are as follows:
              <br />
              You can also contact us through the feedback form available on our Site
            </div>
          </div>

          <br />
          <br />
          <br />
        </div>
      </div>
    </Modal>
  );
}
