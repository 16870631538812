import React from "react";
import Footer from "../../components/Layout/Footer/Footer";
import Header from "../../components/Layout/Header";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import Applicant from "../../components/ReviewRequest/Applicant";

export default function ReviewRequestApplicantEdit() {
    const auth = useSelector(state => state.auth)
    const history = useHistory()
    const { applicationId } = useParams()

    if (!auth.authenticated) {
        return <Redirect to="/" />
    }

    const next = () => {
        history.push("/orders/applicant-update-confirmation")
    };



    return (
        <>

            <Header auth={auth} />
            <main>
                <div className="container-padding">
                    <Applicant next={next} profileTitle={'Please update your profile as requested in the email sent to you.'} nextButtonText={'Submit'} applicationId={applicationId} />
                </div>
            </main>
            <Footer />
        </>
    );
}
