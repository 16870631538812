/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import SideBar from "./Sidebar";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "react-modern-drawer/dist/index.css";
// import { NotificationIcon, ProfileIcon, TraderIcon } from "../../../assets/svgs";
import { useSelector } from "react-redux";
import { useHistory, useLocation, Link } from "react-router-dom";
import bell from "../../assets/img/bell.svg";
import getApplicantThunkCreator from "../../redux-thunk/getApplicantThunkCreator";
import { Redirect } from "react-router-dom";
import menuBar from "../../assets/img/menu-bar.svg";
import Logo from "../../assets/img/Log.svg";
import { useDispatch } from "react-redux";
import { Button, Popover } from "antd";
import Drawer from "react-modern-drawer";
import closeBtn from "../../assets/img/close-X.svg";
import userIcon from "../../assets/img/user-icon.png";
import OrderIcon from "../../assets/img/shape-icon.svg";
import SupportIcon from "../../assets/img/support-icon.svg";
import LogoutIcon from "../../assets/img/logout.svg";

// import IdleTimer from "react-idle-timer";

export const DashboardContainer = ({ children, pageTitle }) => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const pathLocation = useLocation();
  const session = useSelector((state) => state.auth.session);
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);

  useEffect(() => {
    dispatch(getApplicantThunkCreator(session?.user?.id, setLoad));
  }, [dispatch, session?.user?.id]);

  const auth = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.applicant.profile);
  const [open, setOpen] = useState(false);
  const activeRoute = pathLocation?.pathname?.replace("/", "");
  const showDrawer = () => {
    setOpen(!open);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (profile?.firstName === null && profile?.lastName === null) {
      history.push("/profile");
    }
  }, [profile?.userId]);
  if (!auth?.authenticated) {
    return <Redirect to="/" />;
  }

  const logout = () => {
    sessionStorage.clear();
    window.location.href = "/";
  };

  const content = (
    <div style={{ width: "170px", cursor: "pointer" }} className="mt-3">
      <Link to="/profile">
        <p className="edy-menu">Profile</p>
      </Link>
      <p
        onClick={() => (window.location.href = "/orders")}
        className="edy-menu"
      >
        Reviews
      </p>
      <Link to="/contact-us">
        <p className="edy-menu">Support</p>
      </Link>
      <p className="edy-menu" onClick={logout}>
        Log out
      </p>
    </div>
  );

  return (
    <div className="">
      <div className="top-main">
        <div className="top-main-sub pt-4  ">
          <div className="d-flex justify-content-between align-items-center h-100 mt-2">
            <div>
              <div
                onClick={() => (window.location.href = "/orders")}
                className="cursor"
              >
                <img src={Logo} />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="sub-rite"></div>
              <div className="sub-ritey" style={{ cursor: "pointer" }}>
                <Popover
                  placement="bottomRight"
                  content={content}
                  trigger="hover"
                >
                  <div className="d-flex align-items-center">
                    {profile?.firstName && profile?.lastName ? (
                      <div style={{ marginRight: "1rem" }}>
                        {profile?.firstName + " " + profile?.lastName}
                      </div>
                    ) : (
                      <div style={{ marginRight: "1rem" }}>
                        <img src={userIcon} width="30" />{" "}
                      </div>
                    )}{" "}
                    <i className="fa fa-chevron-down"></i>
                  </div>
                </Popover>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-top-main">
        <div className="container-mobile-pad">
          <div className="d-flex justify-content-between align-items-center">
            <div onClick={showDrawer}>
              <img src={menuBar} className="w-100" />
            </div>
            <div onClick={() => history.push("/orders")}>
              <img src={Logo} width="100" />
            </div>
          </div>
        </div>
      </div>

      <div className="top-2">{children}</div>

      <Drawer
        open={open}
        onClose={showDrawer}
        style={{
          width: "80%",
          background: "#fff",
          height: "100vh",
          paddingBottom: "20rem",
        }}
        direction="left"
      >
        <div>
          <div class="draw-mobile-body">
            <div class="d-flex justify-content-between align-items-center">
              <div
                onClick={() => (window.location.href = "/orders")}
                className="cursor"
              >
                <img src={Logo} width="100" />
              </div>
              <div onClick={showDrawer}>
                <img src={closeBtn} width="18" />
              </div>
            </div>
            <br />
            <br />
            <div className="mt-3">
              <div className="user-name">
                {profile?.firstName + " " + profile?.lastName}
              </div>
              <Link to="/profile">
                <div className="profile-mobile">View Profile</div>
              </Link>
            </div>
            <br />
            <br />
            <div className="mt-3">
              <Link to="/profile">
                <div
                  className={`${activeRoute === "profile"
                      ? "d-flex align-items-center left-active-border"
                      : "d-flex align-items-center"
                    }`}
                >
                  <div style={{ marginRight: "1.3rem" }}>
                    {" "}
                    <img src={OrderIcon} width="18" />
                  </div>
                  <div
                    className={
                      activeRoute === "profile"
                        ? "menu-mobile-active"
                        : "menu-mobile"
                    }
                  >
                    Profile
                  </div>
                </div>
              </Link>
              <br />
              <Link to="/orders">
                <div
                  className={`${activeRoute === "orders"
                      ? "d-flex align-items-center left-active-border"
                      : "d-flex align-items-center"
                    }`}
                >
                  <div style={{ marginRight: "1.3rem" }}>
                    {" "}
                    <img src={OrderIcon} width="18" />
                  </div>
                  <div
                    className={
                      activeRoute === "orders"
                        ? "menu-mobile-active"
                        : "menu-mobile"
                    }
                  >
                    Reviews
                  </div>
                </div>
              </Link>

              <br />
              <Link to="/contact-us">
                <div
                  className={`${activeRoute === "contact-us"
                      ? "d-flex align-items-center left-active-border"
                      : "d-flex align-items-center"
                    }`}
                >
                  <div style={{ marginRight: "1.3rem" }}>
                    {" "}
                    <img src={SupportIcon} width="18" />
                  </div>
                  <div
                    className={
                      activeRoute === "contact-us"
                        ? "menu-mobile-active"
                        : "menu-mobile"
                    }
                  >
                    Support
                  </div>
                </div>
              </Link>
              <br />
              <div className="" onClick={logout}>
                <div className="d-flex align-items-center" onClick={logout}>
                  <div style={{ marginRight: "1.3rem" }}>
                    <img src={LogoutIcon} width="18" />
                  </div>
                  <div className="menu-mobile">Log Out</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
