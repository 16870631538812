export const testimonyData = {
  title: "",
  rows: [
    {
      client: "4036",
      rate: 5,
      country: "Pakistan",
      content: "",
    },
    {
      client: "3355",
      rate: 5,
      country: "United States",
      content: "",
    },
    {
      client: "3354",
      rate: 5,
      country: "United States",
      content: "",
    },
    {
      client: "3065",
      rate: 5,
      country: "United States",
      content: "",
    },
    {
      client: "3875",
      rate: 5,
      country: "Vietnam",
      content: "",
    },
    {
      client: "567",
      rate: 5,
      country: "Nigeria",
      content:
        "You took the words right out of my heart and mind. Love this, keep up the good work.",
    },
    {
      client: "518",
      rate: 5,
      country: "India",
      content: "",
    },
    {
      client: "517",
      rate: 5,
      country: "India",
      content:
        "Thank you so much for this editing help. It has been aboon for someone like me.",
    },
    {
      client: "456",
      rate: 5,
      country: "India",
      content: "",
    },
    {
      client: "3661",
      rate: 5,
      country: "India",
      content: "",
    },
    {
      client: "1978",
      rate: 5,
      country: "Thailand",
      content: "",
    },
    {
      client: "3777",
      rate: 5,
      country: "Ghana",
      content: "You guys actually did an outstanding work. Thank You.",
    },
    {
      client: "3070",
      rate: 5,
      country: "Nigeria",
      content:
        "Give an option or space for us to add additional requirements that schools might need.",
    },
    {
      client: "3847",
      rate: 4,
      country: "Saudi Arabia",
      content: "Include comments",
    },
    {
      client: "4034",
      rate: 5,
      country: "China",
      content: "",
    },
    {
      client: "4510",
      rate: 5,
      country: "Nigeria",
      content: "THANK YOU",
    },
    {
      client: "4572",
      rate: 5,
      country: "Peru",
      content: "Great work, I’m very grateful.Thanks!",
    },
    {
      client: "4805",
      rate: 5,
      country: "Nigeria",
      content: "Thanks!",
    },
    {
      client: "4688",
      rate: 5,
      country: "Nigeria",
      content: "Wow!",
    },
    {
      client: "4427",
      rate: 5,
      country: "India",
      content: "Thanks anon for helping with my SOP.",
    },
    {
      client: "3106",
      rate: 4,
      country: "Nigeria",
      content: "",
    },
    {
      client: "3538",
      rate: 4,
      country: "Nigeria",
      content: "",
    },
    {
      client: "4251",
      rate: 5,
      country: "Nigeria",
      content: "",
    },
  ],
};
