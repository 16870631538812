import { useState, useEffect, useMemo } from "react";
import "./index.css";
import { Button } from "../../components/Button/Index";
import { Modal } from "antd";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { InputBox } from "../../components/Input/InputApplication";
import { useHistory } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import MyComponent from "react-fullpage-custom-loader";
import closeBtn from "../../assets/img/close-btn-dark.svg";
import OrderCheckout from "../../pages/orders/order-checkout";
import "./index.css";
export const PaymentSummary = ({ open, setOpen, applicationId }) => {
  const session = useSelector((state) => state.auth.session);
  const dispatch = useDispatch();
  const [checkOutOpen, setCheckOutOpen] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const history = useHistory();
  const [load, setLoad] = useState(false);
  const [checkOutFailed, setCheckoutFailed] = useState(false);
  const [clientSecret, setClientSecret] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCreateError, setShowCreateError] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const loadPaymentSdk = () => {
    setLoad(true);
    axios
      .post(
        `${config.baseUrl}/api/v1/payment/create-payment-intent/${applicationId}`
      )
      .then((res) => {
        setLoading(false);
        if (res.status === 200 && res?.data?.result) {
          setClientSecret(res.data.result.clientSecret);
          setCheckoutFailed(false);
          setCheckOutOpen(true);
          setLoad(false);
          setOpen(false);
        } else {
          console.log(res);
          setCheckoutFailed(true);
        }
      })
      .catch((e) => {
        setLoad(false);
        setLoading(false);
        console.log(e);
        setCheckoutFailed(true);
      });
  };

  const proceedToPayment = (e) => {
    e.preventDefault();
    loadPaymentSdk();
  };

  return (
    <div>
      <Modal
        title={false}
        visible={open}
        footer={false}
        width={600}
        maskClosable={false}
        wrapClassName="example"
        style={{ marginTop: "-1rem" }}
        bodyStyle={{ height: "100%" }}
      >
        <div
          onClick={closeModal}
          className="d-flex justify-content-end align-items-center cursor"
        >
          <img src={closeBtn} width="15" />
        </div>
        <div>
          <br />
          <div className="review-summary-box-pay text-center">
            <div>
              <div className="review-summary-lead">Cost summary</div>
              <hr />
              <br />
              <div className="review-summary-top">
                <div className="summary-flex">
                  <div className="top-title-summary">Order description</div>
                  <div className="title-summary-val">
                    {" "}
                    Document Editing (Edyter)
                  </div>
                </div>
                <br />
                <div className="summary-flex">
                  <div className="top-title-summary">Order reference</div>
                  <div className="title-summary-val">{applicationId}</div>
                </div>
                <br />
                <div className="summary-flex">
                  <div className="top-title-summary">Order quantity</div>
                  <div className="title-summary-val">1</div>
                </div>
                <hr />
                <div className="review-summary-bottom">
                  <div className="summary-bottom-flex">
                    <div className="bottom-title-summary">Price</div>
                    <div className="bottom-value">$5.99</div>
                  </div>
                  <br />
                  <div className="summary-bottom-flex">
                    <div className="bottom-title-summary">Tax</div>
                    <div className="bottom-value">$0</div>
                  </div>
                  <br />
                  <div className="summary-bottom-flex">
                    <div className="bottom-title-summary">Total</div>
                    <div className="bottom-value">$5.99</div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  onClick={proceedToPayment}
                  disabled={load}
                  text="Continue to Payment"
                  loading={load}
                  className="purple w-50"
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <OrderCheckout
        open={checkOutOpen}
        loading={loading}
        clientSecret={clientSecret}
        checkOutFailed={checkOutFailed}
        applicationId={applicationId}
        setOpen={setCheckOutOpen}
      />
    </div>
  );
};
